import React, { useState } from "react";
import { Row, Col, Form, Button } from "react-bootstrap";
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";
import Select from "react-select";
import { Input, Label } from "reactstrap";

const CancelSubscription = ({ customStyles }) => {
    const [isContentVisible, setIsContentVisible] = useState(true); // State to toggle visibility

    const reasons = [
        { value: "i found a better option", label: "I found a better option" },
        { value: "it's too expensive", label: "It's too expensive" },
        { value: "i don't find it useful", label: "I don't find it useful" },
        { value: "other", label: "Other" },
    ];

    const toggleContentVisibility = () => {
        setIsContentVisible(!isContentVisible);
    };

    return (
        <div className="p-4">
            {/* Heading with Chevron Icon */}
            <h5
                className="d-flex align-items-center cursor-pointer"
                onClick={toggleContentVisibility}
                style={{ cursor: "pointer" }}
            >
                Cancel Subscription
                {isContentVisible ? (
                    <MdKeyboardArrowUp />
                ) : (
                    <MdKeyboardArrowDown />
                )}
            </h5>

            {/* Toggleable Content */}
            {isContentVisible && (
                <Form>
                    {/* Reason for cancellation */}
                    <Form.Group as={Row} className="mb-3">
                        <Form.Label column sm={3}>
                            I am cancelling because
                        </Form.Label>
                        <Col sm={9}>
                            <Select
                                options={reasons}
                                styles={customStyles}
                                placeholder="Select Reason..."
                                onChange={(e) => { }}
                            />
                        </Col>
                    </Form.Group>

                    {/* Suggestions */}
                    <Form.Group as={Row} className="mb-3">
                        <Form.Label column sm={3}>
                            Any suggestions for improvements
                        </Form.Label>
                        <Col sm={9}>
                            <Form.Control as="textarea" rows={3} placeholder="Enter your feedback here..." />
                        </Col>
                    </Form.Group>

                    {/* Data wipe confirmation */}
                    <Row>
                        <Col sm='3'></Col>

                        <Col sm='9'>
                            <div className='checkbox'>
                                <Input id='checkbox1' type='checkbox' />
                                <Label className='text-muted' for='checkbox1'>
                                    All of my data will be wiped off within 30 days. I am fine with it.
                                </Label>
                            </div>
                            <Row className="mt-2">
                                <Col className="text-muted">
                                    <p>
                                        You can also just freeze your account instead of deleting it completely. <br />
                                        You can unfreeze your account anytime you want.
                                    </p>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Button variant="danger" className="me-3">
                                        Freeze My Account
                                    </Button>
                                    <Button variant="link" className="text-danger">
                                        Proceed to delete my account
                                    </Button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Form>
            )}
        </div>
    );
};

export default CancelSubscription;
