import React, { Fragment, useContext, useEffect, useState } from 'react';
import { Container, Row, Card, Tabs, Tab, Button, Modal, Spinner, Nav } from 'react-bootstrap';
import CardBody from 'react-bootstrap/CardBody';
import { H4, H6, H3, P } from '../../AbstractElements';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { Col, FormGroup, Label } from 'reactstrap';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import Select from 'react-select'
import './Settings.css'
import { closeCompany, getAllCompanyPackages, verifyPassword, createOpSetting, getOpSettingData, updateOpSetting } from '../../api/Company';
import '../ManageAssistant/ManageAssistant.css'
import { Bounce, toast, ToastContainer } from 'react-toastify';
import moment from 'moment-timezone';
import { Input } from 'reactstrap';
import { useLocation, useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import ApiContext from '../../ApiContext';
import TimezoneSelect from 'react-timezone-select';
import { AccountSettingsIcon, OperationsIcon, RightArrowIcon, SubscriptionsIcon } from "../../fluten_assets/icons";
import { Packages } from "./Packages";
import { getLocalStorage, getUserProfile, setLocalStorage } from "../../helpers/LocalStorageHelper";
import { useUpdateUserService } from "../../Services/useUpdateUserService";
import { changePassword, getAllCompanyData } from '../../api/AuthIndex';
import { ImFilePdf } from 'react-icons/im';
import { UpdateCard } from "./UpdateCard";
import FeatherIcon from "feather-icons-react";
import { Color } from '../../helpers/colors';
import { useDispatch, useSelector } from 'react-redux';
import { ActionTypes } from '../../Store/redux-store/ActionTypes';
import PhoneInput from 'react-phone-number-input'
import { selectIsStripeSessionLoading } from '../../Store/modules/stripe/customerPortal/stripeSessionSelectors';
import CancelSubscription from './CancelSubscription';
import { selectCompleteCompany } from '../../Store/modules/company/companySelectors';



const Settings = () => {
    const { setUpdateStatus, setCompanyDetail, setAllCompanies, setCompaniesStatus, setCompanyStatusData } = useContext(ApiContext);
    const navigate = useNavigate()

    const dispatch = useDispatch()

    const theme = localStorage.getItem("mix_background_layout");
    const labelColor = theme === 'dark-only' ? 'grey' : 'black';
    const token = localStorage.getItem('token');
    const userData = JSON.parse(localStorage.getItem('auth0_profile'))
    const getCompanyInfo = JSON.parse(localStorage.getItem('companyData'))
    console.log("🚀 ~ Settings ~ getCompanyInfo:", getCompanyInfo)

    const completeCompany = useSelector(selectCompleteCompany);
    console.log("🚀 ~ Settings ~ completeCompany:", completeCompany)
    const userNumber = getLocalStorage('userNumber')

    const location = useLocation()
    console.log("🚀 ~ Settings ~ location:", location)
    const tab = location.state?.tab || 'operation';

    const tabLocation = location.search

    const [password, setPassword] = useState()
    const [cancellation, setCancellation] = useState(true)
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [showPasswordModal, setShowPasswordModal] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [loading, setLoading] = useState(false);
    const [companyPackages, setCompanyPackages] = useState();

    const [selectedPackage, setSelectedPackage] = useState(null);
    const [selectedPackageId, setSelectedPackageId] = useState(null);
    const [selectReason, setSelectReason] = useState()
    const [suggestion, setSuggestion] = useState()
    const [wiped, setWiped] = useState()
    const [opSetting, setOpSetting] = useState({});
    const [savedTimezone, setSavedTimezone] = useState();

    const [saveSettingLoader, setSaveSettingLoader] = useState(false)

    const [changePassLoader, setChangePassLoader] = useState(false)

    const [showOldPassword, setShowOldPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    // const stripeLoading = useSelector(selectIsStripeSessionLoading);

    const [stripeLoading, setStripeLoading] = useState(false);

    useEffect(() => {
        // Retrieve the saved timezone from localStorage on mount
        const storedTimezone = localStorage.getItem('selected_timezone');
        if (storedTimezone) {
            setSavedTimezone(storedTimezone);
        }
    }, []);

    useEffect(() => {
        getCompanyPackages();
        getOpSetting();
    }, [])

    const americanAndCanadianZones = moment.tz.names().filter(zone =>
        zone.startsWith('America/') || zone.startsWith('Canada/')
    );

    const getOpSetting = async () => {
        const res = await getOpSettingData(getCompanyInfo?.id, token);
        console.log("🚀 ~ getOpSetting ~ res:", res)
        if (res.data !== null) {
            setOpSetting({
                id: res.data.id,
                office_start_time: res.data.office_start_time,
                office_end_time: res.data.office_end_time,
                time_zone: res.data.time_zone,
                working_weekend: res.data.working_weekend,
                message_not_available: res.data.message_not_available,
                weekly_status_update: res.data.weekly_status_update
            })
        }
        dispatch({ type: ActionTypes.FETCH_USER_COMPLETE_COMPANY, payload: { company_id: getCompanyInfo.id } });
        // handleGetAllCompany()
    }

    const validationSchema = Yup.object().shape({
        oldPassword: Yup.string().required('Old password is required.'),
        newPassword: Yup.string().required('New password is required.'),
        confirmPassword: Yup.string()
            .oneOf([Yup.ref('newPassword.'), null], 'New and confirm password must be same.')
            .required('Confirm password is required.'),
    });

    const mobileValidationSchema = Yup.object().shape({
        mobile: Yup.string().required('Phone number is required.'),
    });
    const { updateUser } = useUpdateUserService();

    const handleSubmit = async (values, { resetForm }) => {
        setChangePassLoader(true)
        console.log('Changing password with values:', values);
        try {
            const formData = new FormData()
            formData.append("oldPassword", values.oldPassword)
            formData.append("newPassword", values.newPassword)
            formData.append("newPassword_confirmation", values.confirmPassword)
            const res = await changePassword(token, formData)
            toast.info(res.message, {
                position: "bottom-center",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                className: 'custom-toast',
            });
            resetForm();
            setChangePassLoader(false)
            console.log("🚀 ~ handleSubmit ~ res:", res)
        } catch (error) {
            console.log("🚀 ~ handleSubmit ~ error:", error)
            if (error?.response?.data?.message == "The old password is incorrect.") {
                toast.error("The old password is incorrect.", {
                    position: 'bottom-center',
                    autoClose: 3000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    className: 'custom-toast-error',
                });
            }
            if (error?.response?.data?.message == 'Your account is connected to google. Please sign in with Google') {
                toast.error("Your account is connected to google. Please sign in with Google", {
                    position: 'bottom-center',
                    autoClose: 3000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    className: 'custom-toast-error',
                });
            }
            setChangePassLoader(false)
        }
    };

    const handleMobileSubmit = (values) => {
        updateUser(values);
        setLocalStorage('userNumber', values.mobile)
    };

    const customStyles = {
        control: (provided) => ({
            ...provided,
            // backgroundColor: 'rgba(89, 120, 212, 0.2)',
            backgroundColor: 'white',
            color: '#5978d4',
            border: '1px solid lightgrey',
            boxShadow: 'none',
            '&:hover': {
                // border: 'none',
                border: '1px solid lightgrey',
            },
            width: 'auto'
        }),
        singleValue: (provided) => ({
            ...provided,
            // color: '#5978d4',
            color: 'black',
        }),
        menu: (provided) => ({
            ...provided,
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected && '#5978d4',
            // color: 'white',
            '&:hover': {
                backgroundColor: 'rgba(89, 120, 212, 0.5)',
            },
            fontSize: '11px'
        }),
        dropdownIndicator: (provided) => ({
            ...provided,
            // color: '#5978d4',
            color: 'black',
            '&:hover': {
                // color: '#5978d4',
                color: 'black',
            },
        }),
        indicatorSeparator: () => ({
            display: 'none',
        }),

    };

    const handleDeleteClick = () => {
        setShowConfirmation(true); // Show confirmation popup
    };

    const handleYesClick = () => {
        setShowConfirmation(false);
        setShowPasswordModal(true);
    };

    const handlePasswordSubmit = () => {
        // Logic for password verification
        console.log('Password submitted');
        setShowPasswordModal(false); // Close password popup
    };

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const select = [
        { value: 'too expensive', label: 'Too Expensive' },
        { value: 'not used enough', label: 'Not Used Enough' },
        { value: 'found better option', label: 'Found Better Option' },
        { value: 'poor customer support ', label: 'Poor Customer Support' },
        { value: 'limited features', label: 'Limited Features' },
        { value: 'billing issues', label: 'Billing Issues' },
        { value: 'no value received', label: 'No Value Received' },
        { value: 'not satisfied', label: 'Not Satisfied' },
        { value: 'no longer needed', label: 'No Longer Needed' },
    ]

    const getCompanyPackages = async () => {
        try {
            let res = await getAllCompanyPackages(token);
            setCompanyPackages(res?.packages);
        } catch (error) {
            console.log("🚀 ~ getCompanyTypes ~ error:", error)
        }
    };

    const getCloseCompany = async () => {
        const data = {
            company_id: getCompanyInfo.id,
            package_id: completeCompany?.company?.company?.latest_package_payment?.package,
            cancelling_reason: selectReason,
            suggestions: suggestion,
            wiped_off: wiped == true ? 1 : 0
        }
        try {
            let res = await closeCompany(token, data);
            if (res) {
                setUpdateStatus(false)
                setCompanyDetail(null)
                setAllCompanies(null)
                setCompaniesStatus(null)
                localStorage.removeItem("profileURL");
                localStorage.removeItem("tokken");
                localStorage.removeItem("token");
                localStorage.removeItem("auth0_profile");
                localStorage.removeItem("email");
                localStorage.removeItem("Name");
                localStorage.setItem("authenticated", false);
                localStorage.removeItem("companyData");
                localStorage.removeItem("companies");
                localStorage.setItem("login", false);
                window.location.reload(navigate(`${process.env.PUBLIC_URL}/login`))
            }
            setLoading(false)
            setShowPasswordModal(false)
        } catch (error) {
            setShowPasswordModal(false)
            setLoading(false)
            toast.error('You have some active assistants. Please remove assistants before closing the company.', {
                position: 'bottom-center',
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                className: 'custom-toast-error',
            })
        }
    };

    const handleVerifyPassword = async () => {
        setLoading(true)
        try {
            const formData = new FormData()
            formData.append('email', userData?.email)
            formData.append('password', password)
            let res = await verifyPassword(token, formData);

            setLoading(false)

            if (res.user) {
                getCloseCompany()
            }
        } catch (error) {
            console.log("🚀 ~ getCompanyTypes ~ error:", error)
            setLoading(false)
            // setShowPasswordModal(false)
            toast.error('Wrong password', {
                position: 'bottom-center',
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                className: 'custom-toast-error',
            })
        }
    };

    const validationSchemaOfOp = Yup.object().shape({
        // office_start_time: Yup.string().required('Office Start Time required.'),
        // office_end_time: Yup.string().required('Office End Time required.'),
        // time_zone: Yup.string().required('Time Zone is Required required'),
        // working_weekend: Yup.string().required('Weekend On/Off required.'),
        // message_not_available: Yup.string().required('Message is required.'),
        // weekly_status_update: Yup.string().required('Weekend Update is required.'),

    });

    const opSettingSubmit = async (values) => {
        setSaveSettingLoader(true)
        try {
            const formData = new FormData();
            formData.append('company_id', getCompanyInfo.id)
            if (values.office_start_time) {
                formData.append('office_start_time', values?.office_start_time + ':00');
            } else {
                formData.append('office_start_time', opSetting?.office_start_time);
            }
            if (values.office_end_time) {
                formData.append('office_end_time', values?.office_end_time + ':00');
            } else {
                formData.append('office_end_time', opSetting?.office_end_time);
            }
            if (values.time_zone) {
                formData.append('time_zone', values?.time_zone);
            } else {
                formData.append('time_zone', opSetting.time_zone);
            }

            if (values.working_weekend) {
                formData.append('working_weekend', values?.working_weekend);
            } else {
                formData.append('working_weekend', opSetting?.working_weekend);
            }

            if (values.message_not_available) {
                formData.append('message_not_available', values?.message_not_available)
            } else {
                formData.append('message_not_available', opSetting?.message_not_available)
            }
            if (values.weekly_status_update) {
                formData.append('weekly_status_update', values?.weekly_status_update);
            } else {
                formData.append('weekly_status_update', opSetting?.weekly_status_update)
            }
            if (opSetting.id === 0) {
                const res = await createOpSetting(formData, token);
                if (res) {
                    // handleGetAllCompany()
                    dispatch({ type: ActionTypes.FETCH_USER_COMPLETE_COMPANY, payload: { company_id: getCompanyInfo.id } });
                    setSaveSettingLoader(false)
                    toast.info("Operation setting save Successfully.", {
                        position: "bottom-center",
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                        className: 'custom-toast',
                    });
                }
            } else {
                const res = await updateOpSetting(opSetting.id, formData, token);
                if (res) {
                    // handleGetAllCompany()
                    dispatch({ type: ActionTypes.FETCH_USER_COMPLETE_COMPANY, payload: { company_id: getCompanyInfo.id } });
                    setSaveSettingLoader(false)
                    toast.info("Operation Settings Saved.", {
                        position: "bottom-center",
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                        className: 'custom-toast',
                    });
                }
            }
        } catch (error) {
            console.log("🚀 ~ opSettingSubmit ~ error:", error)
            setSaveSettingLoader(false)
        }



    };

    const handleTimezoneChange = (newTimezone) => {
        // Save the selected timezone in the form and localStorage

    };

    const handleGetAllCompany = async () => {
        const formData = new FormData();
        formData.append("company_id", getCompanyInfo?.id);

        try {
            const res = await getAllCompanyData(token, formData);

            setCompanyStatusData(res?.company?.companyStatus)
            localStorage.setItem("companyStatus", JSON.stringify(res?.company?.companyStatus))

        } catch (error) {
            console.error("Error fetching company data:", error);
        }
    };

    const MenuTile = (props) => {
        const { eventKey, title, subtitle, icon } = props;

        return (
            <Nav.Item>
                <Nav.Link eventKey={eventKey}>
                    <div className={'d-flex align-items-center gap-3 setting-tile-wrapper'}>
                        <div className={'flex-grow-0'}>
                            <div className={'circle-icon'}>
                                {icon}
                            </div>
                        </div>
                        <div className={'flex-grow-2'}>
                            <div>
                                <span className={'setting-tile-title'}>{title}</span> <br />
                                <span className={'setting-tile-subtitle'}>{subtitle}</span>
                            </div>
                        </div>
                        <div className={'flex-grow-0'} style={{ marginLeft: 'auto' }}>
                            <RightArrowIcon />
                        </div>
                    </div>
                </Nav.Link>
            </Nav.Item>
        );
    }

    const userProfile = getUserProfile();
    // const [defaultActive, setDefaultActive] = useState('operation');

    // const [searchParams] = useSearchParams();
    // const activeTab = searchParams.get('tab');

    // useEffect(() => {
    //     if (activeTab === 'subscription') {
    //         setDefaultActive('subscription');
    //     }
    // }, [activeTab]);

    const [defaultActive, setDefaultActive] = useState(tab);
    const [searchParams, setSearchParams] = useSearchParams();
    const activeTab = searchParams.get('tab');

    useEffect(() => {
        if (activeTab) {
            setDefaultActive(activeTab);
        }
    }, [activeTab]);

    const handleSelect = (k) => {
        setDefaultActive(k);
        setSearchParams({ tab: k }); // Update the URL parameter when the tab changes
    };

    const getStripeSession = async () => {
        setStripeLoading(true);
        try {
            // Dispatch action or API call
            await dispatch({ type: ActionTypes.GET_STRIPE_SESSION });
        } catch (error) {
            console.error("Error in getStripeSession:", error);
        } finally {
            setStripeLoading(false);
        }
    };

    return (
        <Fragment>
            <Container fluid={true}>
                <Row>
                    <Card style={{ height: '100%', marginTop: '4em', marginRight: '2em' }}>
                        <CardBody>
                            <Tab.Container id="left-tabs-example" activeKey={defaultActive} onSelect={handleSelect}>
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <H3>Account Settings</H3>
                                    {tabLocation === "?tab=subscription" &&
                                        <p style={{ textDecorationLine: 'underline', color: Color.PRIMARY, cursor: 'pointer' }} onClick={() => stripeLoading ? "" : getStripeSession()}>Payment Portal</p>
                                    }
                                </div>
                                <Row style={{ marginTop: '5em' }}>
                                    <Col sm={4}>
                                        <Nav variant="pills" className="flex-column">
                                            <MenuTile eventKey={'account'} title={'Manage Profile'}
                                                subtitle={'Manage your account settings'}
                                                icon={<AccountSettingsIcon style={{ fill: '#5679D5' }} />} />
                                            <MenuTile eventKey={'operation'} title={'Operation Settings'}
                                                subtitle={'Manage your account settings'}
                                                icon={<OperationsIcon style={{ fill: '#5679D5' }} />} />
                                            <MenuTile eventKey={'subscription'} title={'Manage Subscription'}
                                                subtitle={'Manage your account settings'}
                                                icon={<SubscriptionsIcon style={{ stroke: '#5679D5' }} />} />
                                            {/* <MenuTile eventKey={'update-card'} title={'Update Payment Method'}
                                                subtitle={'Update your card details'}
                                                icon={<FeatherIcon icon={'credit-card'} style={{ stroke: '#5679D5' }} />} /> */}
                                        </Nav>
                                    </Col>
                                    <Col sm={8}>
                                        <Tab.Content>
                                            <Tab.Pane eventKey="account" title="Account Settings">
                                                <Row>
                                                    <Col sm={8}>
                                                        <Card style={{
                                                            padding: '10px',
                                                            borderRadius: '5px',
                                                            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                                                        }}>
                                                            <H4>Change Password</H4>
                                                            <Formik
                                                                initialValues={{
                                                                    oldPassword: '',
                                                                    newPassword: '',
                                                                    confirmPassword: ''
                                                                }}
                                                                validationSchema={validationSchema}
                                                                onSubmit={handleSubmit}
                                                            >
                                                                {({
                                                                    handleSubmit,
                                                                    handleChange,
                                                                    handleBlur,
                                                                    values,
                                                                    errors,
                                                                    touched,
                                                                    resetForm,
                                                                }) => (
                                                                    <Form onSubmit={handleSubmit}>
                                                                        <FormGroup>
                                                                            <Label className="col-form-label m-0" style={{ color: labelColor }}>
                                                                                Old Password
                                                                            </Label>
                                                                            <div style={{ position: 'relative', display: 'flex', alignItems: 'center', border: '1px solid #ccc', borderRadius: '8px', overflow: 'hidden' }}>
                                                                                <Field
                                                                                    className="form-control"
                                                                                    type={showOldPassword ? 'text' : 'password'}
                                                                                    name="oldPassword"
                                                                                    onChange={handleChange}
                                                                                    onBlur={handleBlur}
                                                                                    value={values.oldPassword}
                                                                                    placeholder="Enter old password"
                                                                                    style={{
                                                                                        border: 'none',
                                                                                        boxShadow: 'none',
                                                                                        paddingLeft: '15px',
                                                                                        paddingRight: '50px',
                                                                                    }}
                                                                                />
                                                                                <div
                                                                                    onClick={() => setShowOldPassword(!showOldPassword)}
                                                                                    style={{
                                                                                        position: 'absolute',
                                                                                        right: '0',
                                                                                        height: '100%',
                                                                                        width: '40px',
                                                                                        display: 'flex',
                                                                                        alignItems: 'center',
                                                                                        justifyContent: 'center',
                                                                                        backgroundColor: '#f7f7f7',
                                                                                        cursor: 'pointer',
                                                                                    }}
                                                                                >
                                                                                    {showOldPassword ? <FaEyeSlash /> : <FaEye />}
                                                                                </div>
                                                                            </div>
                                                                            <div style={{ height: '10px' }}>
                                                                                <ErrorMessage name="oldPassword" component="div" className="text-danger" />
                                                                            </div>
                                                                        </FormGroup>

                                                                        <FormGroup>
                                                                            <Label className="col-form-label m-0" style={{ color: labelColor }}>
                                                                                New Password
                                                                            </Label>
                                                                            <div style={{ position: 'relative', display: 'flex', alignItems: 'center', border: '1px solid #ccc', borderRadius: '8px', overflow: 'hidden' }}>
                                                                                <Field
                                                                                    className="form-control"
                                                                                    type={showNewPassword ? 'text' : 'password'}
                                                                                    name="newPassword"
                                                                                    onChange={handleChange}
                                                                                    onBlur={handleBlur}
                                                                                    value={values.newPassword}
                                                                                    placeholder="Enter new password"
                                                                                    style={{
                                                                                        border: 'none',
                                                                                        boxShadow: 'none',
                                                                                        paddingLeft: '15px',
                                                                                        paddingRight: '50px',
                                                                                    }}
                                                                                />
                                                                                <div
                                                                                    onClick={() => setShowNewPassword(!showNewPassword)}
                                                                                    style={{
                                                                                        position: 'absolute',
                                                                                        right: '0',
                                                                                        height: '100%',
                                                                                        width: '40px',
                                                                                        display: 'flex',
                                                                                        alignItems: 'center',
                                                                                        justifyContent: 'center',
                                                                                        backgroundColor: '#f7f7f7',
                                                                                        cursor: 'pointer',
                                                                                    }}
                                                                                >
                                                                                    {showNewPassword ? <FaEyeSlash /> : <FaEye />}
                                                                                </div>
                                                                            </div>
                                                                            <div style={{ height: '10px' }}>
                                                                                <ErrorMessage name="newPassword" component="div" className="text-danger" />
                                                                            </div>
                                                                        </FormGroup>

                                                                        <FormGroup>
                                                                            <Label className="col-form-label m-0" style={{ color: labelColor }}>
                                                                                Confirm Password
                                                                            </Label>
                                                                            <div style={{ position: 'relative', display: 'flex', alignItems: 'center', border: '1px solid #ccc', borderRadius: '8px', overflow: 'hidden' }}>
                                                                                <Field
                                                                                    className="form-control"
                                                                                    type={showConfirmPassword ? 'text' : 'password'}
                                                                                    name="confirmPassword"
                                                                                    onChange={handleChange}
                                                                                    onBlur={handleBlur}
                                                                                    value={values.confirmPassword}
                                                                                    placeholder="Enter confirm password"
                                                                                    style={{
                                                                                        border: 'none',
                                                                                        boxShadow: 'none',
                                                                                        paddingLeft: '15px',
                                                                                        paddingRight: '50px',
                                                                                    }}
                                                                                />
                                                                                <div
                                                                                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                                                                                    style={{
                                                                                        position: 'absolute',
                                                                                        right: '0',
                                                                                        height: '100%',
                                                                                        width: '40px',
                                                                                        display: 'flex',
                                                                                        alignItems: 'center',
                                                                                        justifyContent: 'center',
                                                                                        backgroundColor: '#f7f7f7',
                                                                                        cursor: 'pointer',
                                                                                    }}
                                                                                >
                                                                                    {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
                                                                                </div>
                                                                            </div>
                                                                            <div style={{ height: '10px' }}>
                                                                                <ErrorMessage name="confirmPassword" component="div" className="text-danger" />
                                                                            </div>
                                                                        </FormGroup>

                                                                        <Button
                                                                            disabled={changePassLoader}
                                                                            style={{ marginLeft: '0px', minWidth: '10em' }}
                                                                            variant="primary"
                                                                            type="submit"
                                                                        >
                                                                            {changePassLoader ? 'Changing...' : 'Change Password'}
                                                                        </Button>
                                                                    </Form>
                                                                )}
                                                            </Formik>
                                                        </Card>
                                                    </Col>
                                                </Row>

                                                <Row>
                                                    <Col sm={8}>

                                                        <Card style={{
                                                            padding: '10px',
                                                            borderRadius: '5px',
                                                            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                                                            marginTop: '3%'
                                                        }}>
                                                            <H4>Update Profile</H4>
                                                            <Formik
                                                                initialValues={{
                                                                    mobile: userNumber ? userNumber : userProfile?.mobile
                                                                }}
                                                                validationSchema={mobileValidationSchema}
                                                                onSubmit={handleMobileSubmit}
                                                            >
                                                                {({
                                                                    handleSubmit,
                                                                    handleChange,
                                                                    handleBlur,
                                                                    values,
                                                                    errors,
                                                                    touched
                                                                }) => (
                                                                    <Form onSubmit={handleSubmit}>
                                                                        <FormGroup>
                                                                            <Label className='col-form-label m-0'
                                                                                style={{ color: labelColor }}>Name</Label>
                                                                            <Field
                                                                                className='form-control'
                                                                                name='name'
                                                                                disabled={true}
                                                                                value={userProfile?.name}
                                                                                placeholder='Enter your name'
                                                                            />
                                                                        </FormGroup>
                                                                        <FormGroup>
                                                                            <Label className='col-form-label m-0'
                                                                                style={{ color: labelColor }}>Email</Label>
                                                                            <Field
                                                                                className='form-control'
                                                                                name='email'
                                                                                disabled={true}
                                                                                value={userProfile?.email}
                                                                                placeholder='Enter your email'
                                                                            />
                                                                        </FormGroup>
                                                                        {/* <FormGroup>
                                                                            <Label className='col-form-label m-0'
                                                                                style={{ color: labelColor }}>Phone Number</Label>
                                                                            <Field
                                                                                className='form-control'
                                                                                type='mobile'
                                                                                name='mobile'
                                                                                onChange={handleChange}
                                                                                onBlur={handleBlur}
                                                                                value={values.mobile}
                                                                                placeholder='Enter your mobile'
                                                                            />
                                                                            <div style={{ height: '10px' }}>
                                                                                <ErrorMessage name="mobile"
                                                                                    component="div"
                                                                                    className="text-danger" />
                                                                            </div>
                                                                        </FormGroup> */}
                                                                        <FormGroup>
                                                                            <Label
                                                                                className={`col-sm-3 col-form-label ${theme === 'dark-only' ? 'dark-theme-label' : ''}`}
                                                                                htmlFor="phone"
                                                                            >
                                                                                Phone Number
                                                                            </Label>
                                                                            <Col sm="12">
                                                                                <div
                                                                                    className={`phone-input-container ${theme === 'dark-only' ? 'dark-theme-phone-input' : ''}`}
                                                                                >
                                                                                    <PhoneInput
                                                                                        international
                                                                                        countryCallingCodeEditable={false}
                                                                                        defaultCountry="US"
                                                                                        value={values.mobile}
                                                                                        onChange={(e) => {
                                                                                            handleChange({ target: { name: 'mobile', value: e } });
                                                                                            // setPhoneError('');
                                                                                        }}
                                                                                        className={theme === 'dark-only' ? 'dark-theme-phone-input' : ''}
                                                                                        countries={['US', 'CA']}
                                                                                        limitMaxLength={12}
                                                                                    />
                                                                                </div>
                                                                                {errors.mobile && touched.mobile && (
                                                                                    <div className="text-danger">{errors.mobile}</div>
                                                                                )}
                                                                            </Col>
                                                                        </FormGroup>


                                                                        <Button style={{
                                                                            marginLeft: '0px',
                                                                            minWidth: '10em'
                                                                        }} variant="primary" type="submit">
                                                                            Update Mobile
                                                                        </Button>
                                                                    </Form>
                                                                )}
                                                            </Formik>
                                                        </Card>
                                                    </Col>
                                                </Row>

                                            </Tab.Pane>

                                            <Tab.Pane eventKey="operation" title="Operation Settings">
                                                <Card style={{
                                                    padding: '10px',
                                                    borderRadius: '5px',
                                                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                                                }}>
                                                    <H4 style={{ marginBottom: "20px" }}>Operation Settings</H4>
                                                    <br />
                                                    <Formik
                                                        initialValues={opSetting}
                                                        validationSchema={validationSchemaOfOp}
                                                        onSubmit={opSettingSubmit}
                                                    >
                                                        {({
                                                            handleSubmit,
                                                            handleChange,
                                                            handleBlur,
                                                            values,
                                                            errors,
                                                            touched,
                                                            setFieldValue
                                                        }) => (
                                                            <Form
                                                                onSubmit={handleSubmit}>
                                                                <div style={{
                                                                    display: 'flex',
                                                                    justifyContent: 'space-between',
                                                                    gap: '10px'
                                                                }}>
                                                                    <FormGroup style={{ flex: '1', marginBottom: "5px" }}>
                                                                        <Label className='col-form-label m-0'
                                                                            style={{ color: labelColor }}>Office Start
                                                                            Time</Label>
                                                                        <Field
                                                                            className='form-control'
                                                                            type='time'
                                                                            name='office_start_time'
                                                                            onChange={handleChange}
                                                                            onBlur={handleBlur}
                                                                            value={values.office_start_time ? values.office_start_time : opSetting?.office_start_time}
                                                                            placeholder='Enter Office Start Time'
                                                                        />
                                                                        <div style={{ height: '10px' }}>
                                                                            <ErrorMessage name="office_start_time"
                                                                                component="div"
                                                                                className="text-danger" />
                                                                        </div>
                                                                    </FormGroup>

                                                                    <FormGroup style={{ flex: '1' }}>
                                                                        <Label className='col-form-label m-0'
                                                                            style={{ color: labelColor }}>Office End
                                                                            Time</Label>
                                                                        <Field
                                                                            className='form-control'
                                                                            type='time'
                                                                            name='office_end_time'
                                                                            onChange={handleChange}
                                                                            onBlur={handleBlur}
                                                                            value={values.office_end_time ? values.office_end_time : opSetting.office_end_time}
                                                                            placeholder='Enter Office End Time'
                                                                        />
                                                                        <div style={{ height: '10px' }}>
                                                                            <ErrorMessage name="office_end_time"
                                                                                component="div"
                                                                                className="text-danger" />
                                                                        </div>
                                                                    </FormGroup>
                                                                </div>

                                                                {/* Time Zone */}
                                                                <div style={{
                                                                    display: 'flex',
                                                                    justifyContent: 'space-between',
                                                                    gap: '10px'
                                                                }}>
                                                                    {/* <FormGroup style={{ flex: '1' }}>
                                                                <Label className='col-form-label m-0' style={{ color: labelColor }}>Time Zone</Label>
                                                                <Field
                                                                    as='select'
                                                                    className='form-control'
                                                                    name='time_zone'
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    value={values.time_zone}
                                                                >
                                                                    <option value="" disabled>Select Time Zone</option>
                                                                    {americanAndCanadianZones.map((zone) => (
                                                                        <option key={zone} value={zone}>{zone}</option>
                                                                    ))}
                                                                    <option disabled>─────────────</option>
                                                                    {moment.tz.names().map((zone) => (
                                                                        !americanAndCanadianZones.includes(zone) && (
                                                                            <option key={zone} value={zone}>{zone}</option>
                                                                        )
                                                                    ))}
                                                                </Field>
                                                                <div style={{ height: '10px' }}>
                                                                    <ErrorMessage name="time_zone" component="div" className="text-danger" />
                                                                </div>
                                                            </FormGroup> */}

                                                                    <FormGroup style={{ flex: '1' }}>
                                                                        <Label className='col-form-label m-0'
                                                                            style={{ color: labelColor }}>Time Zone</Label>
                                                                        {/* <TimezoneSelect
                                                                    value={values.time_zone}
                                                                    onChange={(newTimezone) => {
                                                                        setFieldValue('time_zone', newTimezone.value);
                                                                        setTimeZone(newTimezone.value)
                                                                    }}
                                                                /> */}

                                                                        <TimezoneSelect
                                                                            value={values.time_zone ? values.time_zone : opSetting?.time_zone}
                                                                            onChange={(newTimezone) => {
                                                                                setFieldValue('time_zone', newTimezone.value);
                                                                            }}
                                                                            onBlur={handleBlur}
                                                                            styles={{
                                                                                control: (base) => ({
                                                                                    ...base,
                                                                                    backgroundColor: theme == 'dark-only' ? '#1D1E26' : 'white',
                                                                                    borderColor: theme == 'dark-only' ? '#1D1E26' : '#dee2e6',
                                                                                    color: theme == 'dark-only' ? 'rgb(173, 175, 181)' : '#000'
                                                                                }),
                                                                                singleValue: (base) => ({
                                                                                    ...base,
                                                                                    color: theme == 'dark-only' ? 'rgb(173, 175, 181)' : '#000'
                                                                                }),
                                                                                menu: (base) => ({
                                                                                    ...base,
                                                                                    backgroundColor: theme == 'dark-only' ? '#1D1E26' : 'white',
                                                                                    color: theme == 'dark-only' ? 'rgb(173, 175, 181)' : '#000'
                                                                                }),
                                                                                option: (base) => ({
                                                                                    ...base,
                                                                                    backgroundColor: theme == 'dark-only' ? '#1D1E26' : 'white',
                                                                                    color: theme == 'dark-only' ? 'rgb(173, 175, 181)' : '#000'
                                                                                }),
                                                                            }}
                                                                        />

                                                                        <div style={{ height: '10px' }}>
                                                                            <ErrorMessage name="time_zone"
                                                                                component="div"
                                                                                className="text-danger" />
                                                                        </div>
                                                                    </FormGroup>

                                                                    {/* Weekend On/Off */}
                                                                    <FormGroup style={{ flex: '1' }}>
                                                                        <Label className='col-form-label m-0'
                                                                            style={{ color: labelColor }}>Weekend (Sat, Sun) are working days</Label>

                                                                        <div
                                                                            className='m-t-15 m-checkbox-inline custom-radio-ml'
                                                                            style={{ paddingLeft: "10px" }}>
                                                                            <div className='radio radio-primary'>
                                                                                <Field
                                                                                    id='radioinline1'
                                                                                    type='radio'
                                                                                    name='working_weekend'
                                                                                    value="0"  // String value
                                                                                    as={Input}
                                                                                    onChange={handleChange}
                                                                                    onBlur={handleBlur}
                                                                                    checked={opSetting.working_weekend === '0' ? '0' : values.working_weekend === "1" ? "1" : "0"} // Compare with Formik's values
                                                                                />
                                                                                <Label className='mb-0'
                                                                                    for='radioinline1'
                                                                                    style={{ color: theme == 'dark-only' ? 'rgb(173, 175, 181)' : '#000' }}>
                                                                                    Off
                                                                                </Label>
                                                                            </div>
                                                                            <div className='radio radio-primary'>
                                                                                <Field
                                                                                    id='radioinline2'
                                                                                    type='radio'
                                                                                    name='working_weekend'
                                                                                    value="1"  // String value
                                                                                    as={Input}
                                                                                    onChange={handleChange}
                                                                                    onBlur={handleBlur}
                                                                                    checked={values.working_weekend === "1"} // Compare with Formik's values
                                                                                />
                                                                                <Label className='mb-0'
                                                                                    for='radioinline2'
                                                                                    style={{ color: theme == 'dark-only' ? 'rgb(173, 175, 181)' : '#000' }}>
                                                                                    On
                                                                                </Label>
                                                                            </div>
                                                                        </div>


                                                                        {/* <div className=' m-checkbox-inline custom-radio-ml'>
                                                                                    <div className='checkbox checkbox-dark'>
                                                                                        <Input id='inline-1' type='checkbox'
                                                                                        name='working_weekend'
                                                                                        onChange={handleChange}
                                                                                        onBlur={handleBlur}
                                                                                        value={0}
                                                                                        defaultChecked={opSetting.working_weekend === 0 ? true : false}
                                                                                        />
                                                                                        <Label for='inline-1'>
                                                                                        Off
                                                                                        </Label>
                                                                                    </div>
                                                                                    <div className='checkbox checkbox-dark'>
                                                                                        <Input id='inline-2' type='checkbox'
                                                                                        name='working_weekend'
                                                                                        onChange={handleChange}
                                                                                        onBlur={handleBlur}
                                                                                        value={1}
                                                                                        defaultChecked={opSetting.working_weekend === 1 ? true : false}
                                                                                        />
                                                                                        <Label for='inline-2'>
                                                                                        On
                                                                                        </Label>
                                                                                    </div>
                                                                                </div> */}
                                                                        {/*
                                                                                <Field
                                                                                    as="select"
                                                                                    className='form-control'
                                                                                    name='working_weekend'
                                                                                    onChange={handleChange}
                                                                                    onBlur={handleBlur}
                                                                                    value={values.working_weekend}
                                                                                >
                                                                                    <option value="">Select Option</option>
                                                                                    <option value="1">On</option>
                                                                                    <option value="0">Off</option>
                                                                                </Field> */}

                                                                        <div style={{ height: '10px' }}>
                                                                            <ErrorMessage name="working_weekend"
                                                                                component="div"
                                                                                className="text-danger" />
                                                                        </div>
                                                                    </FormGroup>
                                                                </div>

                                                                {/* Message */}
                                                                <div style={{
                                                                    display: 'flex',
                                                                    justifyContent: 'space-between',
                                                                    gap: '10px'
                                                                }}>
                                                                    <FormGroup style={{ flex: '1' }}>
                                                                        <Label className='col-form-label m-0'
                                                                            style={{ color: labelColor }}>Not Available Answer</Label>
                                                                        <Field
                                                                            className='form-control'
                                                                            type='textarea'
                                                                            component="textarea"
                                                                            name='message_not_available'
                                                                            onChange={handleChange}
                                                                            onBlur={handleBlur}
                                                                            value={values.message_not_available ? values.message_not_available : opSetting.message_not_available}
                                                                            placeholder='Enter Not Available Answer'
                                                                        />
                                                                        <div style={{ height: '10px' }}>
                                                                            <ErrorMessage name="message_not_available"
                                                                                component="div"
                                                                                className="text-danger" />
                                                                        </div>
                                                                    </FormGroup>
                                                                </div>

                                                                {/* Weekend Updated */}
                                                                <div>
                                                                    {/*<FormGroup style={{flex: '1'}}>*/}
                                                                    {/*    <Label className='col-form-label m-0'*/}
                                                                    {/*           style={{color: labelColor}}>Receive*/}
                                                                    {/*        weekly update on your AI Assistant*/}
                                                                    {/*        Performance</Label>*/}
                                                                    {/*    <Field*/}
                                                                    {/*        as="select"*/}
                                                                    {/*        className='form-control'*/}
                                                                    {/*        name='weekly_status_updates'*/}
                                                                    {/*        onChange={handleChange}*/}
                                                                    {/*        onBlur={handleBlur}*/}
                                                                    {/*        value={values.weekly_status_update ? values.weekly_status_update : opSetting.weekly_status_update}*/}
                                                                    {/*    >*/}
                                                                    {/*        /!* <option value="">Select Option</option> *!/*/}
                                                                    {/*        <option value="1">On</option>*/}
                                                                    {/*        <option value="0">Off</option>*/}
                                                                    {/*    </Field>*/}
                                                                    {/*    <div style={{height: '10px'}}>*/}
                                                                    {/*        <ErrorMessage name="weekly_status_update"*/}
                                                                    {/*                      component="div"*/}
                                                                    {/*                      className="text-danger"/>*/}
                                                                    {/*    </div>*/}
                                                                    {/*</FormGroup>*/}

                                                                    <div
                                                                        className='m-t-5 m-checkbox-inline custom-radio-ml'>

                                                                        <FormGroup style={{ flex: '1' }}>
                                                                            <Label className='col-form-label m-0'
                                                                                style={{ color: labelColor }}>Receive weekly update on your AI Assistant Performance</Label>
                                                                            <div
                                                                                className='m-t-10 m-checkbox-inline custom-radio-ml'>
                                                                                <div className='radio radio-primary'>
                                                                                    <Field
                                                                                        id='radioinline3'
                                                                                        type='radio'
                                                                                        name='weekly_status_update'
                                                                                        value="0"  // String value
                                                                                        as={Input}
                                                                                        onChange={handleChange}
                                                                                        onBlur={handleBlur}
                                                                                        checked={opSetting.weekly_status_update === '0' ? '0' : values.weekly_status_update === "0" ? "0" : "0"} // Compare with Formik's values
                                                                                    />
                                                                                    <Label className='mb-0'
                                                                                        for='radioinline3'
                                                                                        style={{ color: theme == 'dark-only' ? 'rgb(173, 175, 181)' : '#000' }}>
                                                                                        Off
                                                                                    </Label>
                                                                                </div>
                                                                                <div className='radio radio-primary'>
                                                                                    <Field
                                                                                        id='radioinline4'
                                                                                        type='radio'
                                                                                        name='weekly_status_update'
                                                                                        value="1"  // String value
                                                                                        as={Input}
                                                                                        onChange={handleChange}
                                                                                        onBlur={handleBlur}
                                                                                        checked={values.weekly_status_update === "1"} // Compare with Formik's values
                                                                                    />
                                                                                    <Label className='mb-0'
                                                                                        for='radioinline4'
                                                                                        style={{ color: theme == 'dark-only' ? 'rgb(173, 175, 181)' : '#000' }}>
                                                                                        On
                                                                                    </Label>
                                                                                </div>
                                                                            </div>

                                                                            <div style={{ height: '10px' }}>
                                                                                <ErrorMessage name="weekly_status_update"
                                                                                    component="div"
                                                                                    className="text-danger" />
                                                                            </div>
                                                                        </FormGroup>
                                                                    </div>
                                                                </div>


                                                                {/* Submit Button */}
                                                                <Button disabled={saveSettingLoader}
                                                                    style={{ marginLeft: '0px', minWidth: '10em' }}
                                                                    variant="primary" type="submit">
                                                                    {saveSettingLoader ? 'Saving' : 'Save Settings'}
                                                                </Button>
                                                            </Form>
                                                        )}
                                                    </Formik>

                                                </Card>
                                            </Tab.Pane>

                                            <Tab.Pane eventKey="subscription">
                                                <Row>
                                                    <Col>
                                                        <Packages />
                                                        {/* <CancelSubscription /> */}
                                                        <div style={{ marginTop: '10px', display: 'flex' }} >
                                                            <p style={{ fontSize: '16px', fontWeight: '500', cursor: 'pointer', }} onClick={() => setCancellation(!cancellation)}>Cancel Subscription</p>
                                                            {!cancellation ?
                                                                <IoIosArrowUp size={20} style={{ marginTop: '5px', marginLeft: '5px', cursor: 'pointer', }} onClick={() => setCancellation(!cancellation)} /> : <IoIosArrowDown size={20} style={{ marginTop: '5px', marginLeft: '5px', cursor: 'pointer', }} onClick={() => setCancellation(!cancellation)} />
                                                            }
                                                        </div>
                                                        {!cancellation &&
                                                            <>
                                                                <Row>
                                                                    <Col sm='4'>
                                                                        <P attrPara={{ style: { fontSize: '16px', marginBottom: '0px' } }}>I am cancelling because</P>
                                                                    </Col>
                                                                    <Col sm='8'>
                                                                        <Select
                                                                            options={select}
                                                                            styles={customStyles}
                                                                            onChange={(e) => {
                                                                                console.log("Selected Value", e.label);
                                                                                setSelectReason(e.label)
                                                                            }}
                                                                        // defaultValue={deparmentsDropdown?.find(option => option.value === option.value)}
                                                                        />
                                                                    </Col>
                                                                </Row>
                                                                <Row>
                                                                    <Col sm='4'>
                                                                        <P attrPara={{ style: { fontSize: '16px', marginTop: '20px' } }}>Any suggestions for improvements</P>
                                                                    </Col>
                                                                    <Col sm='8'>
                                                                        <textarea
                                                                            style={{
                                                                                width: '100%',
                                                                                height: '100px',
                                                                                padding: '10px',
                                                                                borderRadius: '5px',
                                                                                border: '1px solid #ccc',
                                                                                fontSize: '16px',
                                                                                marginTop: '20px',
                                                                                outline: 'none'
                                                                            }}
                                                                            placeholder="Enter suggestion"
                                                                            onChange={(e) => {
                                                                                console.log("Entered Text", e.target.value);
                                                                                setSuggestion(e.target.value)
                                                                            }}
                                                                        />
                                                                        <div className='form-group m-0' style={{ marginTop: '10px' }}>
                                                                            <div className='checkbox'>
                                                                                <input
                                                                                    type='checkbox'
                                                                                    name='agree'
                                                                                    id='checkbox1'
                                                                                    defaultChecked={wiped}
                                                                                    style={{
                                                                                        width: '16px',
                                                                                        height: '16px',
                                                                                        border: '2px solid #333', // Darker border color
                                                                                        borderRadius: '3px',
                                                                                        appearance: 'none', // Removes default browser styling
                                                                                        outline: 'none',
                                                                                        cursor: 'pointer',
                                                                                    }}
                                                                                    onChange={(e) => {
                                                                                        console.log(e.target.checked)
                                                                                        setWiped(e.target.checked)
                                                                                    }}
                                                                                />
                                                                                <label className='text-muted' htmlFor='checkbox1' style={{ fontWeight: '500' }}>
                                                                                    All of my data will be wiped off within 30 days. I am fine with it.
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                        <div style={{ marginTop: '2%' }}>
                                                                            <p style={{ lineHeight: 1 }}>You can also freeze your account instead of deleting it completely.</p>
                                                                            <p style={{ lineHeight: 1, marginTop: '-10px' }}>You can unfreeze your account anytime you want.</p>
                                                                        </div>
                                                                        <div style={{ marginLeft: '-10px', display: 'flex', alignItems: 'center' }}>
                                                                            <Button style={{ fontWeight: 'bold' }}>Freeze My Account</Button>
                                                                            <p style={{ fontWeight: '500', cursor: 'pointer', textDecorationLine: 'underline', color: '#FF5757' }} onClick={handleDeleteClick}>Proceed to delete my account</p>
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                            </>
                                                        }
                                                    </Col>
                                                </Row>
                                            </Tab.Pane>

                                            <Tab.Pane eventKey="update-card">
                                                <Row>
                                                    <Col>
                                                        <UpdateCard />
                                                    </Col>
                                                </Row>
                                            </Tab.Pane>
                                        </Tab.Content>
                                    </Col>
                                </Row>
                            </Tab.Container>
                        </CardBody>
                    </Card>
                </Row>
            </Container>
            <Modal show={showConfirmation} centered onHide={() => setShowConfirmation(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Delete Subscription</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <H4>Are you sure you want to proceed?</H4>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button style={{ height: '40px' }} variant="primary" onClick={handleYesClick}>Yes</Button>
                    <Button style={{ height: '40px' }} variant="secondary"
                        onClick={() => setShowConfirmation(false)}>No</Button>
                </Modal.Footer>
            </Modal>

            {/* Second password entry popup */}
            <Modal show={showPasswordModal} centered onHide={() => setShowPasswordModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Please enter your password to proceed</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <FormGroup>
                        <Label>Password</Label>
                        <div className="password-wrapper">
                            <input
                                type={showPassword ? 'text' : 'password'}
                                className="form-control"
                                placeholder="Enter your password"
                                onChange={(e) => setPassword(e.target.value)}
                            />
                            <span
                                className="password-toggle-icon"
                                onClick={togglePasswordVisibility}
                            >
                                {showPassword ? <FaEyeSlash /> : <FaEye />}
                            </span>
                        </div>
                    </FormGroup>
                </Modal.Body>
                <Modal.Footer>
                    {loading ?
                        <Button disabled>
                            <Spinner
                                as="span"
                                animation="grow"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            />
                            Submiting...
                        </Button>
                        :
                        <Button style={{ height: '40px' }} variant="primary" onClick={handleVerifyPassword}>
                            Submit
                        </Button>
                    }

                    <Button
                        style={{ height: '40px' }}
                        variant="secondary"
                        onClick={() => setShowPasswordModal(false)}
                    >
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
            <ToastContainer />
        </Fragment>
    );
};

export default Settings;
