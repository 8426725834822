import React, { Fragment, useEffect, useRef, useState } from 'react';
import { Col, Container, Row, Card, CardBody, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import { Breadcrumbs, H5 } from "../../AbstractElements";
import DataTable from 'react-data-table-component';
import { H4 } from '../../AbstractElements';
import { keywordReport, getSingleKeywordDetails } from '../../api/AuthIndex';
import { dummytabledata } from '../../Data/Table/Defaultdata';
import moment from 'moment';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';
import Chart from 'react-apexcharts';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../ManageAssistant/ManageAssistant.css'
import { GiHamburgerMenu } from "react-icons/gi";
import { RxChatBubble, RxHamburgerMenu } from 'react-icons/rx'
import { getInitials } from '../../Constant/GlobalFunctions';
import botImage from '../../assets/images/user/botChatImg.png'
import { Modal } from 'react-bootstrap';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { debounceTime } from '../../helpers/constants';
import { useDispatch, useSelector } from 'react-redux';
import { ActionTypes } from '../../Store/redux-store/ActionTypes';
import { isEmpty } from '../../helpers/utils';
import { selectChartData, selectKewordReportChartData, selectKewordReportTableData, selectKeywordsAnalysisLoading, selectKeywordsAnalysisReport, selectTableData } from '../../Store/modules/keywordsAnalysis/keywordsAnalysisSelector';
import { Color } from '../../helpers/colors';


const Reports = ({ mainClass }) => {
    const dispatch = useDispatch();
    const keywordsData = useSelector(selectKeywordsAnalysisReport)
    console.log("🚀 ~ Reports ~ keywordsData:", keywordsData)
    const tableData = useSelector(selectKewordReportTableData)
    console.log("🚀 ~ Reports ~ tableData:", tableData)
    const apexBarChart = useSelector(selectKewordReportChartData);
    const options = JSON.parse(JSON.stringify(apexBarChart.options));
    const series = JSON.parse(JSON.stringify(apexBarChart.series));
    const isKeywordsAnalysisReport = useSelector(selectKeywordsAnalysisLoading)

    const getCompanyInfo = JSON.parse(localStorage.getItem('companyData'));
    const theme = localStorage.getItem("mix_background_layout")
    // const startOfWeek = moment().startOf('week').format('MM/DD/YY');
    // const endOfWeek = moment().endOf('week').format('MM/DD/YY');
    const startOfWeek = moment().subtract(1, 'month').format('MM/DD/YYYY');
    const endOfWeek = moment().format('MM/DD/YYYY');
    const [dateRange, setDateRange] = useState({ startDate: startOfWeek, endDate: endOfWeek });
    console.log("🚀 ~ Reports ~ dateRange:", dateRange)
    const token = localStorage?.getItem('token');
    const [selectedRows, setSelectedRows] = useState([]);
    const [toggleDelet, setToggleDelet] = useState(false);
    const [data, setData] = useState(dummytabledata);
    // const [tableData, setTableData] = useState();
    const [tableData2, setTableData2] = useState();
    const [modal, setModal] = useState(false);
    const [recordingModal, setRecordingModal] = useState(false);
    const [currentRow, setCurrentRow] = useState(null);
    const [transcript, setTranscript] = useState('');
    const [recordingUrl, setRecordingUrl] = useState('');
    const [modalContent, setModalContent] = useState(null);
    const [audio, setAudio] = useState()
    const [selectedKeywords, setSelectedKeywords] = useState([]);
    const [isPlaying, setIsPlaying] = useState(false);
    const audioRef = useRef(null);
    const [callerData, setCallerData] = useState()
    const [loading, setLoading] = useState(true);
    const [toaster, setToaster] = useState(true);
    // const [apexBarChart, setApexBarChart] = useState({
    //     series: [{
    //         data: []
    //     }],
    //     options: {
    //         chart: {
    //             type: 'bar',
    //             height: 350,
    //             toolbar: {
    //                 show: false
    //             },
    //             dropShadow: {
    //                 enabled: true,
    //                 top: 10,
    //                 left: 0,
    //                 blur: 5,
    //                 color: "#7064F5",
    //                 opacity: 0.15,
    //             },
    //         },
    //         plotOptions: {
    //             bar: {
    //                 horizontal: true,
    //             }
    //         },
    //         dataLabels: {
    //             enabled: false
    //         },
    //         colors: ["#5979D4", "#FF5757"],
    //         xaxis: {
    //             categories: [],
    //         }
    //     },
    // });

    useEffect(() => {
        console.log(apexBarChart);
        console.log("🚀 ~ Reports ~ apexBarChart:", apexBarChart);
    }, [apexBarChart]);

    useEffect(() => {
        // if (!isEmpty(startOfWeek)) {
        dispatch({ type: ActionTypes.FETCH_KEYWORDS_ANALYSIS_REPORT, payload: { company_id: getCompanyInfo.id, start_date: dateRange ? moment(dateRange?.startDate).format('YYYY-MM-DD') : moment(startOfWeek).format('YYYY-MM-DD'), end_date: dateRange ? moment(dateRange?.endDate).format('YYYY-MM-DD') : moment(endOfWeek).format('YYYY-MM-DD') } });

        // if (window.location.pathname === "/app/keyword-analysis") {
        //     if (startOfWeek) {
        //         const delayDebounceFn = setTimeout(() => {
        //             handleEventDate()
        //         }, debounceTime);
        //         return () => clearTimeout(delayDebounceFn);
        //     }
        // }
    }, []);

    const handleEventDate = async (event, picker) => {

        const startDate = picker?.startDate
        const endDate = picker?.endDate

        setDateRange({
            startDate: picker ? startDate?.format('MM/DD/YY') : startOfWeek,
            endDate: picker ? endDate?.format('MM/DD/YY') : endOfWeek,
        });
        // const formData = new FormData();
        // formData.append("company_id", getCompanyInfo?.id);
        // formData.append("start_date", picker ? picker?.startDate?.format('YYYY-MM-DD') : moment(startOfWeek).format('YYYY-MM-DD'));
        // formData.append("end_date", picker ? picker?.endDate?.format('YYYY-MM-DD') : moment(endOfWeek).format('YYYY-MM-DD'));
        dispatch({ type: ActionTypes.FETCH_KEYWORDS_ANALYSIS_REPORT, payload: { company_id: getCompanyInfo.id, start_date: moment(startDate).format('YYYY-MM-DD'), end_date: moment(endDate).format('YYYY-MM-DD') } });
        // const res = await keywordReport(token, formData);
        // setTableData(res?.records);
        // if (res?.records.length === 0) {
        //     toast.info("No data found", {
        //         position: "bottom-center",
        //         autoClose: 3000,
        //         hideProgressBar: true,
        //         closeOnClick: true,
        //         pauseOnHover: true,
        //         draggable: true,
        //         progress: undefined,
        //         theme: "light",
        //         className: 'custom-toast',
        //     })
        // }

        // const data1 = res?.records.map(item => item.keyword);
        // const data2 = res?.records.map(item => item.frequency);
        // setApexBarChart({
        //     series: [{
        //         data: data2
        //     }],
        //     options: {
        //         chart: {
        //             type: 'bar',
        //             height: 350,
        //             toolbar: {
        //                 show: false
        //             }
        //         },
        //         plotOptions: {
        //             bar: {
        //                 horizontal: true,
        //             }
        //         },
        //         dataLabels: {
        //             enabled: false
        //         },
        //         colors: ['#7366ff'],
        //         xaxis: {
        //             categories: data1,
        //         }
        //     },
        // });
        setLoading(false)
    };

    const handleTableData = async () => {
        // Your data fetching logic here
    };
    const [keyword, setKeyword] = useState('')

    const table1Ref = useRef(null);
    const table2Ref = useRef(null);

    const toggleModal = async (row) => {
        const start = dateRange.startDate;
        const end = dateRange.endDate;
        const formattedDate1 = moment(start, 'MM/DD/YYYY').format('YYYY-MM-DD');
        const formattedDate2 = moment(end, 'MM/DD/YYYY').format('YYYY-MM-DD');
        const formData = new FormData();
        formData.append("company_id", getCompanyInfo?.id);
        formData.append("start_date", formattedDate1);
        formData.append("end_date", formattedDate2);
        formData.append("keyword", row.keyword);
        const res = await getSingleKeywordDetails(token, formData);
        setKeyword(row.keyword)
        setTableData2(res?.records);
        setCurrentRow(row);

        if (table1Ref.current) {
            table1Ref.current.scrollIntoView({ behavior: 'smooth' });
        }
        if (table2Ref.current) {
            table2Ref.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const toggleTranscriptModal = () => {
        setModal(!modal);
    };

    const toggleRecordingModal = () => {
        setRecordingModal(!recordingModal);
    };

    const showTranscript = (transcript) => {
        setTranscript(transcript);
        toggleTranscriptModal();
    };

    const getRandomColor = () => {
        const colors = ['bg-color-1'];
        return colors[Math.floor(Math.random() * colors.length)];
    };

    const handleShowModal = (data) => {
        console.log("🚀 ~ handleShowModal ~ data:", data)
        let content = data?.transcript;
        let callerName = data?.customer_name

        if (typeof content === 'string') {
            const chatMessages = content.split(/(?=AI:|User:)/g).map((msg, index) => {
                const isAI = msg.startsWith('AI:');
                const highlightedMsg = highlightKeywords(msg.replace(/^(AI:|User:)/, ''), selectedKeywords);
                return isAI
                    ? `
                        <div class="ai-chat-container">
                            <img src="${botImage}" alt="AI" class="ai-image" />
                            <div class="chat-message ai">${highlightedMsg}</div>
                        </div>
                    `
                    : `
                        <div class="user-chat-container">
                            <div class="initials-circle-name ${getRandomColor()}">${getInitials(callerName == null ? "Unknown" : callerName)}</div>
                            <div class="chat-message user">${highlightedMsg}</div>
                        </div>
                    `;
            }).join('');
            setModalContent(chatMessages);
            setAudio(data?.recording_url);
            setCallerData(data);
        } else {
            setModalContent(content ? content : "No record");
        }

        setModal(true);
    };

    const highlightKeywords = (text, keywords) => {
        const allKeywords = [...keywords].filter(Boolean);
        if (allKeywords.length === 0) return text;
        const regex = new RegExp(`(${allKeywords.join('|')})`, 'gi');
        return text.replace(regex, (match) => `<mark>${match}</mark>`);
    };

    const handlePlayPause = () => {
        if (audioRef.current) {
            if (isPlaying) {
                audioRef.current.pause();
            } else {
                audioRef.current.play();
            }
            setIsPlaying(!isPlaying);
        }
    };

    const showRecording = (url) => {
        setRecordingUrl(url);
        toggleRecordingModal();
    };

    const formatCallDate = (dateString) => {
        return moment(dateString).format('MMM D, YYYY HH:mma');
    };

    const formatPhoneNumber = (phoneNumber) => {
        const cleaned = ('' + phoneNumber).replace(/\D/g, '');
        const match = cleaned.match(/^(\d{1})(\d{3})(\d{3})(\d{4})$/);
        if (match) {
            return `+${match[1]} (${match[2]}) ${match[3]}-${match[4]}`;
        }
        return phoneNumber;
    };

    const tableColumns = [
        {
            name: 'Keyword',
            sortable: false,
            center: false,
            // width: '160px',
            flex: 1,
            cell: row => <p style={{ textTransform: 'capitalize', marginLeft: '10px' }}>{row.keyword}</p>,
        },
        {
            name: 'Frequency',
            sortable: false,
            center: true,
            // width: '180px',
            flex: 1,
            cell: row =>
                <div style={{ width: '80px' }}>
                    <p style={{ textTransform: 'capitalize', textAlign: 'center' }}>{row.frequency}</p>
                </div>,
        },
        {
            name: 'Action',
            // cell: row => <Button color="primary" onClick={() => toggleModal(row)}>View</Button>,
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
            center: true,
            // width: '145px',
            flex: 1,
            cell: row => (
                <>
                    <div style={{ width: '60%' }}>
                        <div onClick={() => toggleModal(row)} style={{ width: '40px', backgroundColor: '#f7f7f7', padding: 5, justifyContent: 'center', alignItems: 'center', display: 'flex', cursor: 'pointer', borderRadius: '5px', marginLeft: '10px' }}>
                            <RxHamburgerMenu color='black' size={20} />
                        </div>
                    </div>
                </>
            ),
        },
    ];

    const tableColumns2 = [
        {
            name: 'Call Date',
            // selector: row => moment(row['call_date']).format('DD-MM-YYYY'),
            sortable: true,
            center: false,
            width: '200px',
            // flex: 1,
            cell: row => {
                const formatCallDate = (dateString) => {
                    return moment(dateString).format('MM-DD-YYYY hh:mm a');
                };
                return (
                    <>
                        <p style={{ fontSize: '12px', lineHeight: 0.5, marginLeft: '10px' }}>{formatCallDate(row?.call_date)}</p>
                    </>
                )
            },
        },
        {
            name: 'Caller',
            // selector: row => row['customer_name'],
            sortable: true,
            center: false,
            // width: '200px',
            flex: 1,
            cell: row => {
                return (
                    <>
                        <p style={{ fontSize: '12px', lineHeight: 0.5, marginLeft: '20px' }}>{row?.customer_name}</p>
                    </>
                )
            }
        },
        {
            name: 'Phone No.',
            selector: row => formatPhoneNumber(row['customer_phone']),
            sortable: true,
            center: false,
            // width: '200px',
            flex: 1,
        },
        {
            name: 'Attended By',
            selector: row => row['bot_name'],
            sortable: true,
            center: false,
            // width: '200px',
            flex: 1,
        },
        {
            name: 'Frequency',
            // selector: row => row['frequency'],
            sortable: true,
            center: false,
            // width: '200px',
            flex: 1,
            cell: row => {
                return (
                    <>
                        <p style={{ fontSize: '12px', lineHeight: 0.5, marginLeft: '30px' }}>{row?.frequency}</p>
                    </>
                )
            }
        },
        // {
        //     name: 'Recording',
        //     cell: row => (
        //         <div>
        //             <Button color="primary" onClick={() => showRecording(row['recording_url'])}>Listen Audio</Button>
        //         </div>
        //     ),
        //     ignoreRowClick: true,
        //     allowOverflow: true,
        //     button: true,
        //     center: true,
        //     width: '200px',
        //     // flex:1,
        // },
        {
            name: 'Transcript',
            cell: row => (
                <>
                    <div style={{ width: '60%' }}>
                        <div onClick={() => handleShowModal(row)} style={{ width: '40px', backgroundColor: 'rgba(222, 228, 251, 0.5)', padding: 5, justifyContent: 'center', alignItems: 'center', display: 'flex', cursor: 'pointer', borderRadius: '5px', marginLeft: '10px' }}>
                            <RxChatBubble color='black' size={20} />
                            {console.log("CallerData:", row)}
                        </div>
                    </div>
                </>
            ),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
            center: true,
            // width: '200px',
            flex: 1,
        }
    ];

    const calculatedHeight = Math.max(350, series[0].data.length * 25);

    return (
        <Fragment>
            <Breadcrumbs mainTitle="Reports" />
            <Container fluid={true}>
                {isKeywordsAnalysisReport ? (
                    <Row>
                        <Col sm='6'>
                            <Card>
                                <CardBody style={{ padding: '10px' }}>
                                    <SkeletonTheme baseColor={theme == 'dark-only' ? "#202020" : ""} highlightColor={theme == 'dark-only' ? "#444" : ""}>
                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                            <Skeleton height={20} style={{ width: '150px' }} />
                                            <Skeleton height={20} style={{ width: '150px' }} />
                                        </div>
                                    </SkeletonTheme>
                                    <Row>
                                        <Col sm='12'>
                                            <SkeletonTheme baseColor={theme == 'dark-only' ? "#202020" : ""} highlightColor={theme == 'dark-only' ? "#444" : ""}>
                                                <Skeleton height={20} count={15} />
                                            </SkeletonTheme>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col sm='6'>
                            <Card>
                                <CardBody style={{ padding: '10px' }}>
                                    <SkeletonTheme baseColor={theme == 'dark-only' ? "#202020" : ""} highlightColor={theme == 'dark-only' ? "#444" : ""}>
                                        <Skeleton height={20} count={16} />
                                    </SkeletonTheme>
                                </CardBody>
                            </Card>
                        </Col>
                        <H4></H4>
                        <Col sm='12'>
                            <Card>
                                <CardBody style={{ padding: '10px' }}>
                                    <SkeletonTheme baseColor={theme == 'dark-only' ? "#202020" : ""} highlightColor={theme == 'dark-only' ? "#444" : ""}>
                                        <Skeleton height={20} count={10} />
                                    </SkeletonTheme>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                ) : (
                    <Fragment>
                        <Row>
                            <div className="table-responsive" ref={table1Ref}>
                                <Row>
                                    <Col sm='6'>
                                        <Card>
                                            <CardBody>
                                                <Row>
                                                    <Col md="6">
                                                        <H4>Keyword Summary</H4>
                                                    </Col>
                                                    <Col md="6">
                                                        <DateRangePicker
                                                            initialSettings={{ startDate: dateRange.startDate, endDate: dateRange.endDate }}
                                                            onApply={handleEventDate}
                                                            children={{}}
                                                        >
                                                            <input
                                                                type="text"
                                                                value={`${dateRange.startDate} - ${dateRange.endDate}`}
                                                                readOnly
                                                                className="form-control"
                                                            />
                                                        </DateRangePicker>
                                                    </Col>
                                                </Row>
                                                <br />
                                                <DataTable
                                                    className='reportsDT'
                                                    data={tableData}
                                                    columns={tableColumns}
                                                    striped={true}
                                                    center={true}
                                                    pagination
                                                    paginationPerPage={50}
                                                    clearSelectedRows={toggleDelet}
                                                    noDataComponent={
                                                        <div style={{ padding: '10px', color: theme === 'dark-only' ? Color.DARK_THE_GREY_TEXT : '#000', backgroundColor: theme === 'dark-only' ? '#262932' : '#fff', textAlign: 'center', width: '100%', border: 'none' }}>
                                                            There are no records to display
                                                        </div>
                                                    }
                                                />

                                            </CardBody>
                                        </Card>
                                    </Col>
                                    <Col sm='6'>
                                        <Card>
                                            <CardBody>
                                                <Row>
                                                    <Col md="8">
                                                        <H4>Frequency</H4>
                                                    </Col>
                                                    <Col md="12">
                                                        <div id='basic-bar'>
                                                            <Chart options={options} series={series} type="bar" height={calculatedHeight} />
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            </div>
                            <div className="table-responsive" ref={table2Ref}>
                                <Col sm='12'>
                                    <Card className="mt-4 mb-4" >
                                        <CardBody>

                                            <Row>
                                                <Col md="8">
                                                    <H4>Keyword Report ( {keyword} )</H4>
                                                </Col>
                                                <Col md="12">
                                                    <DataTable
                                                        className='reportsDT'
                                                        data={tableData2}
                                                        columns={tableColumns2}
                                                        striped={true}
                                                        center={true}
                                                        pagination
                                                        paginationPerPage={50}
                                                        noDataComponent={
                                                            <div style={{ padding: '10px', color: theme === 'dark-only' ? Color.DARK_THE_GREY_TEXT : '#000', backgroundColor: theme === 'dark-only' ? '#262932' : '#fff', textAlign: 'center', width: '100%', border: 'none' }}>
                                                                There are no records to display
                                                            </div>
                                                        }
                                                    />
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </div>
                        </Row>
                    </Fragment>
                )
                }
            </Container >
            <Modal
                show={modal}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                onHide={() => {
                    setIsPlaying(false)
                    setModal(false)
                }}
                className="custom-modal"
            >
                <Modal.Header closeButton>
                    <div style={{ display: 'flex' }}>
                        <div style={{ marginRight: '8px' }} className={`initials-circle ${getRandomColor()}`}>{getInitials(callerData?.customer_name == null ? 'Unknown' : callerData?.customer_name)}</div>
                        <div>
                            <p style={{ color: theme === 'dark-only' ? 'grey' : '#013165', fontSize: '14px', padding: '0px', lineHeight: 0.7, fontWeight: 'bold' }}>{callerData?.customer_name == null ? 'Unknown' : callerData?.customer_name}</p>
                            <p style={{ marginTop: '-10px', fontSize: '12px', padding: '0px', color: 'grey', lineHeight: 0.7, }}>{callerData?.customer_phone}</p>
                            <p style={{ marginTop: '-10px', fontSize: '12px', padding: '0px', color: 'grey', lineHeight: 0.7, }}>{formatCallDate(callerData?.call_date)}</p>
                        </div>
                    </div>
                    <button className={`play-pause-button ${isPlaying ? 'playing' : ''}`} onClick={handlePlayPause}>
                        {isPlaying ? (
                            <svg width="24" height="24" viewBox="0 0 24 24"><path d="M6 19h4V5H6v14zm8-14v14h4V5h-4z" /></svg>
                        ) : (
                            <svg width="24" height="24" viewBox="0 0 24 24"><path d="M8 5v14l11-7z" /></svg>
                        )}
                    </button>
                    <audio ref={audioRef} src={audio} onEnded={() => setIsPlaying(false)}>
                        Your browser does not support the audio element.
                    </audio>
                </Modal.Header>
                <Modal.Body>
                    <div className="chat-container" dangerouslySetInnerHTML={{ __html: modalContent }} />
                </Modal.Body>
            </Modal>
        </Fragment >
    );
};

export default Reports;