import React, { Fragment, useEffect, useRef, useState } from 'react';
import { Col, Container, Row, Card, CardBody, ModalHeader, ModalBody, ModalFooter, Button, Spinner } from "reactstrap";
import { Breadcrumbs, H5 } from "../../AbstractElements";
import DataTable from 'react-data-table-component';
import { H4 } from '../../AbstractElements';
import { getChildCallsStatusSummary, childCallsDetail, personWiseChildDetail } from '../../api/AuthIndex';
import { dummytabledata } from '../../Data/Table/Defaultdata';
import moment from 'moment';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';
import Chart from 'react-apexcharts';
import ReactApexChart from 'react-apexcharts';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../ManageAssistant/ManageAssistant.css'
import { Modal } from 'react-bootstrap';
import { formatMobileNumber } from '../../Constant/GlobalFunctions';
import { RxChatBubble, RxHamburgerMenu } from 'react-icons/rx'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { debounceTime } from '../../helpers/constants';
import { useDispatch, useSelector } from 'react-redux';
import { ActionTypes } from '../../Store/redux-store/ActionTypes';
import { selectCallLogCompany, selectCallLogCompanyLoading, selectChartData, selectTableData } from '../../Store/modules/callLog/callLogSelectors';
import {isEmpty} from "../../helpers/utils";
import { Color } from '../../helpers/colors';

const ChildCallReports = ({ mainClass }) => {
    const dispatch = useDispatch();
    const callLogData = useSelector(selectCallLogCompany);
    const tableData = useSelector(selectTableData);
    const chartData = useSelector(selectChartData);
    const isCallLogLoading = useSelector(selectCallLogCompanyLoading);

    const getCompanyInfo = JSON.parse(localStorage.getItem('companyData'));
    const theme = localStorage.getItem("mix_background_layout")
    const dateRangePickerStyle = theme === "dark-only" ? { backgroundColor: "red" } : {};

    // const startOfWeek = moment().startOf('week').format('MM/DD/YY');
    // const endOfWeek = moment().endOf('week').format('MM/DD/YY');
    const startOfWeek = moment().subtract(1, 'month').format('MM/DD/YYYY');
    const endOfWeek = moment().format('MM/DD/YYYY');
    const [dateRange, setDateRange] = useState({ startDate: startOfWeek, endDate: endOfWeek });
    const token = localStorage?.getItem('token');
    const [selectedRows, setSelectedRows] = useState([]);
    const [toggleDelet, setToggleDelet] = useState(false);
    const [data, setData] = useState(dummytabledata);
    // const [tableData, setTableData] = useState(tableDatatest ? tableDatatest : '');
    const [tableData2, setTableData2] = useState();
    const [modal, setModal] = useState(false);
    const [recordingModal, setRecordingModal] = useState(false);
    const [staffReportModal, setStaffReportModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [spinner, setSpinner] = useState(false);
    const [currentRow, setCurrentRow] = useState(null);
    const [transcript, setTranscript] = useState('');
    const [recordingUrl, setRecordingUrl] = useState('');
    // const [chartData, setChartData] = useState([]);
    const [personWiseTable, setPersonWiseTable] = useState();

    const isInitialRender = useRef(true);

    useEffect(() => {
        if (!isEmpty(startOfWeek)) {
            dispatch({ type: ActionTypes.FETCH_CALL_LOG_COMPANY, payload: { company_id: getCompanyInfo.id, start_date: moment(startOfWeek).format('YYYY-MM-DD'), end_date: moment(endOfWeek).format('YYYY-MM-DD') } });
        }
        // const delayDebounceFn = setTimeout(() => {
        //     if (startOfWeek) {
        //         handleEventDate()
        //     }
        // }, debounceTime);

        // return () => clearTimeout(delayDebounceFn);
    }, []);

    const handleEventDate = async (event, picker) => {
        setSpinner(true)
        try {
            setDateRange({
                startDate: picker ? picker?.startDate?.format('MM/DD/YY') : startOfWeek,
                endDate: picker ? picker?.endDate?.format('MM/DD/YY') : endOfWeek,
            });
            // const formData = new FormData();
            // formData.append("company_id", getCompanyInfo.id);
            // formData.append("start_date", picker ? picker?.startDate?.format('YYYY-MM-DD') : moment(startOfWeek).format('YYYY-MM-DD'));
            // formData.append("end_date", picker ? picker?.endDate?.format('YYYY-MM-DD') : moment(endOfWeek).format('YYYY-MM-DD'));
            // const res2 = await getChildCallsStatusSummary(token, formData);
            dispatch({ type: ActionTypes.FETCH_CALL_LOG_COMPANY, payload: { company_id: getCompanyInfo.id, start_date: picker ? picker?.startDate?.format('YYYY-MM-DD') : moment(startOfWeek).format('YYYY-MM-DD'), end_date: picker ? picker?.endDate?.format('YYYY-MM-DD') : moment(endOfWeek).format('YYYY-MM-DD') } });
            // const res2 = tableDatatest
            // const convertData = () => {
            //     const records = Object.values(res2.records).flat(2);
            //     return { records };
            // };
            // let res = convertData(res2)
            // console.log("🚀 ~ handleEventDate ~ res:", res)
            // if (res?.records?.length === 0) {
            //     toast.info("No data found", {
            //         position: "bottom-center",
            //         autoClose: 3000,
            //         hideProgressBar: true,
            //         closeOnClick: true,
            //         pauseOnHover: true,
            //         draggable: true,
            //         progress: undefined,
            //         theme: "light",
            //         className: 'custom-toast',
            //     })
            // }
            // const data1 = res?.records.map(item => item.keyword);
            // const data2 = res?.records.map(item => item.frequency);
            // const filteredRecords = res?.records.filter(record => record.status === "no-answer");
            // setTableData(filteredRecords);

            // // Extract unique departments
            // const departments = [...new Set(res?.records.map(record => record.department))];

            // // Generate chart data for each department
            // const data = departments.map(department => {
            //     const filteredRecords = res?.records.filter(record => record.department === department);

            //     const series = filteredRecords.map(record => parseFloat(record.this_status));
            //     const labels = filteredRecords.map(record => record.status);

            //     return {
            //         department,
            //         series,
            //         options: {
            //             chart: {
            //                 width: 200,
            //                 type: 'donut',
            //             },
            //             plotOptions: {
            //                 pie: {
            //                     donut: {
            //                         size: '50%' // Adjust the size of the inner hole as needed
            //                     }
            //                 }
            //             },
            //             labels: labels,
            //             responsive: [{
            //                 breakpoint: 280,
            //                 options: {
            //                     chart: {
            //                         width: 100
            //                     },
            //                     legend: {
            //                         position: 'bottom'
            //                     }
            //                 }
            //             }]
            //         }
            //     };
            // });
            // setChartData(data);
            setSpinner(false)
        } catch (error) {
            console.log("🚀 ~ handleEventDate ~ error:", error)
            setSpinner(false)
        }


    };

    const [keyword, setKeyword] = useState('')

    const table1Ref = useRef(null);
    const table2Ref = useRef(null);

    const toggleModal = async (row) => {
        const start = dateRange.startDate;
        const end = dateRange.endDate;
        const formattedDate1 = moment(start, 'MM/DD/YY').format('YYYY-MM-DD');
        const formattedDate2 = moment(end, 'MM/DD/YY').format('YYYY-MM-DD');
        const formData = new FormData();
        formData.append("company_id", getCompanyInfo.id);
        formData.append("start_date", formattedDate1);
        formData.append("end_date", formattedDate2);
        formData.append("department_id", row.department_id);
        const res = await childCallsDetail(token, formData);
        console.log("🚀 ~ toggleModal ~ res:", res)
        setKeyword(row.department)
        setTableData2(res?.records);
        setCurrentRow(row);

        if (table1Ref.current) {
            table1Ref.current.scrollIntoView({ behavior: 'smooth' });
        }
        if (table2Ref.current) {
            table2Ref.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const toggleTranscriptModal = () => {
        setModal(!modal);
    };

    const toggleRecordingModal = () => {
        setRecordingModal(!recordingModal);
    };

    const showTranscript = (transcript) => {
        setTranscript(transcript);
        toggleTranscriptModal();
    };

    const showRecording = (url) => {
        setRecordingUrl(url);
        toggleRecordingModal();
    };

    const formatPhoneNumber = (phoneNumber) => {
        const cleaned = ('' + phoneNumber).replace(/\D/g, '');
        const match = cleaned.match(/^(\d{1})(\d{3})(\d{3})(\d{4})$/);
        if (match) {
            return `+${match[1]} (${match[2]}) ${match[3]}-${match[4]}`;
        }
        return phoneNumber;
    };

    const tableColumns = [
        {
            name: 'Department',
            // selector: row => row['department'],
            sortable: true,
            center: false,
            // width: '160px',
            flex: 1,
            cell: row => {
                return (
                    <>
                        <p style={{ fontSize: '12px', lineHeight: 0.5, marginLeft: '17px' }}>{row.department}</p>
                    </>
                )
            },
        },
        {
            name: 'Missed Calls',
            // selector: row => row['average'],
            sortable: true,
            center: true,
            // width: '170px',
            flex: 1,
            cell: row => <p style={{marginLeft:'17px'}}>{row.average}%</p>,
        },
        {
            name: 'Action',
            // cell: row => <Button color="primary" onClick={() => toggleModal(row)}>View</Button>,
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
            center: true,
            // width: '150px',
            flex: 1,
            cell: row => (
                <>
                    <div style={{ width: '60%' }}>
                        <div onClick={() => toggleModal(row)} style={{ width: '40px', backgroundColor: '#f7f7f7', padding: 5, justifyContent: 'center', alignItems: 'center', display: 'flex', cursor: 'pointer', borderRadius: '5px' }}>
                            <RxHamburgerMenu color='black' size={20} />
                        </div>
                    </div>
                </>
            ),
        },
    ];

    const handlePersonWiseData = async (data) => {
        console.log("🚀 ~ handlePersonWiseData ~ data:", data)
        let departID = data?.department_id
        let staffID = data?.staff_id
        setLoading(true)
        setStaffReportModal(true)
        try {
            const formData = new FormData();
            formData.append("company_id", getCompanyInfo?.id);
            formData.append("start_date", moment().subtract(1, 'month').format('YYYY-MM-DD'));
            formData.append("end_date", moment().format('YYYY-MM-DD'));
            formData.append("staff_id", staffID);
            formData.append("department_id", departID);
            const res = await personWiseChildDetail(token, formData);
            console.log("🚀 ~ handlePersonWiseData ~ res:", res)
            setPersonWiseTable(res.records.map((item) => {
                console.log("🚀 ~ setPersonWiseTable ~ item:", item)
                return {
                    callDate: item.call_date,
                    phone: item.customer_number,
                    status: item.status,
                }
            }))
            if (res) {
                setLoading(false)
            }
        } catch (error) {
            console.log("🚀 ~ getDepartDetail ~ error:", error)
        }
    };

    const tableColumns2 = [
        {
            name: 'Call Date',
            // selector: row => moment(row['call_date']).format('DD-MM-YYYY'),
            sortable: true,
            center: false,
            width: '300px',
            cell: row => {
                console.log("🚀 ~ ChildCallReports ~ row:", row)
                const date = moment(row?.call_date).format('MM-DD-YYYY HH:MM a');
                // const formattedDate = moment(date, 'MMM-DD-YYYY HH:mm').format('MMMM Do, YYYY HH:mm');
                return (
                    <>
                        <p style={{ fontSize: '12px', lineHeight: 0.5, marginLeft: '10px', color: theme === 'dark-only' ? Color.DARK_THE_GREY_TEXT : Color.BLACK }}>{date}</p>
                    </>
                )
            },
        },
        {
            name: 'Transfer To',
            // selector: row => row['transferred_to'],
            sortable: true,
            center: true,
            width: '200px',
            cell: row => {
                return (
                    <div style={{ cursor: 'pointer' }} onClick={() => handlePersonWiseData(row)}>
                        <p style={{ textDecorationLine: 'underline' }}>{row.transferred_to}</p>
                    </div>
                )
            },
        },
        {
            name: 'Phone No.',
            selector: row => formatPhoneNumber(row['customer_number']),
            sortable: true,
            center: true,
            width: '200px',
        },
        {
            name: 'Status',
            selector: row => row['status'],
            sortable: true,
            center: true,
            width: '200px',
        }
    ];

    const tableColumns3 = [
        {
            name: 'Call Date',
            sortable: false,
            center: false,
            cell: row => {
                const formatCallDate = (dateString) => {
                    return moment(dateString).format('MM-DD-YYYY hh:mm a');
                };
                return (
                    <>
                        <p style={{ fontSize: '12px', lineHeight: 0.5 }}>{formatCallDate(row.callDate)}</p>
                    </>
                )
            },
        },
        {
            name: 'Customer Phone',
            // selector: row => row['transferred_to'],
            sortable: false,
            center: true,
            cell: row => {
                return (
                    <>
                        <p style={{ fontSize: '12px', lineHeight: 1 }}>{formatMobileNumber(row.phone)}</p>
                    </>
                )
            },
        },
        {
            name: 'Status',
            sortable: false,
            center: true,
            cell: row => {
                return (

                    <p style={{ lineHeight: 1, textTransform: 'capitalize', backgroundColor: row.status == 'no-answer' ? '#FF4560' : row.status == 'completed' ? '#0adb92' : row.status == 'busy' ? '#5979D4' : '', padding: '5px', borderRadius: '5px', fontSize: '12px', color: 'white', }}>{row.status}</p>

                )
            },
        },
    ];

    return (
        <Fragment>
            <Breadcrumbs mainTitle="Reports" />
            <Container fluid={true}>
                <Row>
                    {isCallLogLoading ? (
                        <Row>
                            <Col sm='6'>
                                <Card>
                                    <CardBody style={{ padding: '10px' }}>
                                        <SkeletonTheme baseColor={theme == 'dark-only' ? "#202020" : ""} highlightColor={theme == 'dark-only' ? "#444" : ""}>
                                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                                <Skeleton height={20} style={{ width: '150px' }} />
                                                <Skeleton height={20} style={{ width: '150px' }} />
                                            </div>
                                        </SkeletonTheme>
                                        <Row>
                                            <Col sm='12'>
                                                <SkeletonTheme baseColor={theme == 'dark-only' ? "#202020" : ""} highlightColor={theme == 'dark-only' ? "#444" : ""}>
                                                    <Skeleton height={20} count={15} />
                                                </SkeletonTheme>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col sm='6'>
                                <Card>
                                    <CardBody style={{ padding: '10px' }}>
                                        <SkeletonTheme baseColor={theme == 'dark-only' ? "#202020" : ""} highlightColor={theme == 'dark-only' ? "#444" : ""}>
                                            <Skeleton height={20} count={16} />
                                        </SkeletonTheme>
                                    </CardBody>
                                </Card>
                            </Col>
                            <H4></H4>
                            <Col sm='12'>
                                <Card>
                                    <CardBody style={{ padding: '10px' }}>
                                        <SkeletonTheme baseColor={theme == 'dark-only' ? "#202020" : ""} highlightColor={theme == 'dark-only' ? "#444" : ""}>
                                            <Skeleton height={20} count={10} />
                                        </SkeletonTheme>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    ) : (
                        <>
                            <div className="table-responsive" ref={table1Ref}>
                                <Row>
                                    <Col sm='6'>
                                        <Card>
                                            <CardBody>
                                                <Row>
                                                    <Col md="6">
                                                        <H4>Department Wise Summary</H4>
                                                    </Col>
                                                    <Col md="6">
                                                        <DateRangePicker
                                                            initialSettings={{ startDate: dateRange.startDate, endDate: dateRange.endDate }}
                                                            onApply={handleEventDate}
                                                        >
                                                            <input
                                                                type="text"
                                                                value={`${dateRange.startDate} - ${dateRange.endDate}`}
                                                                readOnly
                                                                className={`form-control`}
                                                            />
                                                        </DateRangePicker>
                                                    </Col>
                                                </Row>
                                                <br />
                                                <DataTable
                                                    className='reportsDT'
                                                    data={tableData}
                                                    columns={tableColumns}
                                                    striped={true}
                                                    center={true}
                                                    pagination
                                                    paginationPerPage={50}
                                                    clearSelectedRows={toggleDelet}
                                                    noDataComponent={
                                                        <div style={{ padding: '10px', color: theme === 'dark-only' ? Color.DARK_THE_GREY_TEXT : '#000', backgroundColor: theme === 'dark-only' ? '#262932' : '#fff', textAlign: 'center', width: '100%', border: 'none' }}>
                                                            There are no records to display
                                                        </div>
                                                    }
                                                />
                                            </CardBody>
                                        </Card>
                                    </Col>
                                    <Col sm='6'>
                                        <Card>
                                            <CardBody>
                                                <Row>
                                                    <Col md="8">
                                                        <H4>Frequency</H4>
                                                    </Col>
                                                    <Col md="12">
                                                        <Row>
                                                            {chartData.map((data, index) => {
                                                                console.log("🚀 ~ {chartData.map ~ data:", data)
                                                                return (
                                                                    <Col md="6">
                                                                        <div key={index}>
                                                                            <h5>{data.department}</h5>
                                                                            <div id={`chart-${index}`}>
                                                                                <ReactApexChart options={data.options} series={data.series} type="donut" width={270} />
                                                                            </div>
                                                                        </div>
                                                                    </Col>
                                                                )
                                                            }
                                                            )}
                                                        </Row>

                                                    </Col>
                                                </Row>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            </div>

                            <div className="table-responsive" ref={table2Ref}>
                                <Col sm='12'>
                                    <Card>
                                        <CardBody>
                                            <Row>
                                                <Col md="8">
                                                    <H4>Department Report ( {keyword} )</H4>
                                                </Col>
                                                <Col md="12">
                                                    <DataTable
                                                        className='reportsDT'
                                                        data={tableData2}
                                                        columns={tableColumns2}
                                                        striped={true}
                                                        center={true}
                                                        pagination
                                                        paginationPerPage={50}
                                                        noDataComponent={
                                                            <div style={{ padding: '10px', color: theme === 'dark-only' ? Color.DARK_THE_GREY_TEXT : '#000', backgroundColor: theme === 'dark-only' ? '#262932' : '#fff', textAlign: 'center', width: '100%', border: 'none' }}>
                                                                There are no records to display
                                                            </div>
                                                        }
                                                    />
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </div>
                        </>
                    )}
                </Row>
            </Container>
            <Modal
                show={staffReportModal}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                onHide={() => setStaffReportModal(false)}
            >
                <Modal.Header closeButton>
                    <H4>Department Staff Report</H4>
                </Modal.Header>
                <Modal.Body>
                    <div style={{ justifyContent: 'center' }}>
                        {loading ? (
                            <div style={{ display: 'flex', justifyContent: 'center', height: '400px', alignItems: 'center' }}>
                                <Spinner
                                    as="span"
                                    animation="grow"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                />
                            </div>
                        ) : (
                            <>
                                <div style={{ height: '450px', overflow: 'scroll' }}>
                                    <DataTable
                                        data={personWiseTable || []}
                                        columns={tableColumns3}
                                        striped={true}
                                        center={true}
                                        pagination
                                        paginationPerPage={5}
                                        noDataComponent={
                                            <div style={{ padding: '10px', color: theme === 'dark-only' ? '#fff' : '#000', backgroundColor: theme === 'dark-only' ? '#262932' : '#fff', textAlign: 'center', width: '100%', border: 'none' }}>
                                                There are no records to display
                                            </div>
                                        }
                                    />
                                </div>
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '20px' }}>
                                    <Button style={{ width: '150px', height: '43px' }} onClick={() => setStaffReportModal(false)}>Close</Button>
                                </div>
                            </>
                        )}
                    </div>
                </Modal.Body>
            </Modal>
        </Fragment>
    );
};

export default ChildCallReports;