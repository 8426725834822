import {selectCompleteCompany} from "../../../Store/modules/chat/companySelectors";
import {useDispatch, useSelector} from 'react-redux';
import {hasFeatureAccess} from "../../../helpers/PackagesHelper";
import {AiOutlinePlus} from "react-icons/ai";
import {AI_ASSISTANT_RIGHT_SIDEBAR, FEATURE} from "../../../helpers/constants";
import UpgradePopup from "../../../Components/UpgradePopup/UpgradePopup";
import {useEffect, useState} from "react";
import {Popover, PopoverBody,} from 'reactstrap';
import {AssistantTile} from "./AssistantTile";
import {ActionTypes} from "../../../Store/redux-store/ActionTypes";
import {selectSelectedAssistant} from "../../../Store/modules/assistant/assistantSelectors";
import {isEmpty} from "../../../helpers/utils";

export const AssistantList = () => {
    const [showUpgradePopup, setShowUpgradePopup] = useState(false);
    const [popoverOpen, setPopoverOpen] = useState(false);
    const selectedAssistant = useSelector(selectSelectedAssistant);
    const dispatch = useDispatch();

    const completeCompany = useSelector(selectCompleteCompany);
    const assistantants = completeCompany?.company?.company?.assistant;

    const canCreateAssistants = hasFeatureAccess({
        featureName: FEATURE.ASSISTANTS,
        packageType: completeCompany?.company?.activeSubscription,
        existingQuantity: completeCompany?.company?.company?.assistants
    });

    useEffect(() => {
        if (isEmpty(selectedAssistant) && !isEmpty(assistantants)) {
            dispatch({type: ActionTypes.SET_SELECTED_ASSISTANT, payload: {assistant: assistantants?.[0]}});
            dispatch({
                type: ActionTypes.SET_ASSISTANT_RIGHT_SIDEBAR,
                payload: {sidebar: AI_ASSISTANT_RIGHT_SIDEBAR.ASSISTANT_DETAIL}
            });
        }
    }, [assistantants]);

    const handleMouseEnter = (target) => {
        setPopoverOpen(true);
    };

    const handleMouseLeave = (target) => {
        setPopoverOpen(false);
    }

    const openCreateAssistant = () => {
        dispatch({
            type: ActionTypes.SET_ASSISTANT_RIGHT_SIDEBAR,
            payload: {sidebar: AI_ASSISTANT_RIGHT_SIDEBAR.CREATE_ASSISTANT}
        });
    };

    const UpgradeRequiredPopup = () => (
        <UpgradePopup
            isOpen={showUpgradePopup}
            toggler={setShowUpgradePopup}
            title="Upgrade Required"
            description="To unlock additional assistants, please upgrade your package for enhanced support and functionality."
        />
    )

    return (
        <>
            <div style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '20px'
            }}>
                <h4 style={{
                    marginBottom: '0px',
                    textAlign: 'left',
                    color: '#013165',
                    marginTop: '5px'
                }}>AI Assistants</h4>
                <div>
                    <div
                        id="addAssistantButton"
                        style={{
                            border: '1px solid #EBEBEB',
                            width: '35px',
                            height: '35px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            borderRadius: '50px',
                            backgroundColor: '#F9F8F8',
                            cursor: 'pointer',
                        }}
                        onClick={() => {
                            if (!canCreateAssistants) {
                                setShowUpgradePopup(true);
                                return;
                            }
                            openCreateAssistant();
                        }}
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                    >
                        <AiOutlinePlus/>
                    </div>

                    <Popover
                        placement="top"
                        isOpen={popoverOpen}
                        target="addAssistantButton"
                    >
                        <PopoverBody>Add New AI Assistant</PopoverBody>
                    </Popover>
                </div>
            </div>

            {assistantants?.map((assistant, i) => {
                return (
                    <AssistantTile assistant={assistant} key={i++}/>
                );
            })}

            <UpgradeRequiredPopup/>
        </>
    );
}