import Table from 'react-bootstrap/Table';
import { CrossIcon, TickIcon } from "../../fluten_assets/icons";
import button from "../../CommonElements/Button";
import './Packages.css'
import { Fragment, useEffect, useState } from "react";
import { UpgradeConfirm } from "../../Components/UpgradeConfirm/UpgradeConfirm";
import { getCurrentCompany, getLocalStorage } from "../../helpers/LocalStorageHelper";
import { ActionTypes } from "../../Store/redux-store/ActionTypes";
import { PACKAGE_TYPE } from "../../helpers/constants";
import { selectCompleteCompany } from "../../Store/modules/company/companySelectors";
import { useDispatch, useSelector } from 'react-redux';
import { packageType, selectUpgradingPackageLoading } from "../../Store/modules/packages/packageSelectors";
import { generateBotPersona, generateOTP } from '../../api/AuthIndex';
import { Color } from "../../helpers/colors";
import { selectIsStripeSessionLoading } from "../../Store/modules/stripe/customerPortal/stripeSessionSelectors";

const features = [
    {
        name: 'Price',
        payg: '-',
        startup: '$299',
        pro: '$599',
    },
    {
        name: 'Calls per month',
        payg: '-',
        startup: '350',
        pro: '650',
    },
    {
        name: 'Price per call unit',
        payg: '$1',
        startup: '$1',
        pro: '$1',
    },
    {
        name: 'Assistants',
        payg: '1- Inbound only',
        startup: '2- Inbound only',
        pro: '5- Inbound only',
    },
    {
        name: 'Auto-charge every month + Yearly',
        payg: '-',
        startup: <TickIcon />,
        pro: <TickIcon />,
    },
    {
        name: 'Keyword analysis report',
        payg: '-',
        startup: <TickIcon />,
        pro: <TickIcon />,
    },
    {
        name: 'Calls Log',
        payg: <TickIcon />,
        startup: <TickIcon />,
        pro: <TickIcon />,
    },
    {
        name: 'Calls transfer to other numbers',
        payg: '5 Numbers',
        startup: 'Unlimited',
        pro: 'Unlimited',
    },
    {
        name: 'Fall-back number',
        payg: '1 Numbers',
        startup: '1 for each bot',
        pro: '1 for each bot',
    },
    {
        name: 'Weekly email about bot performance',
        payg: <TickIcon />,
        startup: <TickIcon />,
        pro: <TickIcon />,
    },
    {
        name: 'Sentiment analysis report',
        payg: <CrossIcon />,
        startup: <TickIcon />,
        pro: <TickIcon />,
    },
    {
        name: 'Additional calls cost',
        payg: '-',
        startup: '0.99',
        pro: '0.87',
    },
    {
        name: 'Best Suited for',
        payg: 'Bakery, Small clinics, gym',
        startup: 'Realestate, dealerships, Small hospitals, retail stores',
        pro: 'Large dealerships, hospitals, restaurants, hotels',
    },
    {
        name: 'Calls Recording & Transcripts',
        payg: '30 days',
        startup: '1 year',
        pro: '1 year',
    },
    {
        name: 'Departmental Calls Report (Call Transfer)',
        payg: <CrossIcon />,
        startup: <TickIcon />,
        pro: <TickIcon />,
    },
    {
        name: 'Phone number(s) price',
        payg: '$2.75',
        startup: 'Free',
        pro: 'Free',
    },
    {
        name: 'Phone number(s) price',
        payg: <TickIcon />,
        startup: <TickIcon />,
        pro: <TickIcon />,
    },

];

const UpgradeBtn = (props) => {
    const { onClick, disabled, title } = props;
    return (
        <button onClick={onClick} disabled={disabled} style={{ backgroundColor: disabled ? Color.GREY : '' }} className={'upgrade-btn'}>
            {title}
        </button>
    );
}

export const Packages = () => {
    const token = getLocalStorage('tokken')
    const [startUpPopup, setStartupPopup] = useState(false);
    const [proPopup, setProPopup] = useState(false);
    const [otp, setOtp] = useState("");
    const dispatch = useDispatch();
    const companyData = getCurrentCompany();
    const completeCompany = useSelector(selectCompleteCompany);
    const upgradingPackageLoading = useSelector(selectIsStripeSessionLoading);
    const upgradingPackageType = useSelector(packageType);

    const currentPackage = completeCompany?.company?.activeSubscription;

    const upgradePlan = (plan) => {
        dispatch({ type: ActionTypes.UPGRADE_PACKAGE, payload: { package_type: plan, company_id: companyData?.id, otp: otp } });
        setStartupPopup(false);
        setProPopup(false);
        setOtp('')
    }

    const handleGenerate = async () => {
        const res = await generateOTP(token)
    }

    const startupText = 'Get ready to boost your journey with our Startup Plan for just $350 With your card already saved, it\'s super easy. Confirm your payment below and let’s get started!';
    const proText = 'You\'re one step away from unlocking the PRO Plan for just $650 Your saved card makes it quick and easy. Confirm your payment below to elevate your experience!';

    const StyledTd = (props) => {
        const { children, packType } = props;

        return <td
            {...props}
            style={{
                backgroundColor: currentPackage === packType ? '#ececec' : '',
                border: currentPackage === packType ? '2px solid #ececec' : '',
                fontWeight: currentPackage === packType ? 'bold' : '',
                width: '25%',
            }}
        >{children}</td>
    }

    const StyledTh = (props) => {
        const { children, packType } = props;

        return (
            <th
                {...props}
                style={{
                    backgroundColor: currentPackage === packType ? Color.PRIMARY : '',
                    // border: currentPackage === packType ? `2px solid #4A7DFF` : '',
                    color: currentPackage === packType ? 'white' : '',
                    fontWeight: currentPackage === packType ? 'bold' : '',
                    padding: '0px',
                }}
            >
                <p style={{ color: currentPackage === packType ? 'white' : '', marginLeft: '10px', marginTop: '10px', marginBottom: '0px' }}>
                    {children}
                </p>
                {currentPackage === packType && (
                    <div
                        style={{
                            backgroundColor: '#FFD56B',
                            marginTop: '5px',
                            padding: '5px 0',
                            fontWeight: 'bold',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}
                    >
                        <p style={{ color: 'black', margin: 0 }}>Selected</p>
                    </div>
                )}
            </th>
        );
    };


    const getStripeSession = () => {
        dispatch({ type: ActionTypes.GET_STRIPE_SESSION });
    }


    // const StyledTh = (props) => {
    //     const { children, packType } = props;

    //     return <th
    //         {...props}
    //         style={{
    //             backgroundColor: currentPackage === packType ? Color.PRIMARY : '',
    //             border: currentPackage === packType ? `2px solid ${Color.PRIMARY}` : '',
    //             color: currentPackage === packType ? Color.WHITE : '',
    //             fontWeight: currentPackage === packType ? 'bold' : ''
    //         }}
    //     >
    //         {children}
    //         {currentPackage === packType && (
    //             <div style={{ backgroundColor: 'red' }}>
    //                 <p style={{ color: Color.LIGHT_GREEN, alignSelf: 'center' }}>Selected</p>
    //             </div>
    //         )}
    //     </th>
    // }

    return (
        <Fragment>
            <Table striped bordered hover style={{ tableLayout: 'fixed', width: '100%' }}>
                <thead>
                    <tr>
                        <th colSpan={2} rowSpan={2}>Features</th>
                        <StyledTh packType={PACKAGE_TYPE.PAY_AS_YOU_GO}>Pay as you go</StyledTh>
                        <StyledTh packType={PACKAGE_TYPE.STARTUP}>Start-up</StyledTh>
                        <StyledTh packType={PACKAGE_TYPE.PRO}>Pro</StyledTh>
                    </tr>
                </thead>
                <tbody>
                    {features?.map((feature, i) => {
                        return (
                            <tr key={i++}>
                                <StyledTd colSpan={2}>{feature?.name}</StyledTd>
                                <StyledTd colSpan={1} packType={PACKAGE_TYPE.PAY_AS_YOU_GO} >{feature?.payg}</StyledTd>
                                <StyledTd colSpan={1} packType={PACKAGE_TYPE.STARTUP}>{feature?.startup}</StyledTd>
                                <StyledTd colSpan={1} packType={PACKAGE_TYPE.PRO}>{feature?.pro}</StyledTd>
                            </tr>
                        );
                    })}

                    <tr>
                        <td colSpan={2}></td>
                        <StyledTd
                            packType={PACKAGE_TYPE.PAY_AS_YOU_GO}
                        >
                            {currentPackage === PACKAGE_TYPE.PAY_AS_YOU_GO ? (<span></span>) : (
                                <>
                                    {upgradingPackageLoading && upgradingPackageType === PACKAGE_TYPE.PAY_AS_YOU_GO ? <p>Upgrading...</p> : (<UpgradeBtn disabled={upgradingPackageLoading} key={'1'} title={currentPackage === 'strtup' || currentPackage === 'pro' ? "Downgrade" : "Upgrade"} onClick={() => {
                                        // setStartupPopup(true);
                                        getStripeSession();
                                    }} />)}
                                </>
                            )}
                        </StyledTd>
                        <StyledTd
                            packType={PACKAGE_TYPE.STARTUP}
                        >
                            {currentPackage === PACKAGE_TYPE.STARTUP ? (<span></span>) : (
                                <>
                                    {upgradingPackageLoading && upgradingPackageType === PACKAGE_TYPE.STARTUP ? <p>Upgrading...</p> : (<UpgradeBtn disabled={upgradingPackageLoading} key={'2'} title={currentPackage === 'pro' ? "Downgrade" : "Upgrade"} onClick={() => {
                                        // setStartupPopup(true);
                                        getStripeSession();
                                    }} />)}
                                </>
                            )}
                        </StyledTd>
                        <StyledTd
                            packType={PACKAGE_TYPE.PRO}
                        >
                            {
                                currentPackage === PACKAGE_TYPE.PRO ? (<span> </span>) :
                                    (<>
                                        {upgradingPackageLoading && upgradingPackageType === PACKAGE_TYPE.PRO ? <p>Upgrading...</p> : <UpgradeBtn disabled={upgradingPackageLoading} key={'3'} title={"Upgrade"} onClick={() => {
                                            // setProPopup(true)
                                            getStripeSession();
                                        }} />
                                        }
                                    </>
                                    )
                            }
                        </StyledTd>
                    </tr>
                </tbody>
            </Table>


            <UpgradeConfirm
                key={'223ss'}
                title={'Startup Upgrade'}
                description={startupText}
                isOpen={startUpPopup}
                otp={otp}
                setOtp={setOtp}
                toggler={setStartupPopup}
                onGenerate={handleGenerate}
                onCancel={() => { setStartupPopup(false) }}
                onConfirm={() => { upgradePlan(PACKAGE_TYPE.STARTUP) }}
            />
            <UpgradeConfirm
                key={'221'}
                title={'Pro Upgrade'}
                description={proText}
                isOpen={proPopup}
                otp={otp}
                setOtp={setOtp}
                toggler={setProPopup}
                onGenerate={handleGenerate}
                onCancel={() => { setProPopup(false) }}
                onConfirm={() => { upgradePlan(PACKAGE_TYPE.PRO) }}
            />
        </Fragment>
    );
}

