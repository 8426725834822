import React from 'react'
import { useSelector } from 'react-redux';
import { formatPhoneNumber } from '../../Constant/GlobalFunctions'
import { selectCompleteCompany } from '../../Store/modules/company/companySelectors';
import profileImage from '../../assets/images/user/bot.png';
import botImageMale from '../../assets/images/user/botImageMale.jpeg';
import botImageFemale from '../../assets/images/user/botImageFemale.jpeg';
import { Color } from '../../helpers/colors';

const AssistantTiles = () => {
    const completeCompany = useSelector(selectCompleteCompany);
    console.log("🚀 ~ NotificationSlider ~ completeCompany:", completeCompany)
    const theme = localStorage.getItem("mix_background_layout");
    return (
        <div style={{ marginLeft: '30px', display: 'flex', alignItems: 'center', justifyContent: 'flex-end', width: '100%', }}>
            {completeCompany?.company?.company?.assistant.map((item) => {
                return (
                    <>
                        <div className="cardBox">
                            <div style={{ backgroundColor: '#f39c12', height: '60px', width: '60px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <img
                                    src={item?.picture ? item?.picture : item.gender === 'M' ? botImageMale : botImageFemale}
                                    alt="Avatar"
                                    style={{ height: '60px', width: '60px', marginTop: '0px' }}
                                />
                            </div>
                            <div className="details">
                                <p className="name" style={{color:theme === 'dark-only' ? Color.DARK_THE_GREY_TEXT : 'black'}}>
                                    {item?.bot_name} <span className="language" style={{color:theme === 'dark-only' ? Color.DARK_THE_GREY_TEXT : 'black'}}>{item?.language == 'en-US' ? '(English)' : item?.language == 'en-AU' ? '(English)' : item?.language == 'en-GB' ? '(English)' : item?.language == 'en-IN' ? '(English)' : item?.language == 'fr' ? '(French)' : item?.language == 'es' ? '(Spanish)' : item?.language == 'de' ? '(German)' : item?.language == 'zh-CN' ? '(Chinese)' : ''}</span>
                                </p>
                                <p className="phone">{formatPhoneNumber(item?.phone_number)}</p>
                            </div>
                        </div>
                    </>
                )
            })}
        </div>
    )
}

export default AssistantTiles