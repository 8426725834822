import '../../component2/ManageAssistant/ManageAssistant.css'
const theme = localStorage.getItem("mix_background_layout")
const getCompanyInfo = JSON.parse(localStorage.getItem('companyData'))
const companies = JSON.parse(localStorage.getItem("companies"))

export const MENUITEMS = [
  {
    menutitle: "Applications",
    menucontent: "Ready to use Apps",
    Items: [
      { path: `${process.env.PUBLIC_URL}/app/dashboard`, icon: "fa fa-database", title: "Dashboard", type: "link" },

      { path: `${process.env.PUBLIC_URL}/app/company-setup`, icon: 'fa fa-university', type: "link", title: "Company Setup", },
      { path: `${process.env.PUBLIC_URL}/app/phone-directory`, icon: "fa fa-pencil-square-o", title: "Phone Directory", type: "link" },
      { path: `${process.env.PUBLIC_URL}/app/ai-assistants`, icon: "fa fa-user-md", title: "AI Assistants", type: "link", tourId: 'dashboard-step4', class: 'assistantSteper' },
      { path: `${process.env.PUBLIC_URL}/app/customers`, icon: "fa fa-users", title: "Customers", type: "link" },
      { path: `${process.env.PUBLIC_URL}/app/knowledge-base`, icon: "fa fa-file-text", title: "Knowledge Base", tourId: 'dashboard-step5', type: "link" },

      // { path: `${process.env.PUBLIC_URL}/app/companies`, icon: "fa fa-university", title: "Com
      // panies/Branches", type: "link" },
      // { path: `${process.env.PUBLIC_URL}/app/package`, icon: "fa fa-th", title: "Packages", type: "link" },

      {
        title: "Schedules",
        icon: "fa fa-table",
        type: "sub",
        badge: "badge badge-light-secondary",
        url:`${process.env.PUBLIC_URL}/app/manage-schedule`,
        active: false,
        children: [
          { path: `${process.env.PUBLIC_URL}/app/calendar`, type: "link", title: "Calendar" },
          { path: `${process.env.PUBLIC_URL}/app/manage-schedule`, type: "link", title: "Manage Schedule" }
        ],
      },

      {
        title: "Reports",
        icon: "fa fa-calendar",
        type: "sub",
        badge: "badge badge-light-secondary",
        active: false,
        url:`${process.env.PUBLIC_URL}/app/all-calls`,
        children: [
          { path: `${process.env.PUBLIC_URL}/app/all-calls`, type: "link", title: "All Calls" },
          { path: `${process.env.PUBLIC_URL}/app/department-calls`, type: "link", title: "Department Calls" },
          { path: `${process.env.PUBLIC_URL}/app/keyword-analysis`, type: "link", title: "Keyword Analysis" },
          { path: `${process.env.PUBLIC_URL}/app/calls-analysis`, type: "link", title: "Calls Analysis" },
        ],
      },

      // { path: `${process.env.PUBLIC_URL}/app/settings`, icon: "fa fa-cog", title: "Settings", type: "link" },
      {
        path: `${process.env.PUBLIC_URL}/login`, // assuming the login page is at this path
        icon: "fa fa-sign-out",
        title: "Logout",
        type: "link",
        onClick: (e) => {
          e.preventDefault(); // prevent the default link behavior
          localStorage.removeItem("profileURL");
          localStorage.removeItem("tokken");
          localStorage.removeItem("token");
          localStorage.removeItem("auth0_profile");
          localStorage.removeItem("email");
          localStorage.removeItem("Name");
          localStorage.setItem("authenticated", false);
          localStorage.removeItem("companyData");
          localStorage.setItem("login", false);; // clear the local storage
          window.location.href = `${process.env.PUBLIC_URL}/login`; // navigate to the login page
        }
      }
    ]
  }
];



export const MENUITEMS2 = [
  {
    menutitle: "Applications",
    menucontent: "Ready to use Apps",
    Items: [
      { path: `${process.env.PUBLIC_URL}/app/companies`, icon: "fa fa-university", title: "Companies", type: "link" },
      {
        path: `${process.env.PUBLIC_URL}/login`, // assuming the login page is at this path
        icon: "fa fa-sign-out",
        title: "Logout",
        type: "link",
        onClick: (e) => {
          e.preventDefault(); // prevent the default link behavior
          localStorage.removeItem("profileURL");
          localStorage.removeItem("tokken");
          localStorage.removeItem("token");
          localStorage.removeItem("auth0_profile");
          localStorage.removeItem("email");
          localStorage.removeItem("Name");
          localStorage.setItem("authenticated", false);
          localStorage.removeItem("companyData");
          localStorage.setItem("login", false);; // clear the local storage
          window.location.href = `${process.env.PUBLIC_URL}/login`; // navigate to the login page
        }
      }
    ]
  }
];


