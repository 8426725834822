import React, { useRef, useEffect, useState, Fragment } from 'react';
import { Container, Col, Row, Popover, OverlayTrigger, Card, CardBody } from 'react-bootstrap';
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import './ScheduleDetail.css'; // Add custom styles in a CSS file
import { H4, H6 } from '../../../AbstractElements';
import { FaArrowLeft, FaRegUser } from 'react-icons/fa';
import { GrDocumentText } from "react-icons/gr";
import { FiPhone } from 'react-icons/fi';
import { Color } from '../../../helpers/colors';
import { AxiosInstance } from "../../../api/AxiosInstance";
import { Breadcrumbs, Btn } from "../../../AbstractElements";
import { Link } from 'react-router-dom';
import moment from 'moment';
import { IoTimeOutline } from 'react-icons/io5';
import { LuPhone } from "react-icons/lu";
import { GoClock } from 'react-icons/go';
import { MdAlternateEmail } from "react-icons/md";
import { getScheduleDataWithDate } from '../../../api/AuthIndex';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';


const ScheduleDetail = () => {
    const calendarComponentRef = useRef(null);

    let getCompanyInfo = JSON.parse(localStorage.getItem('companyData'))
    let token = localStorage.getItem('token')
    const theme = localStorage.getItem("mix_background_layout")

    const [calendarEvents, setCalendarEvents] = useState([])
    const [latestCalendarEvents, setLatestCalendarEvents] = useState([])

    const [eventDataWithCount, setEventDataWithCount] = useState([])
    const [singleDateEvents, setSingleDateEvents] = useState([])

    const [date, setDate] = useState()

    const [loading, setloading] = useState(false)
    const [upComing, setUpComing] = useState(false)
    useEffect(() => {
        getSchaduleData();
        getSchaduleDataWithCount()
    }, [])
    const getSchaduleData = async (code) => {
        const headers = AxiosInstance.defaults.headers;
        headers['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
        const response = await AxiosInstance.get(`/company/bookings?company_id=${getCompanyInfo.id}&show_prev=Y`);
        const result = response?.data?.data?.map(item => ({
            title: item.name,
            start: item.start_time.split(" ")[0], // Extract the date part
            end: item.end_time,
            extendedProps: {
                name: item.name,
                phone: item.phone_number,
                email: item.email,
                start: item.start_time,
                end: item.end_time,
            }
        }));
        const today = new Date().toISOString().split("T")[0];
        const upcomingEvents = response?.data?.data?.filter(item => item.start_time.split(" ")[0] >= today)
            .map(item => ({
                title: item.name,
                start: item.start_time, // Extract the date part
                end: item.end_time,
                extendedProps: {
                    name: item.name,
                    phone: item.phone_number,
                    email: item.email,
                    start: item.start_time,
                    end: item.end_time,
                }
            }));
        setCalendarEvents(result);
        setLatestCalendarEvents(upcomingEvents)
    }

    const getSchaduleDataWithCount = async () => {
        setloading(true)
        try {
            const headers = AxiosInstance.defaults.headers;
            headers['Authorization'] = `Bearer ${token}`;
            const response = await AxiosInstance.post(`/company/bookingsWithCount?company_id=${getCompanyInfo.id}`);

            const today = new Date().toISOString().split("T")[0];
            const upcomingEvents = response?.data?.data?.map(item => ({
                title: item.tot_bookings,
                start: item.booking_date,
            }));
            setEventDataWithCount(upcomingEvents)
            setloading(false)
        } catch (error) {
            console.log("🚀 ~ getSchaduleDataWithCount ~ error:", error)
            setloading(false)
        }
    }

    const handleSingleEvent = (data) => {
        let date = moment(data).format('YYYY-MM-DD')
        scheduleWithCount(date)
    }

    const scheduleWithCount = async (date) => {
        setloading(true)
        try {
            const formData = new FormData()
            formData.append('company_id', getCompanyInfo.id)
            formData.append('date', moment(date).format("YYYY-MM-DD"))
            const res = await getScheduleDataWithDate(token, formData)
            setSingleDateEvents(res.data)
            if (res.data) {
                setUpComing(true)
            }
            setDate(moment(date).format('MMM Do'))
            setloading(false)
        } catch (error) {
            console.log("🚀 ~ scheduleWithCount ~ error:", error)
            setloading(false)
        }

    }

    const renderEventContent = (eventInfo) => {
        const { title, start, extendedProps } = eventInfo.event;
        const { name, phone, email, end } = extendedProps;

        const dateStr = start;
        const dateEnd = moment(end)
        const date = moment(dateStr);

        const formattedDate = date.format("MMM Do");
        const formattedStartTime = date.format("hh:mma");
        const formattedEndTime = dateEnd.format("hh:mma");

        const isPastDate = date.isBefore(moment(), "day");

        const currentTime = moment();


        const popover = (
            <Popover id="popover-basic">
                {/* <Popover.Header as="h3">{title}</Popover.Header> */}
                <Popover.Body>
                    <div style={{ display: 'flex' }}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <FaRegUser size={18} color={Color.PRIMARY} />
                            <p style={{ marginBottom: '0px', marginLeft: '5px' }}>{name}</p>
                        </div>
                        <div style={{ display: 'flex', marginLeft: '25px', alignItems: 'center' }}>
                            <GrDocumentText size={18} color={Color.PRIMARY} />
                            <p style={{ marginBottom: '0px', marginLeft: '5px' }}>{email}</p>
                        </div>
                    </div>
                    <div style={{ display: 'flex' }}>
                        <div style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
                            <FiPhone size={18} color={Color.PRIMARY} />
                            <p style={{ marginBottom: '0px', marginLeft: '5px' }}>{phone}</p>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center', marginTop: '10px', marginLeft: '25px' }}>
                            <IoTimeOutline size={18} color={Color.PRIMARY} />
                            <p style={{ marginBottom: '0px', marginLeft: '5px' }}>{formattedStartTime} - {formattedEndTime}</p>
                        </div>
                    </div>
                </Popover.Body>
            </Popover>
        );

        return (
            // <OverlayTrigger
            //     trigger={["hover", "focus"]}
            //     placement="bottom"
            //     overlay={popover}
            //     delay={{ show: 200, hide: 200 }} // Add a slight delay for better UX
            // >
            <div className={isPastDate ? "event-content2" : "event-content"}
                onClick={() => handleSingleEvent(moment(dateStr).format("YYYY-MM-DD"))} style={{ cursor: 'pointer' }} >
                <p style={{ marginTop: '-2px' }}>{title > 1 ? `${title} Bookings` : `${title} Booking`}</p>
            </div>
            // {/* </OverlayTrigger> */}
        );
    };

    const formatPhoneNumber = (phoneNumber) => {
        const cleaned = ('' + phoneNumber).replace(/\D/g, '');
        const match = cleaned.match(/^(\d{1})(\d{3})(\d{3})(\d{4})$/);
        if (match) {
            return `+${match[1]} (${match[2]}) ${match[3]}-${match[4]}`;
        }
        return phoneNumber;
    };

    const handleTodayHighlight = () => {
        const calendarApi = calendarComponentRef.current.getApi();
        const todayDate = calendarApi.getDate(); // Get today's date from the calendar
        const todayString = todayDate.toISOString().split("T")[0]; // Format to YYYY-MM-DD

        // Find the cell for today and add the class
        const todayCell = document.querySelector(`[data-date='${todayString}']`);
        if (todayCell) {
            todayCell.classList.add("highlight-today");
        }
    };

    return (
        <Fragment>
            <Breadcrumbs mainTitle="Calendar" />
            <Container fluid={true} className="rubik-font">
                <Row>
                    <Col md="12">
                        <Card>
                            <CardBody className="custTableComp">
                                <Row>
                                    <Col sm="12" >
                                        <Link to={"../app/manage-schedule"}
                                            className='btn btn-primary'
                                            style={{
                                                borderColor: Color.PRIMARY,
                                                color: 'white',
                                                float: "right",
                                                marginTop: "0px",
                                                marginRight: "0px",
                                                marginBottom: "20px"
                                            }} >
                                            Manage Schedule
                                        </Link>
                                    </Col>
                                    <Col sm="3" className={theme === 'dark-only' ? "sidebar3" : "sidebar2"}>
                                        {loading ?
                                            <Col sm='12' md='12'>
                                                <Card>
                                                    <CardBody style={{ padding: '10px' }}>
                                                        <SkeletonTheme baseColor={theme == 'dark-only' ? "#202020" : ""} highlightColor={theme == 'dark-only' ? "#444" : ""}>
                                                            <div style={{ marginTop: '10px' }}>
                                                                <Skeleton height={18} count={5} />
                                                            </div>
                                                            <div style={{ marginTop: '10px' }}>
                                                                <Skeleton height={18} count={5} />
                                                            </div>
                                                            <div style={{ marginTop: '10px' }}>
                                                                <Skeleton height={18} count={5} />
                                                            </div>
                                                            <div style={{ marginTop: '10px' }}>
                                                                <Skeleton height={18} count={5} />
                                                            </div>
                                                            <div style={{ marginTop: '10px' }}>
                                                                <Skeleton height={18} count={5} />
                                                            </div>
                                                        </SkeletonTheme>
                                                    </CardBody>
                                                </Card>
                                            </Col>
                                            :
                                            <>
                                                {upComing ?
                                                    <>
                                                        <div style={{ display: 'flex', marginTop: '-10px' }}>
                                                            <FaArrowLeft style={{ marginRight: '5px', marginTop: '2px', cursor: 'pointer', color: theme === 'dark-only' ? Color.DARK_THE_GREY_TEXT : Color.BLACK }} onClick={() => setUpComing(false)} />
                                                            <H6>Meetings on {date}</H6>
                                                        </div>
                                                        <div style={{ height: '580px', overflowY: 'scroll', marginTop: '20px', scrollbarWidth: 'thin', msOverflowStyle: 'auto' }}>
                                                            {singleDateEvents?.map((event, index) => {

                                                                const dateStr = event.start_time;
                                                                const dateEnd = moment(event.end_time)
                                                                const date = moment(dateStr);

                                                                const formattedDate = date.format("MMM Do");
                                                                const formattedStartTime = date.format("hh:mma");
                                                                const formattedEndTime = dateEnd.format("hh:mma");

                                                                const isPastEvent = dateEnd.isBefore(moment(), 'day') || (dateEnd.isSame(moment(), 'day') && moment().isAfter(dateEnd));
                                                                const borderLeftColor = isPastEvent ? '5px solid lightgrey' : '5px solid #5679D5';

                                                                return (
                                                                    <div key={index} className={theme === 'dark-only' ? "event-card2" : "event-card"} style={{ borderLeft: borderLeftColor }}>
                                                                        <div className={theme === 'dark-only' ? "date-container2" : "date-container"}>
                                                                            <div className={theme === 'dark-only' ? "day1" : "day"}>{formattedDate.split(" ")[0]}</div>
                                                                            <div className={theme === 'dark-only' ? "month1" : "month"}>{formattedDate.split(" ")[1]}</div>
                                                                        </div>
                                                                        <div
                                                                            style={{
                                                                                borderLeft: "1px solid #E9E9E9",
                                                                                height: "auto",
                                                                                margin: "0px 0px 0px 5px",
                                                                            }}
                                                                        ></div>
                                                                        <div className="details">
                                                                            <H6 attrH6={{ style: { color: Color.PRIMARY, fontSize: '14px' } }}>{event?.name}</H6>
                                                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                                <LuPhone color={Color.GREY} />
                                                                                <p style={{ marginBottom: '0px', fontSize: '12px', marginLeft: '5px', color: Color.GREY }}>
                                                                                    {formatPhoneNumber(event?.phone_number)}
                                                                                </p>
                                                                            </div>

                                                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                                <MdAlternateEmail color={Color.GREY} />
                                                                                <OverlayTrigger
                                                                                    placement="top"
                                                                                    overlay={
                                                                                        <Popover id={`popover-${index}`}>
                                                                                            <Popover.Body>{event?.email}</Popover.Body>
                                                                                        </Popover>
                                                                                    }
                                                                                >
                                                                                    <p
                                                                                        style={{
                                                                                            marginBottom: '0px',
                                                                                            fontSize: '12px',
                                                                                            marginLeft: '5px',
                                                                                            color: Color.GREY,
                                                                                            width: '120px',
                                                                                            overflow: 'hidden',
                                                                                            textOverflow: 'ellipsis',
                                                                                            display: 'inline-block',
                                                                                            whiteSpace: 'nowrap',
                                                                                            cursor: 'pointer',
                                                                                        }}
                                                                                    >
                                                                                        {event?.email}
                                                                                    </p>
                                                                                </OverlayTrigger>
                                                                            </div>

                                                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                                <GoClock color={Color.GREY} />
                                                                                <p style={{ marginBottom: '0px', fontSize: '12px', marginLeft: '5px', color: Color.GREY }}>
                                                                                    {formattedStartTime} - {formattedEndTime}
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                )
                                                            })}
                                                        </div>
                                                    </>
                                                    :
                                                    <div style={{ marginTop: '-15px' }}>
                                                        <H4>Upcoming Events</H4>
                                                        <div style={{ height: '580px', overflowY: 'scroll', marginTop: '20px', scrollbarWidth: 'thin', msOverflowStyle: 'auto' }}>
                                                            {latestCalendarEvents?.map((event, index) => {
                                                            console.log("🚀 ~ {latestCalendarEvents?.map ~ event:", event)

                                                                const dateStr = event.start;
                                                                const dateEnd = moment(event.end
                                                                    )
                                                                const date = moment(dateStr);

                                                                const formattedDate = date.format("MMM Do");
                                                                const formattedStartTime = date.format("hh:mma");
                                                                const formattedEndTime = dateEnd.format("hh:mma");

                                                                const isPastEvent = dateEnd.isBefore(moment(), 'day') || (dateEnd.isSame(moment(), 'day') && moment().isAfter(dateEnd));
                                                                const borderLeftColor = isPastEvent ? '5px solid lightgrey' : '5px solid #5679D5';

                                                                return (
                                                                    <div key={index} className={theme === 'dark-only' ? "event-card2" : "event-card"} style={{ borderLeft: borderLeftColor }}>
                                                                        <div className={theme === 'dark-only' ? "date-container2" : "date-container"}>
                                                                            <div className={theme === 'dark-only' ? "day1" : "day"}>{formattedDate.split(" ")[0]}</div>
                                                                            <div className={theme === 'dark-only' ? "month1" : "month"}>{formattedDate.split(" ")[1]}</div>
                                                                        </div>
                                                                        <div
                                                                            style={{
                                                                                borderLeft: "1px solid #E9E9E9",
                                                                                height: "auto",
                                                                                margin: "0px 0px 0px 5px",
                                                                            }}
                                                                        ></div>
                                                                        <div className="details">
                                                                            <H6 attrH6={{ style: { color: Color.PRIMARY, fontSize: '14px' } }}>{event?.title}</H6>
                                                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                                <LuPhone color={Color.GREY} />
                                                                                <p style={{ marginBottom: '0px', fontSize: '12px', marginLeft: '5px', color: Color.GREY }}>
                                                                                    {formatPhoneNumber(event?.extendedProps?.phone)}
                                                                                </p>
                                                                            </div>

                                                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                                <MdAlternateEmail color={Color.GREY} />
                                                                                <OverlayTrigger
                                                                                    placement="top"
                                                                                    overlay={
                                                                                        <Popover id={`popover-${index}`}>
                                                                                            <Popover.Body>{event?.extendedProps?.email}</Popover.Body>
                                                                                        </Popover>
                                                                                    }
                                                                                >
                                                                                    <p
                                                                                        style={{
                                                                                            marginBottom: '0px',
                                                                                            fontSize: '12px',
                                                                                            marginLeft: '5px',
                                                                                            color: Color.GREY,
                                                                                            width: '120px',
                                                                                            overflow: 'hidden',
                                                                                            textOverflow: 'ellipsis',
                                                                                            display: 'inline-block',
                                                                                            whiteSpace: 'nowrap',
                                                                                            cursor: 'pointer',
                                                                                        }}
                                                                                    >
                                                                                        {event?.extendedProps?.email}
                                                                                    </p>
                                                                                </OverlayTrigger>
                                                                            </div>

                                                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                                <GoClock color={Color.GREY} />
                                                                                <p style={{ marginBottom: '0px', fontSize: '12px', marginLeft: '5px', color: Color.GREY }}>
                                                                                    {formattedStartTime} - {formattedEndTime}
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                )
                                                            })}
                                                        </div>
                                                    </div>
                                                }
                                            </>
                                        }

                                    </Col>

                                    {/* Calendar */}
                                    <Col sm="9">
                                        <FullCalendar
                                            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                                            initialView="dayGridMonth"
                                            ref={calendarComponentRef}
                                            events={eventDataWithCount}
                                            eventContent={renderEventContent}
                                            weekends={true}
                                        />
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>

                </Row>
            </Container>
            {/*<button onClick={onConnectAccount1}>  </button>*/}
        </Fragment>

    );
};

export default ScheduleDetail;
