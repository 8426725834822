import React, { Fragment, useState, useEffect, useContext } from "react";
import { Container, Row, Col, Card, CardBody, Button } from "reactstrap";
import { Breadcrumbs, Btn, H5, H4 } from "../../AbstractElements";
import { getAllCountries, createCompanyData, updateCompanyData, getCompanyDetails, getColorsList } from "../../api/Company"
import { Link, useNavigate } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import defaultlogo from '../../assets/images/defaultlogo.png'
import DataTable from "react-data-table-component";
import { formatMobileNumber } from "../../Constant/GlobalFunctions";
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

import { AiOutlinePlus } from "react-icons/ai";
import { GoQuestion } from "react-icons/go";
import { RiDeleteBin6Line } from "react-icons/ri";
import { FiEdit3 } from "react-icons/fi";
import CreateCompany from "./CreateCompany";
import EditCompany from "./EditCompany";
import ApiContext from "../../ApiContext";
import { OverlayTrigger, Popover, Tooltip } from "react-bootstrap";
import './CreateCompany.css'
import { Color } from "../../helpers/colors";
import { debounceTime } from "../../helpers/constants";

const ManageCompany = () => {

    const navigate = useNavigate();
    const { setCompaniesStatus } = useContext(ApiContext);
    const [getCompany, setCompany] = useState([]);
    const [proServ, setProServ] = useState([]);
    const [selectedCompnay, setSelectedCompnay] = useState(null);
    const [directoryData, setDirectoryData] = useState([]);
    const getTokken = localStorage.getItem('token')

    const [departColors, setDepartColors] = useState()
    const [loading, setLoading] = useState()
    const [editCompany, setEditCompany] = useState(false)
    const [newCompany, setNewCompany] = useState(false)
    const [selectedCompnayData, setSelectedCompnayData] = useState(true)
    const [createCompany, setCreateCompany] = useState(false)
    const [editCompanyData, setEditCompanyData] = useState()

    let getCompanyInfo = JSON.parse(localStorage.getItem('companyData'))
    const userData = JSON.parse(localStorage.getItem('auth0_profile'))
    console.log("🚀 ~ ManageCompany ~ userData:", userData)

    const theme = localStorage.getItem("mix_background_layout")
    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            getCompanyArray()
            getColors()
        }, debounceTime);
        return () => clearTimeout(delayDebounceFn);
    }, [])

    const getCompanyArray = async () => {
        setLoading(true)
        try {
            let res = await getCompanyDetails(getCompanyInfo?.id, getTokken)
            // localStorage.setItem("companies", JSON.stringify(res.company));

            setCompany(res.company)

            // setCompaniesStatus(res?.company?.company)
            const lines = res.company?.company?.products_and_services.split(/\r?\n/);
            setProServ(lines)
            setLoading(false)
        } catch (error) {
            console.log("🚀 ~ getCompanyArray ~ error:", error)
            setLoading(false)
        }
    }

    const getColors = async () => {
        try {
            let res = await getColorsList(getTokken)
            let colors = res?.colors?.map((item) => item.hex)
            setDepartColors(colors)
        } catch (error) {
            console.log("🚀 ~ getColors ~ error:", error)
        }
    }


    const colorMap = {};
    let colorIndex = 0;

    const getDepartmentColor = (department) => {
        const colors = departColors?.length > 0 ? departColors : ['#f28b82', '#fbbc04', '#fff475', '#ccff90', '#a7ffeb', '#cbf0f8', '#aecbfa', '#d7aefb', '#fdcfe8'];
        if (!colorMap[department]) {
            colorMap[department] = colors[colorIndex % colors?.length];
            colorIndex++;
        }
        return colorMap[department];
    };

    const getLuminance = (hex) => {
        const rgb = parseInt(hex.substring(1), 16);
        const r = (rgb >> 16) & 0xff;
        const g = (rgb >> 8) & 0xff;
        const b = (rgb >> 0) & 0xff;
        const luminance = 0.2126 * r + 0.7152 * g + 0.0722 * b;
        return luminance;
    };

    const getTextColor = (bgColor) => {
        return getLuminance(bgColor) > 160 ? 'darkgrey' : '#fff';
    };

    const handleEdit = (data) => {
        setEditCompany(true)
        setNewCompany(false)
        setSelectedCompnayData(false)
        setEditCompanyData(data)
    }

    const handleCancelButton = () => {
        setSelectedCompnay(true)
        setSelectedCompnayData(true)
        setEditCompany(false)
        setNewCompany(false)
    }


    const tableColumns = [
        {
            name: 'Caller',
            // selector: row => row['person'],
            sortable: false,
            center: false,
            cell: row => {
                return (
                    <div>
                        <p style={{ fontSize: '14px', lineHeight: 1, marginLeft: '10px' }}>{row.name}</p>
                    </div>
                )
            }
        },
        {
            name: 'Phone No.',
            sortable: false,
            center: false,
            cell: row => {
                return (
                    <div>
                        <p style={{ fontSize: '14px', lineHeight: 1 }}>{formatMobileNumber(row.phone)}</p>
                    </div>
                )
            }
        },
        {
            name: 'Department',
            // selector: row => row['depart'],
            sortable: false,
            center: false,
            cell: row => {
                const department = row?.depart || 'Unknown';
                const bgColor = getDepartmentColor(department);
                const textColor = getTextColor(bgColor);
                return (
                    <div style={{ backgroundColor: bgColor, width: '95%', padding: '5px', borderRadius: '5px', color: textColor }}>
                        <p style={{ fontSize: '14px', lineHeight: 1, textAlign: 'center', }}>{row.depart}</p>
                    </div>

                    // <div>
                    //     <p style={{ fontSize: '9px', lineHeight: 1 }}>{row.depart}</p>
                    // </div>
                )
            }
        },

    ];

    const popover = (
        <Popover id="popover-basic">
            <Popover.Body>
                Add new company or branch
            </Popover.Body>
        </Popover>
    );


    return (
        <Fragment>
            <Breadcrumbs parent="Forms" mainTitle="Manage Company" subParent="Company" title="Manage Company" />
            <Container fluid={true} className="rubik-font" style={{ padding: "14px" }}>
                <Row>
                    {loading ? (
                        <Row>
                            <Col sm='12'>
                                <Card>
                                    <CardBody style={{ padding: '10px' }}>
                                        <SkeletonTheme baseColor={theme == 'dark-only' ? "#202020" : ""} highlightColor={theme == 'dark-only' ? "#444" : ""}>
                                            <div style={{ display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'space-between' }}>
                                                <div>
                                                    <Skeleton height={50} style={{ width: '50px', borderRadius: '50px' }} />
                                                </div>
                                                <div style={{ display: 'flex' }}>
                                                    <Skeleton height={20} style={{ marginLeft: '10px', width: '20px', borderRadius: '50px' }} />
                                                    <Skeleton height={20} style={{ marginLeft: '10px', width: '20px', borderRadius: '50px' }} />
                                                </div>
                                            </div>
                                            <div style={{ marginTop: '10px' }}>
                                                <Skeleton height={10} count={6} style={{ borderRadius: '10px' }} />
                                            </div>
                                            <div style={{ marginTop: '10px' }}>
                                                <Skeleton height={10} count={15} style={{ borderRadius: '10px' }} />
                                            </div>
                                        </SkeletonTheme>
                                    </CardBody>
                                </Card>
                            </Col>
                            {/* <Col sm='5'>
                                <Card>
                                    <CardBody style={{ padding: '10px' }}>
                                        <SkeletonTheme baseColor={theme == 'dark-only' ? "#202020" : ""} highlightColor={theme == 'dark-only' ? "#444" : ""}>
                                            <Skeleton height={10} count={20} />
                                        </SkeletonTheme>
                                    </CardBody>
                                </Card>
                            </Col> */}
                        </Row>
                    ) : (
                        <>
                            {getCompanyInfo == null &&
                                <Col sm="12 txt-end">
                                    <Button onClick={() => {
                                        navigate(`${process.env.PUBLIC_URL}/app/setup-company`)
                                        setCreateCompany(true)
                                    }}>Create Company</Button>
                                </Col>
                            }

                            <Row>
                                {/* <Col sm="4">
                                    <Card style={{ height: '580px', overflow: 'scroll', padding: '0px' }}>
                                        <CardBody style={{ padding: '0px' }}>
                                            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                                <div
                                                    style={{
                                                        marginTop: '10px',
                                                        borderBottom: '1px solid lightgrey',
                                                        backgroundColor: '#f7f9fc',
                                                        cursor: 'pointer',
                                                        padding: '10px 20px',
                                                        borderRadius: '5px',
                                                        width: '100%'
                                                    }}
                                                    onClick={() => {
                                                        setSelectedCompnay(true)
                                                        setSelectedCompnayData(true)
                                                        setEditCompany(false)
                                                        setNewCompany(false)
                                                    }}
                                                >
                                                    <div style={{ display: 'flex', }}>
                                                        <div style={{ width: '18%', marginTop: '10px' }}>
                                                            <div className='social-img'>
                                                                {getCompany?.company?.upload_logo ?
                                                                    <img style={{ width: '35px', height: '35px', borderRadius: '50px', }} src={`${process.env.REACT_APP_IMAGE_URL}${getCompany?.company?.upload_logo}`} />
                                                                    :
                                                                    <img style={{ width: '35px', height: '35px', borderRadius: '50px', }} src={defaultlogo} />
                                                                }
                                                            </div>
                                                        </div>
                                                        <div style={{ marginLeft: '5px' }}>
                                                            <div style={{ display: 'flex', borderRadius: '10px', alignItems: 'center', width: '100%' }}>
                                                                <div style={{ display: 'flex', alignItems: 'center', lineHeight: 0.5, width: '100%', alignItems: 'center' }}>
                                                                    <div style={{ justifyContent: 'space-between', width: '100%' }} className='someMargin'>
                                                                        <h4 style={{ marginBottom: '3px', textAlign: 'left', fontSize: '16px', color: '#FF3464', marginTop: '10px', fontWeight: 'bold', cursor: 'pointer' }} >{getCompany?.company?.company_name}</h4>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <p style={{ fontSize: '14px', fontWeight: '300', textAlign: 'left', height: '35px', overflow: 'hidden', color: 'grey', marginTop: '5px', lineHeight: 1.2 }} >{getCompany?.company?.introduction}</p>
                                                            <p style={{ fontSize: '12px', textAlign: 'left', fontWeight: '500', textDecorationLine: 'underline', color: theme === 'dark-only' ? 'grey' : 'grey', cursor: 'pointer', marginTop: '-15px' }}
                                                            >More...</p>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Col> */}
                                {editCompany &&
                                    <Col lg="12" md="12" sm="12" >
                                        <Card>
                                            <CardBody>
                                                <EditCompany companyData={editCompanyData} cancelButton={handleCancelButton} />
                                            </CardBody>
                                        </Card>
                                    </Col>
                                }
                                {newCompany &&
                                    <Col lg="12" md="12" sm="12" >
                                        <Card>
                                            <CardBody>
                                                <CreateCompany />
                                            </CardBody>
                                        </Card>
                                    </Col>}
                                {selectedCompnayData &&
                                    <Col lg="12" md="12" sm="12" style={{ position: 'relative' }}>
                                        <div>
                                            <Card>
                                                <CardBody>
                                                    <Row>
                                                        <div>
                                                            <div style={{ display: 'flex', borderRadius: '10px', justifyContent: 'space-between', }}>
                                                                <div style={{ display: 'flex', position: 'relative' }}>
                                                                    {getCompany?.company?.upload_logo ?
                                                                        <img style={{
                                                                            width: "70px",
                                                                            height: '70px',
                                                                            backgroundColor: 'white',
                                                                            border: '1px solid #EBEBEB',
                                                                            borderRadius: '50px',
                                                                            position: 'absolute',
                                                                            top: '-66px',
                                                                            left: '10px',
                                                                            padding: '5px'
                                                                        }}
                                                                            src={`${process.env.REACT_APP_IMAGE_URL}${getCompany?.company?.upload_logo}`} />
                                                                        :
                                                                        <img
                                                                            style={{
                                                                                width: "70px",
                                                                                height: '70px',
                                                                                border: '1px solid #EBEBEB',
                                                                                borderRadius: '50px',
                                                                                position: 'absolute',
                                                                                top: '-66px',
                                                                                left: '10px',
                                                                                padding: '5px'
                                                                            }} src={defaultlogo} />
                                                                    }
                                                                    <div style={{ marginLeft: '10px' }}>
                                                                        <H4
                                                                            attrH4={{
                                                                                style: {
                                                                                    marginBottom: '0px',
                                                                                    textAlign: 'left',
                                                                                    color: '#FF3464',
                                                                                    marginTop: '15px',
                                                                                }
                                                                            }}
                                                                        >
                                                                            {getCompany?.company?.company_name}
                                                                        </H4>
                                                                    </div>
                                                                </div>



                                                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                                    <div style={{ display: 'flex', width: '100px', justifyContent: 'end' }}>
                                                                        <div
                                                                            style={{
                                                                                borderRadius: '50%',
                                                                                padding: '10px',
                                                                                backgroundColor:theme === 'dark-only' ? '#262932' : 'white',
                                                                                display: 'flex',
                                                                                justifyContent: 'center',
                                                                                alignItems: 'center',
                                                                                border: '1px solid rgba(105, 120, 143, 0.3)',
                                                                                cursor: 'pointer'
                                                                            }}
                                                                            onClick={() => handleEdit(getCompany?.company)}
                                                                        >
                                                                            <FiEdit3
                                                                                size={20}
                                                                                color={theme === 'dark-only' ? 'grey' : '#69788F'}
                                                                            // onClick={() => handleEdit(getCompany?.company)}
                                                                            />
                                                                        </div>

                                                                        {/* <div
                                                                            style={{
                                                                                borderRadius: '50%',
                                                                                padding: '10px',
                                                                                backgroundColor: 'white',
                                                                                display: 'flex',
                                                                                justifyContent: 'center',
                                                                                alignItems: 'center',
                                                                                border: '1px solid rgba(105, 120, 143, 0.3)',
                                                                                cursor: 'pointer'
                                                                            }}
                                                                        // onClick={handleDelete} 
                                                                        >
                                                                            <RiDeleteBin6Line
                                                                                size={20}
                                                                                color={'#69788F'}
                                                                            />
                                                                        </div> */}
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                        <div class={theme === 'dark-only' ? "contact-info1" : "contact-info"} style={{ marginTop: '10px', marginBottom: '3%' }}>
                                                            <div class="info-item">
                                                                <h4
                                                                    style={{
                                                                        color: '#777',
                                                                        fontSize: '12px',
                                                                        marginBottom: '5px'
                                                                    }}
                                                                >Email Address</h4>
                                                                <p
                                                                    style={{
                                                                        color:theme === 'dark-only' ? Color.DARK_THE_GREY_TEXT : '#000', /* Black for main content */
                                                                        fontSize: '14px',
                                                                        fontWeight: 'bold'
                                                                    }}
                                                                >{userData?.email}</p>
                                                            </div>
                                                            <div class="info-item">
                                                                <h4
                                                                    style={{
                                                                        color: '#777',
                                                                        fontSize: '12px',
                                                                        marginBottom: '5px'
                                                                    }}
                                                                >Phone Number</h4>
                                                                {getCompany?.company?.official_phone ?
                                                                    <p
                                                                        style={{
                                                                            color:theme === 'dark-only' ? Color.DARK_THE_GREY_TEXT : '#000',
                                                                            fontSize: '14px',
                                                                            fontWeight: 'bold'
                                                                        }}
                                                                    >{formatMobileNumber(getCompany?.company?.official_phone)}</p>
                                                                    :
                                                                    <p
                                                                        style={{
                                                                            color: Color.PRIMARY,
                                                                            fontSize: '14px',
                                                                            fontWeight: '500',
                                                                            textDecorationLine: 'underline',
                                                                            cursor: 'pointer'
                                                                        }}
                                                                        onClick={() => handleEdit(getCompany?.company)}
                                                                    >
                                                                        Edit
                                                                    </p>
                                                                    // <FiEdit3
                                                                    //     size={20}
                                                                    //     color={theme === 'dark-only' ? 'grey' : '#69788F'}
                                                                    //     cursor='pointer'
                                                                    //     onClick={() => handleEdit(getCompany?.company)}
                                                                    // />
                                                                }
                                                            </div>
                                                            <div class="info-item">
                                                                <h4
                                                                    style={{
                                                                        color: '#777',
                                                                        fontSize: '12px',
                                                                        marginBottom: '5px'
                                                                    }}
                                                                >Website</h4>
                                                                {getCompany?.company?.website ?
                                                                    <a
                                                                        href={getCompany?.company?.website}
                                                                        target="_blank"
                                                                        rel="noopener noreferrer"
                                                                        style={{
                                                                            color:theme === 'dark-only' ? Color.DARK_THE_GREY_TEXT : '#000',
                                                                            fontSize: '14px',
                                                                            fontWeight: 'bold',
                                                                            width: '300px',
                                                                            whiteSpace: 'nowrap',
                                                                            overflow: 'hidden',
                                                                            textOverflow: 'ellipsis',
                                                                            display: 'inline-block',
                                                                            textDecorationLine: 'underline'
                                                                        }}
                                                                    >
                                                                        {getCompany?.company?.website}
                                                                    </a>
                                                                    :
                                                                    <p
                                                                        style={{
                                                                            color: Color.PRIMARY,
                                                                            fontSize: '14px',
                                                                            fontWeight: '500',
                                                                            textDecorationLine: 'underline',
                                                                            cursor: 'pointer'
                                                                        }}
                                                                        onClick={() => handleEdit(getCompany?.company)}
                                                                    >
                                                                        Edit
                                                                    </p>
                                                                }
                                                            </div>
                                                            <div class="info-item">
                                                                <h4
                                                                    style={{
                                                                        color: '#777',
                                                                        fontSize: '12px',
                                                                        marginBottom: '5px'
                                                                    }}
                                                                >Address</h4>
                                                                <p
                                                                    style={{
                                                                        color:theme === 'dark-only' ? Color.DARK_THE_GREY_TEXT : '#000', /* Black for main content */
                                                                        fontSize: '14px',
                                                                        fontWeight: 'bold'
                                                                    }}
                                                                >
                                                                    {getCompany?.company?.location ?
                                                                        <>
                                                                            {getCompany?.company?.location
                                                                                ?.split(/(?=\s\+\d{1,})/) // Split when phone number starts with '+' and keep the delimiter
                                                                                .map((section, index) => (
                                                                                    <React.Fragment key={index}>
                                                                                        {section.split(/\s(?=\S+@\S+\.\S+)/) // Further split when email format is detected
                                                                                            .map((part, subIndex) => (
                                                                                                <React.Fragment key={subIndex}>
                                                                                                    {part.trim()}
                                                                                                    <br />
                                                                                                </React.Fragment>
                                                                                            ))
                                                                                        }
                                                                                    </React.Fragment>
                                                                                ))
                                                                            }
                                                                        </>
                                                                        :
                                                                        <>
                                                                            {getCompany?.company?.address}
                                                                        </>
                                                                    }

                                                                </p>

                                                            </div>
                                                        </div>

                                                        <div style={{
                                                            padding: '10px 20px 20px 20px',
                                                            backgroundColor:theme === 'dark-only' ? '#262932' : '#f7f9fc',
                                                            borderRadius: '8px',
                                                            width: '100%',
                                                            maxWidth: '1200px',
                                                            margin: '0 auto',
                                                            border:theme === 'dark-only' ? '1px solid #a6a8b2' : ''
                                                            // boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
                                                        }}>
                                                            <Col md="12">
                                                                <br />
                                                                <h4 style={{ marginLeft: '-8px', color: theme === 'dark-only' ? 'grey' : 'black' }} className="cInfoH4">About</h4>
                                                                <p style={{ color: theme === 'dark-only' ? Color.DARK_THE_GREY_TEXT : '#868599' }} >
                                                                    {getCompany?.company?.introduction}
                                                                </p>
                                                            </Col>
                                                            <hr style={{ width: '100%', borderColor: theme === 'dark-only' ? 'grey' : 'black', margin: '20px 0' }} />
                                                            <Col md="12"
                                                                style={{ marginTop: '20px', }}>
                                                                <h4
                                                                    style={{
                                                                        marginLeft: '-8px',
                                                                        color: theme === 'dark-only' ? 'grey' : 'black'
                                                                    }}
                                                                    className="cInfoH4"
                                                                >
                                                                    Product & Services
                                                                </h4>
                                                                <div
                                                                    style={{
                                                                        marginLeft: '-8px',
                                                                        color: theme === 'dark-only' ? Color.DARK_THE_GREY_TEXT : '#5678D5',
                                                                        display: 'flex',
                                                                        flexWrap: 'wrap',
                                                                        gap: '10px'
                                                                    }}
                                                                    className="cInfo"
                                                                    id="output"
                                                                >
                                                                    {proServ?.map((line, index) => (
                                                                        <span key={index} style={{ minWidth: '150px', textTransform: 'capitalize' }}>
                                                                            • {line}
                                                                        </span>
                                                                    ))}
                                                                </div>
                                                            </Col>
                                                        </div>
                                                        {/* <Row>
                                                            <Col sm='6'>
                                                                <div style={{ marginTop: '15px' }}>
                                                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                                                        <h4 style={{ marginLeft: '-8px', color: theme === 'dark-only' ? 'grey' : 'black' }} className="cInfoH4">Departments</h4>
                                                                    </div>
                                                                    <div style={{}}>
                                                                        {getCompany?.company?.departments_summary?.map((item) => {
                                                                            return (
                                                                                <>
                                                                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                                                                        <h4 style={{ marginLeft: '-8px', textTransform: 'capitalize', fontWeight: '300', fontSize: '14px', color: theme === 'dark-only' ? 'grey' : 'black' }} className="cInfoH4">{item.department}</h4>
                                                                                        <p style={{ color: theme === 'dark-only' ? 'grey' : '#FF3364', marginTop: '-5px' }}>{item.staff} members</p>
                                                                                    </div>
                                                                                </>
                                                                            )
                                                                        })}
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                            <Col sm='6'>
                                                                <div style={{ marginTop: '15px' }}>
                                                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                                                        <h4 style={{ marginLeft: '-8px', color: theme === 'dark-only' ? 'grey' : 'black' }} className="cInfoH4">AI Assistants</h4>
                                                                    </div>
                                                                    <div style={{}}>
                                                                        {getCompany?.company?.assistant?.map((item) => {
                                                                            return (
                                                                                <>
                                                                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                                                                        <h4 style={{ marginLeft: '-8px', textTransform: 'capitalize', fontWeight: '300', fontSize: '14px', color: theme === 'dark-only' ? 'grey' : 'black' }} className="cInfoH4">{item?.bot_name}</h4>
                                                                                        <p style={{ color: theme === 'dark-only' ? 'grey' : '#FF3364', marginTop: '-5px' }}>{formatMobileNumber(item?.phone_number)}</p>
                                                                                    </div>
                                                                                </>
                                                                            )
                                                                        })}
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                        </Row> */}
                                                    </Row>
                                                </CardBody>
                                            </Card>
                                        </div >
                                    </Col >
                                }


                                {/* <Col lg="12" md="12" sm="12">
                                    <div>
                                        <Col md="12">
                                            <Card>
                                                <div style={{ paddingTop: '20px', paddingRight: '20px', paddingLeft: '20px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                                    <H5>Phone Directory</H5>
                                                    <Link to={`${process.env.PUBLIC_URL}/app/manage-directory`} style={{ textDecoration: "underline", color: theme === 'dark-only' ? 'grey' : 'black', fontWeight: '500', }}>Add Staff Phone</Link>
                                                </div>
                                                <CardBody>
                                                    <DataTable
                                                        data={directoryData}
                                                        columns={tableColumns}
                                                        striped={true}
                                                        center={true}
                                                        pagination
                                                        paginationPerPage={50}
                                                        paginationRowsPerPageOptions={[50]}
                                                        noDataComponent={
                                                            <div style={{ padding: '10px', color: theme === 'dark-only' ? '#fff' : '#000', backgroundColor: theme === 'dark-only' ? '#262932' : '#fff', textAlign: 'center', width: '100%', border: 'none' }}>
                                                                There are no records to display
                                                            </div>
                                                        }
                                                    />
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </div>
                                </Col> */}
                            </Row >
                            {/* )
                            })} */}
                        </>

                    )}
                </Row >
            </Container >
        </Fragment >
    );
};

export default ManageCompany;
