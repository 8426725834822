import React, { useEffect, useState } from 'react'
import { FiEdit2 } from 'react-icons/fi'
import { Col, Row } from 'reactstrap'
import { P, H4 } from '../../../AbstractElements'
import { Color } from '../../../helpers/colors'
import { useSelector } from 'react-redux';
import { selectCompleteCompany } from '../../../Store/modules/company/companySelectors';
import { Link } from 'react-router-dom'
import moment from "moment";

const EditScheduleBot = ({ EditClick, formData }) => {
    const [data, setData] = useState([]);
    const [selectedBot, setSelectedBot] = useState(null);
    const completeCompany = useSelector(selectCompleteCompany);
    const [days, setDays] = useState([]);

    const botOptions = completeCompany?.company?.company?.assistant?.map(bot => ({
        value: bot?.bot_id,
        label: bot?.bot_name,
    })) || [];

    useEffect(() => {




        // if (botOptions?.length === 1) {
        //     console.log(botOptions,"===---=-=-==--=-=-=-==-=-=-")
        //     setSelectedBot(botOptions[0]); 
        // } else {
        //     setSelectedBot(botOptions[0]); 
        // }
        if (formData?.length !== 0) {
            const bo = botOptions.find(bot => bot.value === formData[0]?.bot_id);
            setSelectedBot(bo);

            // console.log(checkedDays,"000000-0000000000000000000000000000")
            // setDays(checkedDays)
            setData(formData)
        }
    }, [])

    const getBotName = (id) => {
        const bo = botOptions.find(bot => bot.value === id);
        console.log(bo)
        return bo?.label
    }

    const getNotificationText = (notificationKey) => {
        switch (notificationKey) {
            case "fluten_calendar":
                return 'Fluten Calendar'
            case "google_calendar":
                return 'Google Calendar'
            case "push_notification":
                return 'Push Notification'
            case "sms":
                return 'SMS'
            case "email":
                return 'Email'
            default:
                break;
        }
    }

    const getOverrideColor = (date) => {
        return moment().isSameOrBefore(date, 'day') ? Color.BLACK : Color.DARK_THE_GREY_TEXT
    }

    return (
        <>

            {formData.map((bot, index) => {
                let sch = bot?.schedule.filter(item => item.checked).map(item => item.day);
                const overrides = bot?.overrides.map(item => {
                    const startDateFormatted = item?.daterange[0]?.replace(/(\d{2})-(\d{2})-(\d{4})/, "$3-$1-$2");
                    const endDateFormatted = item.daterange[item?.daterange?.length - 1].replace(/(\d{2})-(\d{2})-(\d{4})/, "$3-$1-$2");

                    return {
                        startDate: new Date(startDateFormatted + "T00:00:00.000Z").toISOString(),
                        endDate: new Date(endDateFormatted + "T00:00:00.000Z").toISOString(),
                        key: "selection",
                        name: item.title
                    };
                });
                return (
                    <>
                        <Row style={{ marginBottom: '25px' }}>
                            <Col sm='6'>
                                <H4>{getBotName(bot?.bot_id)}</H4>
                            </Col>
                            <Col sm='6' style={{ textAlign: "right" }}>
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'end' }}>
                                    <div style={{ marginTop: '-8px' }}>
                                        <button
                                            onClick={EditClick}
                                            style={{
                                                alignItems: 'center',
                                                gap: '5px',
                                                backgroundColor: Color.WHITE,
                                                border: '1px solid #ddd',
                                                borderRadius: '5px',
                                                padding: '5px 10px',
                                                cursor: 'pointer',
                                                fontsize: '14px',
                                                color: Color.BUTTON_TEXT_GREY,
                                                height: '43px',

                                            }}>
                                            Edit{" "}<FiEdit2 size={15} />
                                        </button>
                                    </div>
                                    <div>
                                        <Link to={"../app/calendar"}
                                            className='btn btn-primary'
                                            style={{
                                                borderColor: Color.PRIMARY,
                                                color: 'white',
                                                marginTop: "0px",
                                                marginRight: "0px",
                                            }} >
                                            Calendar
                                        </Link>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row style={{ marginBottom: '25px' }}>
                            <Col sm='2'>
                                <P>Available Days:</P>
                            </Col>
                            <Col sm='6'>
                                <P>
                                    {sch?.map((day, index) => {
                                        return (
                                            <> {day}{sch?.length > index + 1 && ', '}</>
                                        )
                                    })
                                    }
                                </P>
                            </Col>
                        </Row>
                        <Row style={{ marginBottom: '25px' }}>
                            <Col sm='2'>
                                <P>Booking Notifications:</P>
                            </Col>
                            <Col sm='6'>
                                <P>{bot?.notifications?.map((notificationKey, index) => {
                                    console.log("🚀 ~ <P>{bot?.notifications?.map ~ notificationKey:", bot?.notifications)
                                    return (
                                        <>
                                            {getNotificationText(notificationKey)}{bot?.notifications?.length > index + 1 && ', '}
                                            {/* {notificationKey === "fluten_calendar" ? "Fluten Calendar" : notificationKey === "google_calendar" ? "Google Calendar" : notificationKey === "push_notification" ? "Push Notification" : notificationKey === "email" ? "Email" : ""} {bot?.notifications?.length > index + 1 && ','} */}
                                        </>
                                    )
                                })

                                }</P>
                            </Col>
                        </Row>
                        <Row style={{ marginBottom: '25px' }}>
                            <Col sm='2'>
                                <P>Time Slot:</P>
                            </Col>
                            <Col sm='6'>
                                <P>{bot.slot_size === 15 ? "15 minutes" : bot.slot_size === 30 ? "30 minutes" : bot.slot_size === 60 ? "1 hour" : bot.slot_size === 120 ? "2 hours" : ""}</P>
                            </Col>
                        </Row>
                        <Row style={{ marginBottom: '25px' }}>
                            <Col sm='2'>
                                <P>Overrides:</P>
                            </Col>
                            <Col sm='6'>
                                {overrides?.length > 0 &&
                                    <P>
                                        {overrides?.map((data, index) => {
                                            return (
                                                <>
                                                    <div>
                                                        {moment(data.startDate).isSame(moment(data.endDate), 'day') ?
                                                            <p style={{ color: getOverrideColor(data?.startDate) }}>
                                                                {data.startDate
                                                                    ? moment(data.startDate).format("DD MMM")
                                                                    : "N/A"}
                                                                &nbsp;[
                                                                {data.name ? data.name : "N/A"}]
                                                                {overrides?.length > index + 1 && ', '}<br />
                                                            </p> :
                                                            <p style={{ color: getOverrideColor(data?.startDate) }}>
                                                                {data.startDate
                                                                    ? moment(data.startDate).format("DD MMM")
                                                                    : "N/A"}
                                                                -
                                                                {data.endDate
                                                                    ? moment(data.endDate).format("DD MMM")
                                                                    : "N/A"}
                                                                &nbsp;[
                                                                {data.name ? data.name : "N/A"}]
                                                                {overrides?.length > index + 1 && ', '}<br />
                                                            </p>
                                                        }
                                                    </div>
                                                    {/* {data.startDate
                                                ? moment(data.startDate).format("DD MMM")
                                                : "N/A"}
                                            -
                                            {data.endDate
                                                ? moment(data.endDate).format("DD MMM")
                                                : "N/A"}
                                            &nbsp;[
                                            {data.name ? data.name : "N/A"}]
                                            {overrides.length > index + 1 && ', '}<br /> */}
                                                </>
                                            )
                                        })}
                                    </P>
                                }

                            </Col>
                        </Row>
                        {/* <Row style={{ marginBottom: '25px' }}>
                            <Col sm='2'>
                                <P>AI Assistant Guidelines:</P>
                            </Col>
                            <Col sm='6'>
                                <P>
                                    {bot.prompt}
                                </P>
                            </Col>
                        </Row> */}

                    </>
                )


            })}

        </>
    )
}

export default EditScheduleBot