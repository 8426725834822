import React, { useContext, useEffect, useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { bookNumber, createCustomerAndSetupIntent } from '../api/Company';
import { useLocation, useNavigate } from 'react-router';
import { setUpPhone } from '../api/AuthIndex';
import ApiContext from '../ApiContext';
import { ToastContainer, toast } from "react-toastify";
import { STRIPE_PUBLIC_KEY } from '../helpers/constants';

// Initialize Stripe.js with your publishable key
// const stripePromise = loadStripe('pk_test_51Pa1MqHPlLwpeHXdifepfNZ6pTSJKfvkleCUaYqtN9NOSkUoz1s8SKxInsX23RakhcIZ1AJh9XlwxSONVeIVzSh1007Zuhfvnd');
const stripePromise = loadStripe(STRIPE_PUBLIC_KEY);

const CheckoutForm = () => {
    const { setBackNavigation, backNavigation } = useContext(ApiContext);
    const botData = JSON.parse(localStorage.getItem("bot_Data"))
    console.log("🚀 ~ CheckoutForm ~ botData:", botData)
    const companyData = JSON.parse(localStorage.getItem("signup_company"))
    const userInfo = JSON.parse(localStorage.getItem("register"))
    const navigate = useNavigate();
    const location = useLocation();
    console.log("🚀 ~ CheckoutForm ~ location:", location)
    const haveNumber = location?.state?.haveNumber
    console.log("🚀 ~ CheckoutForm ~ haveNumber:", haveNumber)
    const numberDetail = location?.state?.bookedNumberData
    const token = userInfo.token
    const stripe = useStripe();
    const elements = useElements();
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const [userClientSecretId, setUserClientSecretId] = useState(null)
    const [userCustomerId, setUserCustomerId] = useState(null)

    const [accountSID, setAccountSID] = useState()
    const [authToken, setAuthToken] = useState();
    const [provider, setProvider] = useState('Twilio')
    const [phone, setPhone] = useState()
    const [loading, setLoading] = useState(false)


    const cardStyle = {
        style: {
            base: {
                color: "#32325d",
                fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
                fontSmoothing: "antialiased",
                fontSize: "16px",
                "::placeholder": {
                    color: "#aab7c4",
                },
            },
            invalid: {
                color: "#fa755a",
                iconColor: "#fa755a",
            },
        },
    };

    useEffect(() => {
        if (backNavigation) {
            navigate(`${process.env.PUBLIC_URL}/complete`)
        } else {
            getCustomerIntent()
        }
    }, [])


    const handleBookNumber = async () => {
        setPhone(null)
        try {
            const formData = new FormData()
            formData.append('company_id', companyData?.id)
            formData.append('phone_number', numberDetail?.phone_number)
            const res = await bookNumber(userInfo?.token, formData)
            console.log("🚀 ~ handleBookNumber ~ res:", res)
            setAccountSID(res.ACCOUNT_SID)
            setAuthToken(res.AUTH_TOKEN)
            setPhone(res.phone)

            if (res.status) {
                handleHaveTwilioNumber(res)
            }

        } catch (error) {
            console.log("🚀 ~ handleBookNumber ~ error:", error)
            setLoading(false)
        }
    }

    const handleHaveTwilioNumber = async (data) => {
        console.log("🚀 ~ handleHaveTwilioNumber ~ data:", data)
        const providerKeys = `${data?.ACCOUNT_SID},${data?.AUTH_TOKEN}`
        try {
            // const formData = new FormData()
            // formData.append("provider", provider === 'Twilio' ? 'Tw' : provider === 'Vonage' ? 'Vo' : 'Tw')
            // formData.append("phone_number", data.phone)
            // formData.append("provider_keys", providerKeys)
            // formData.append("bot_name", botData?.bot_name)
            // formData.append("company_id", companyData?.id)
            // formData.append("bot_id", botData?.id)
            // const res = await setUpPhone(userInfo.token, formData)
            // if (res.data.original.error) {
            //     toast.error("The phone number has already been taken.", {
            //         position: "top-left",
            //         autoClose: 3000,
            //         hideProgressBar: true,
            //         closeOnClick: true,
            //         pauseOnHover: true,
            //         draggable: true,
            //         progress: undefined,
            //         theme: "light",
            //         className: 'custom-toast-error',
            //     });
            //     setLoading(false)
            // } else {
            setLoading(false)
            setBackNavigation(true)
            navigate(`${process.env.PUBLIC_URL}/complete`)
            const phoneSetupData = {
                provider: provider === 'Twilio' ? 'Tw' : provider === 'Vonage' ? 'Vo' : 'Tw',
                phone_number: data?.phone,
                provider_keys: providerKeys,
                bot_name: botData?.bot_name,
                company_id: companyData?.id,
                bot_id: botData?.id
            }
            localStorage.setItem('phoneSetupData', JSON.stringify(phoneSetupData))
            // }
        } catch (error) {
            console.log("🚀 ~ handleHaveTwilioNumber ~ error:", error)
            setLoading(false)
        }
    }


    const getCustomerIntent = async () => {
        const data = {
            name: userInfo?.user?.name,
            email: userInfo?.user?.email
        }
        const res = await createCustomerAndSetupIntent(token, data)
        setUserClientSecretId(res.clientSecret)
        setUserCustomerId(res.customerId)
    }

    const handleSubmit = async (event) => {
        setLoading(true)
        event.preventDefault();

        const clientSecret = userClientSecretId;

        if (!stripe || !elements) {
            return;
        }

        const cardElement = elements.getElement(CardElement);

        const { setupIntent, error } = await stripe.confirmCardSetup(
            clientSecret,
            {
                payment_method: {
                    card: cardElement,
                },
            }
        );

        if (error) {
            // Show error in UI
            setError(`Error: ${error.message}`);
        } else {
            // Card has been successfully saved
            setSuccess('Card saved successfully!');

            const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

            // Save the setupIntent.payment_method and customer_id to the backend
            const response = await fetch(`${process.env.REACT_APP_API_URL}/savePaymentMethod`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRF-TOKEN': csrfToken,
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify({
                    payment_method: setupIntent.payment_method,
                    customer_id: userCustomerId,
                }),
            });

            if (response.ok) {
                if (haveNumber == false) {
                    handleBookNumber()
                } else {
                    setLoading(false)
                    setBackNavigation(true)
                    navigate(`${process.env.PUBLIC_URL}/complete`)
                }
            } else {
                console.log('Error saving payment method');
            }
        }
    };

    return (
        <form id="payment-form" onSubmit={handleSubmit}>

            <div style={{ flex: 1, marginTop: '8%' }}>
                <label style={{ fontSize: '16px' }}>Name</label>
                <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Name"
                    style={{ marginTop: '5px' }}
                    value={userInfo?.user?.name}
                    readOnly
                />
            </div>

            <div style={{ flex: 1, marginTop: '10%' }}>
                <label style={{ fontSize: '16px' }}>Email</label>
                <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Name"
                    style={{ marginTop: '5px' }}
                    value={userInfo?.user?.email}
                    readOnly
                />
            </div>

            <label style={{ marginTop: '10%', fontSize: '16px' }}>Card Details</label>
            <CardElement id="card-element" options={cardStyle} />
            {loading ?
                <button type="submit" disabled style={{ marginTop: "10%", padding: "10px 20px", backgroundColor: "primary", color: "#fff", border: "none", borderRadius: "4px", cursor: "pointer", width: '100%' }}>
                    Saving...
                </button>
                :
                <button type="submit" style={{ marginTop: "10%", padding: "10px 20px", backgroundColor: "#5978D4", color: "#fff", border: "none", borderRadius: "4px", cursor: "pointer", width: '100%' }}>
                    Save Details
                </button>
            }

            {error && <div className="error" style={{ color: "red", marginTop: "10px" }}>{error}</div>}
            <ToastContainer />
            {/* {success && <div className="success" style={{ color: "green", marginTop: "10px", width:'40%' }}>{success}</div>} */}
        </form>
    );
};

const PaymentStripe = () => {
    return (
        <Elements stripe={stripePromise}>
            <CheckoutForm />
        </Elements>
    );
};

export default PaymentStripe;