import axios from 'axios';
import React, { Fragment, useState, useEffect } from 'react';
import { PlusSquare, Upload } from 'react-feather';
import errorImg from '../../assets/images/search-not-found.png';
import { toast } from 'react-toastify';
import { H4, H6, LI, P, UL, Image, H5 } from '../../AbstractElements';
import { CardBody, CardHeader, Form, Input, Media, Row, Col, FormGroup, Label, Button, Card, } from 'reactstrap';
import { FileApi } from '../../api';
import { Modal } from 'react-bootstrap';
import { FileUploader } from "react-drag-drop-files";
import { getBotByCompany, uploadFile, getAllFileData, getFileByIdData, replaceFile, deleteFileById } from '../../api/AuthIndex';
import "./styles.css";
import moment from "moment";
import { Spinner } from 'react-bootstrap';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownCommon from '../../Components/Common/Dropdown';
import { FaFilePdf, FaFileLines } from "react-icons/fa6";
import { BiSolidFilePng } from "react-icons/bi";
import { FaFileWord } from "react-icons/fa";
import '../ManageAssistant/ManageAssistant.css'

import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import NoDataFound from '../NoDataFound/NoDataFound';
import { debounceTime } from '../../helpers/constants';
import { getLocalStorage } from '../../helpers/LocalStorageHelper';
import { Color } from '../../helpers/colors';


const fileTypes = ["txt", "Docx", "PDF"];

const FileContent = () => {
  let getCompanyInfo = JSON.parse(localStorage.getItem('companyData'))
  let token = getLocalStorage('tokken')
  const theme = localStorage.getItem("mix_background_layout")
  const [selectedFiles, setSelectedFiles] = useState("");
  const [searchTerm, setSearchTerm] = useState('');
  const [myfile, setMyFile] = useState([]);
  const [addFile, setAddFile] = useState(false);
  const [desc, setDesc] = useState("");
  const [bots, setBots] = useState([]);
  const [selectedBot, setSelectedBot] = useState("");
  const [showEditButton, setShowEditButton] = useState(null); // State for toggling edit button
  const [editModalVisible, setEditModalVisible] = useState(false); // State for managing edit modal visibility
  const [deleteModalVisible, setDeleteModalVisible] = useState(false); // State for managing edit modal visibility
  const [valid, setValid] = useState("")
  const [currentFile, setCurrentFile] = useState(null); // State for storing the current file data

  const [loading, setLoading] = useState(false)
  const [spinner, setSpinner] = useState(false)

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      getAllFile();
    }, debounceTime);
    return () => clearTimeout(delayDebounceFn);
  }, []);

  const getAllFile = async () => {
    setSpinner(true)
    try {
      const formData = new FormData();
      formData.append("company_id", getCompanyInfo?.id);
      let res = await getAllFileData(token, formData);
      setMyFile(res);
      setSpinner(false)
    } catch (error) {
      console.log("🚀 ~ getAllFile ~ error:", error)
      setSpinner(false)
    }
  }

  function convertDateFormat(dateString) {
    console.log(dateString, "342r3423")
    const date = moment(dateString);
    const formattedDate = date.format('MMMM Do, YY');
    const formattedTime = date.format('HH:mm');
    return `${formattedDate} ${formattedTime}`;
  }

  const getFileExtension = (fileName) => {
    return fileName.split('.').pop().toLowerCase();
  };

  // const getFileIconClass = (fileName) => {
  //   const extension = getFileExtension(fileName);
  //   return extension === "pdf" ? <FaFilePdf size={25} /> :
  //     extension === "png" ? <BiSolidFilePng size={25} /> :
  //       extension === "text" ? <FaFileLines size={25} /> :
  //         extension === "docx" ? <FaFileWord size={25} /> : "";
  // };

  const getFileIconClass = (fileName) => {
    const extension = getFileExtension(fileName);
    return extension === "pdf" ? <img style={{ width: '50px', height: '50px' }} src={require('../../assets/images/user/pdf.png')} /> :
      extension === "png" ? <BiSolidFilePng size={25} /> :
        extension === "txt" ? <img style={{ width: '50px', height: '50px' }} src={require('../../assets/images/user/txt.png')} /> :
          extension === "docx" ? <img style={{ width: '50px', height: '50px' }} src={require('../../assets/images/user/docx.png')} /> : "";
  };

  // const getFileIconClass = (fileName) => {
  //   const extension = getFileExtension(fileName);
  //   return extension === "pdf" ? "fa-solid fa-file-pdf" :
  //     extension === "png" ? "fa-solid fa-file-png" :
  //       extension === "text" ? "fa-regular fa-file-lines" :
  //         extension === "docx" ? "fa-regular fa-file-word" : "";
  // };

  const getFileName = (fileName) => {
    const name = fileName.split('.').slice(0, -1).join('.');
    return name;
  };

  const handleProviderChange = (e) => {
    setValid("")
    setSelectedBot(e.target.value);
  };

  const handleChange = (event) => {
    event.preventDefault();
    setSearchTerm(event.target.value);
  };

  const handleFileChange = (file) => {
    setSelectedFiles(file);
    setValid("")
  };

  const handleFileChange2 = (file) => {
    setSelectedFiles(file);
  };


  const getBot = async () => {
    const formData = new FormData();
    formData.append("company_id", getCompanyInfo?.id);
    let res = await getBotByCompany(formData, token);
    console.log("🚀 ~ getBot ~ res:", res)
    setBots(res.bots);
    setSelectedBot(res?.bots[0]?.bot_id)
  }

  const handleEditClick = async (file) => {
    setCurrentFile(file);
    setValid("")
    setEditModalVisible(true);
  }

  const viewFile = async (file) => {
    const formData = new FormData();
    formData.append("file_id", file?.id);
    try {
      let response = await getFileByIdData(token, formData);

      if (response && response.url) {
        // Open the file URL in a new tab
        window.open(response.url, '_blank');
      } else {
        console.error('URL not found in response', response);
      }
    } catch (error) {
      console.error('Error fetching file data', error);
    }
  }

  const handleDeleteClick = async (file) => {
    setCurrentFile(file);
    setDeleteModalVisible(true);

  }

  const handleEditClick2 = async () => {
    const formData = new FormData();
    formData.append("file_id", currentFile?.id);
    let deleteFile = await deleteFileById(token, formData)
    getAllFile();
    setDeleteModalVisible(false)
  }

  const DropdownMenu = ["View", "Edit", "Delete"];

  const handleActionSelect = (data, action) => {
    if (action === "View") {
      viewFile(data)
    } else if (action == "Edit") {
      handleEditClick(data); getBot();
    } else if (action === "Delete") {
      handleDeleteClick(data)
    }
  };

  const filelist = myfile
    .filter((data) => {
      console.log("🚀 ~ .filter ~ data:", data)
      if (searchTerm == null) {
        return data;
      } else if (data.file_name.toLowerCase().includes(searchTerm.toLowerCase())) {
        return data;
      }
    })
    .map((data, i) => {
      return (
        <Card style={{ width: '350px', marginRight: '20px', padding: '20px', alignItems: 'center', borderRadius: '10px', boxShadow: theme === 'dark-only' ? '' : '1px 2px 5px lightgrey', marginBottom: '20px' }} key={i} >
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div style={{ display: 'flex' }}>
              <div style={{ marginTop: '5px' }}>
                {getFileIconClass(data?.file_name)}
                {/* <i style={{ fontSize: '25px' }} className={getFileIconClass(data?.file_name)}></i> */}
              </div>

              <div style={{ marginLeft: '20px' }} >
                <div onClick={() => { viewFile(data) }} style={{ cursor: 'pointer' }}>
                  <H5 attrH5={{
                    style: {
                      cursor: "pointer",
                      fontWeight: "bold",
                      fontSize: "18px",
                      textTransform: "capitalize",
                      color:theme === 'dark-only' ? Color.DARK_THE_GREY_TEXT : "#013165",
                      marginTop: "5px",
                      width: "250px", // Set the maximum width
                      overflow: "hidden", // Hide the overflowing content
                      textOverflow: "ellipsis", // Show "..." for truncated text
                      whiteSpace: "nowrap",
                    }
                  }}>{getFileName(data?.file_name)}</H5>
                </div>
                <p style={{ marginBottom: "5px", width: '220px', lineHeight: 1.2, color: theme === 'dark-only' ? 'grey' : 'black' }}>{data?.description}</p>
                <p style={{ lineHeight: 1 }}>
                  <span style={{ color: 'grey' }}>
                    {moment(data?.created_at).format('MMMM Do, YYYY')}
                  </span>
                </p>
              </div>
            </div>

            <DropdownCommon
              dropdownMain={{ className: 'icon-dropdown', direction: 'start', }}
              options={DropdownMenu}
              iconName='fa-solid fa-ellipsis-vertical'
              btn={{ tag: 'span', style: { color: theme === 'dark-only' ? 'grey' : 'black', cursor: 'pointer', padding: '10px' } }}
              onSelect={(action) => handleActionSelect(data, action)}
            />
          </div>
        </Card >
        // </LI>
      );
    });

  const onFileUpload = async () => {
    try {

      if (desc === "") {
        setValid("Please enter the Description");
        return false
      } else if (selectedBot === "") {
        setValid("Please select the Assistant");
        return false
      } else if (selectedFiles === "") {
        setValid("Please select a File.");
        return false
      }
      setLoading(true)
      const formData = new FormData();
      formData.append("file", selectedFiles);
      formData.append("company_id", getCompanyInfo?.id);
      formData.append("bot_id", selectedBot);
      formData.append("description", desc);
      let res = await uploadFile(formData, token);
      setBots(res.bots);
      toast.info("Files Uploaded Successfully!", {
        position: "bottom-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        className: 'custom-toast',
      });
      setLoading(false)
      setSelectedFiles(""); // Clear selected files after upload
      setAddFile(false); // Close the modal after upload
      getAllFile();
      setDesc("")

    } catch (e) {
      toast.error('Server Error');
    }
  };

  const onFileUpload2 = async () => {
    try {
      if (selectedFiles === "") {
        setValid("Please select a File.");
        return false
      }
      setLoading(true)
      const formData = new FormData();
      formData.append("file", selectedFiles);
      formData.append("file_id", currentFile.id);
      let res = await replaceFile(formData, token);
      setBots(res.bots);
      toast.info('Files Uploaded Successfully!', {
        position: "bottom-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        className: 'custom-toast',
      });
      setLoading(false)
      setSelectedFiles(""); // Clear selected files after upload
      setAddFile(false); // Close the modal after upload
      getAllFile();
      setEditModalVisible(false)
    } catch (e) {
      toast.error('Server Error');
    }
  };



  return (
    <Fragment>
      {spinner ? (
        <Row>
          <Col sm='12'>
            <Card >
              <CardBody style={{ padding: '10px' }}>
                <SkeletonTheme baseColor={theme == 'dark-only' ? "#202020" : ""} highlightColor={theme == 'dark-only' ? "#444" : ""}>
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Skeleton height={30} style={{ width: '150px' }} />
                    <Skeleton height={30} style={{ width: '200px' }} />
                  </div>
                </SkeletonTheme>
              </CardBody>
            </Card>
          </Col>
          <Col sm='4'>
            <Card>
              <CardBody style={{ padding: '10px' }}>
                <SkeletonTheme baseColor={theme == 'dark-only' ? "#202020" : ""} highlightColor={theme == 'dark-only' ? "#444" : ""}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Skeleton height={50} style={{ width: '50px', borderRadius: '50px' }} />
                    <Skeleton height={10} count={5} style={{ width: '18vw', marginLeft: '10px' }} />
                  </div>
                </SkeletonTheme>
              </CardBody>
            </Card>
          </Col>
          <Col sm='4'>
            <Card>
              <CardBody style={{ padding: '10px' }}>
                <SkeletonTheme baseColor={theme == 'dark-only' ? "#202020" : ""} highlightColor={theme == 'dark-only' ? "#444" : ""}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Skeleton height={50} style={{ width: '50px', borderRadius: '50px' }} />
                    <Skeleton height={10} count={5} style={{ width: '18vw', marginLeft: '10px' }} />
                  </div>
                </SkeletonTheme>
              </CardBody>
            </Card>
          </Col>
          <Col sm='4'>
            <Card>
              <CardBody style={{ padding: '10px' }}>
                <SkeletonTheme baseColor={theme == 'dark-only' ? "#202020" : ""} highlightColor={theme == 'dark-only' ? "#444" : ""}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Skeleton height={50} style={{ width: '50px', borderRadius: '50px' }} />
                    <Skeleton height={10} count={5} style={{ width: '18vw', marginLeft: '10px' }} />
                  </div>
                </SkeletonTheme>
              </CardBody>
            </Card>
          </Col>
          <Col sm='4'>
            <Card>
              <CardBody style={{ padding: '10px' }}>
                <SkeletonTheme baseColor={theme == 'dark-only' ? "#202020" : ""} highlightColor={theme == 'dark-only' ? "#444" : ""}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Skeleton height={50} style={{ width: '50px', borderRadius: '50px' }} />
                    <Skeleton height={10} count={5} style={{ width: '18vw', marginLeft: '10px' }} />
                  </div>
                </SkeletonTheme>
              </CardBody>
            </Card>
          </Col>
          <Col sm='4'>
            <Card>
              <CardBody style={{ padding: '10px' }}>
                <SkeletonTheme baseColor={theme == 'dark-only' ? "#202020" : ""} highlightColor={theme == 'dark-only' ? "#444" : ""}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Skeleton height={50} style={{ width: '50px', borderRadius: '50px' }} />
                    <Skeleton height={10} count={5} style={{ width: '18vw', marginLeft: '10px' }} />
                  </div>
                </SkeletonTheme>
              </CardBody>
            </Card>
          </Col>
          <Col sm='4'>
            <Card>
              <CardBody style={{ padding: '10px' }}>
                <SkeletonTheme baseColor={theme == 'dark-only' ? "#202020" : ""} highlightColor={theme == 'dark-only' ? "#444" : ""}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Skeleton height={50} style={{ width: '50px', borderRadius: '50px' }} />
                    <Skeleton height={10} count={5} style={{ width: '18vw', marginLeft: '10px' }} />
                  </div>
                </SkeletonTheme>
              </CardBody>
            </Card>
          </Col>
        </Row>
      )
        :
        (
          <div style={{ padding: '10px', marginTop: '-20px' }}>
            <Card>
              <CardBody>
                <CardHeader className="cusHdKB">
                  <Media>
                    <Media body>
                      <H4 attrH4={{ className: 'mb-3 kbheading' }} >Files</H4>
                      <div className='btn btn-primary' onClick={() => { setAddFile(true); getBot(); }} style={{ float: "right" }}>
                        <PlusSquare />
                        Add
                      </div>
                    </Media>
                  </Media>
                </CardHeader>

                {filelist?.length > 0 ? (
                  // <CardBody className='file-manager'>
                  <div style={{ display: 'flex', flexWrap: 'wrap', marginTop: '10px' }}>
                    {filelist}
                  </div>
                  // </CardBody>
                ) : (

                  // <Card style={{ height: '600px' }}>
                  <NoDataFound message={'No knowledge base files found. Click add to upload.'} />
                  // </Card>
                )}
              </CardBody>
            </Card>
          </div>
        )}

      <Modal
        show={addFile}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={() => setAddFile(false)}
        className='fileMo'
      >
        <Modal.Header closeButton>
          <H4>Upload File</H4>
        </Modal.Header>
        <Modal.Body>
          <Row style={{ padding: "0px 25px 0px 25px" }}>
            <Col md="12">
              <FormGroup >
                <Label className="col-sm-12 col-form-label" style={{fontWeight:'500', fontSize:'18px'}} htmlFor="provider">Assistant</Label>
                <Col sm="12">
                  <Input
                    type="select"
                    name="provider"
                    value={selectedBot} // This binds the selected bot's ID to the state
                    className="form-control input-air-primary digits"
                    onChange={handleProviderChange} // This updates the state on change
                  >
                    {bots?.map(option => {
                      console.log("Botlist Options", option);
                      return (
                        <option key={option?.bot_id} value={option?.bot_id}>
                          {option?.bot_name}
                        </option>
                      )
                    })}
                  </Input>
                  <Label style={{ color: "red" }}>{valid === "Please select the Assistant " && valid}</Label>
                </Col>
              </FormGroup>
              <FormGroup >
                <Label className="col-sm-12 col-form-label" style={{fontWeight:'500', fontSize:'18px'}} htmlFor="description">Description</Label>
                <Col sm="12">
                  <Input
                    type="textarea"
                    rows="3"
                    value={desc}
                    onChange={(e) => { setDesc(e.target.value); setValid("") }}
                    name="description"
                    className="form-control input-air-primary digits"
                    placeholder='This description will be used as a guideline for your assistant to understand at which situation this knowledge file should be used'
                  >
                  </Input>
                  <Label style={{ color: "red" }}>{valid === "Please enter the Description" && valid}</Label>
                </Col>
              </FormGroup>
            </Col>
            <Label className="col-sm-12 col-form-label" style={{fontWeight:'500', fontSize:'18px'}} htmlFor="provider">Upload</Label>
            <Col md="12" className="dragDrop">
              <FileUploader handleChange={handleFileChange} name="file" types={fileTypes} />
              <span style={{ color: "red" }}>{valid === "Please select a File." && valid}</span>
              <div>
                <h6>Selected Files:</h6>
                <UL>
                  <LI>{selectedFiles?.name}</LI>
                </UL>
              </div>
            </Col>
          </Row>
          <Media body className='text-end'>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', }}>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around', width: '60%' }}>
                {loading ?
                  <Button color="primary" disabled>
                    <Spinner
                      as="span"
                      animation="grow"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                    Loading...
                  </Button>
                  :
                  <Button style={{ width: '150px' }} type="submit" color="primary" onClick={onFileUpload}>
                    <Upload style={{ width: "14px", height: "14px", marginRight: "5px" }} />
                    {'Upload'}
                  </Button>
                }
                <Button style={{ height: '43px', width: '150px' }} onClick={() => setAddFile(false)}>Close</Button>
              </div>
            </div>
          </Media>

        </Modal.Body>
      </Modal>

      <Modal
        show={editModalVisible}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={() => setEditModalVisible(false)}
        className='fileMo'
      >
        <Modal.Header closeButton>
          <H4>Edit File</H4>
        </Modal.Header>
        <Modal.Body>
          <Row style={{ padding: "0px 25px 0px 25px" }}>
            <Col md="12">
              <FormGroup className="row">
                <Label className="col-sm-3 col-form-label" htmlFor="fileName">File Name</Label>
                <Col sm="9">
                  <Input
                    type="text"
                    value={currentFile?.file_name}
                    readOnly
                    className="form-control input-air-primary digits"
                  />
                </Col>
              </FormGroup>
              <FormGroup className="row">
                <Label className="col-sm-3 col-form-label" htmlFor="description">Description</Label>
                <Col sm="9">
                  <Input
                    type="textarea"
                    readOnly
                    value={currentFile?.description}
                    name="description"
                    className="form-control input-air-primary digits"
                  />
                </Col>
              </FormGroup>
            </Col>

            <Col md="12">
              <Label className="col-sm-3 col-form-label" htmlFor="description">Replace File</Label>
            </Col>
            <Col md="12" className="dragDrop">
              <FileUploader handleChange={handleFileChange2} name="file" types={fileTypes} />
              <span style={{ color: "red" }}>{valid === "Please select a File." && valid}</span>
              <div>
                <h6>Selected Files:</h6>
                <UL>
                  <LI>{selectedFiles?.name}</LI>
                </UL>
              </div>
            </Col>
          </Row>
          <Media body className='text-end'>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', }}>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around', width: '60%' }}>
                {loading ?
                  <Button color="primary" disabled>
                    <Spinner
                      as="span"
                      animation="grow"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                    Loading...
                  </Button>
                  :
                  <Button style={{ width: "150px" }} type="submit" color="primary" onClick={onFileUpload2}>
                    <Upload style={{ width: "14px", height: "14px", marginRight: "5px" }} />
                    {'Upload'}
                  </Button>
                }
                <Button style={{ height: '43px', width: "150px" }} onClick={() => setEditModalVisible(false)}>Close</Button>
              </div>
            </div>
          </Media>
        </Modal.Body>
      </Modal>

      <Modal
        show={deleteModalVisible}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={() => setDeleteModalVisible(false)}
        className='fileMo'
      >
        <Modal.Header closeButton>
          <H4>Delete File</H4>
        </Modal.Header>
        <Modal.Body>
          <Row style={{ padding: "0px 25px 0px 25px" }}>
            <Col md="12">
              <p style={{ fontWeight: "500", fontSize: "16px", textAlign: "center" }}>Are you sure you want to delete this File?</p>
            </Col>
          </Row>

          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', }}>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around', width: '60%' }}>
              {loading ?
                <Button color="primary" disabled>
                  <Spinner
                    as="span"
                    animation="grow"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                  Loading...
                </Button>
                :
                <Button type="submit" color="primary" onClick={handleEditClick2}>
                  Delete
                </Button>
              }
              <Button style={{ height: '43px' }} onClick={() => setDeleteModalVisible(false)}>Close</Button>
            </div>
          </div>

        </Modal.Body>
      </Modal>

    </Fragment>
  );
};

export default FileContent;
