import Select from 'react-select';
import {useCreateAssistantContext} from "../context/CreateAssistantContext";
import {customSelectStyles} from "../styles/customSelectStyles";
import {Button, Form} from 'reactstrap';
import {useDispatch, useSelector} from 'react-redux';
import '../styles/tag-styles.css'
import {setFieldValue} from "../../../../Store/modules/assistant/create/form/createAssistantFormSlice";
import {isEmpty} from "../../../../helpers/utils";
import {useState} from "react";
import {BACKGROUND_VOICE_OPTIONS_ARR} from "../../../../helpers/constants";
import {explodeString, implodeArr} from "../../../../helpers/StringHelper";


export const CustomPrecastBotStep3 = () => {
    const {handleNext} = useCreateAssistantContext();
    const dispatch = useDispatch();
    const {values, errors, isSubmitting} = useSelector((state) => state.createAssistantForm);
    const [keywordsInput, setKeywordsInput] = useState('');
    const [tags, setTags] = useState(!isEmpty(values?.keywords) ? explodeString(values?.keywords) : []);

    const handleChange = (field, value) => {
        setFormErrors({});
        dispatch(setFieldValue({field, value}));
    };


    const [formErrors, setFormErrors] = useState({
        end_call_phrases: '',
        end_call_message: '',
        background_sound: '',
        keywords: '',
    });

    const getFormErrors = () => {
        let errors = {};

        if (isEmpty(values.end_call_message)) {
            errors.end_call_message = 'End call message is required';
        }

        if (isEmpty(values.end_call_phrases)) {
            errors.end_call_phrases = 'End call phrases are required';
        }

        if (isEmpty(values?.background_sound)) {
            errors.background_sound = 'You must choose one of these options';
        }

        // if (isEmpty(tags)) {
        //     errors.keywords = 'Keywords are required';
        // }

        setFormErrors({...formErrors, ...errors});

        return errors;
    }

    const handleKeyPress = (e) => {
        if (e.key === 'Enter' || e.key === ',') {
            const input = keywordsInput?.trim().replace(/,+$/, '').toLowerCase();
            if (input) {
                const isDuplicate = tags.some(tag => tag.toLowerCase() === input);
                if (isDuplicate) {
                    setFormErrors({...formErrors, keywords: 'This keyword has already been submitted.'});
                } else {
                    setTags([...tags, input]);
                }
                handleChange('keywords', '');
                setKeywordsInput('');
            }
            e.preventDefault();
        }
    };

    const removeTag = (indexToRemove) => {
        setTags(tags.filter((_, index) => index !== indexToRemove));
    };

    return (
        <Form>
            <div style={{marginBottom: '10px'}}>
                <label>End Call Phrases</label>
                <input
                    className="form-control"
                    name={'end_call_phrases'}
                    placeholder="Goodbye, Take Care, Bye"
                    value={values.end_call_phrases}
                    onChange={(e) => {
                        handleChange('end_call_phrases', e.target.value)
                    }}
                    style={{
                        fontSize: '14px',
                        fontWeight: '300'
                    }}
                />
                {formErrors?.end_call_phrases && <span style={{
                    color: 'red',
                    fontSize: '12px'
                }}>{formErrors?.end_call_phrases}</span>}
            </div>
            <div style={{marginBottom: '10px'}}>
                <label>End Call Message</label>
                <input
                    className="form-control"
                    placeholder="Have a nice day"
                    name={'end_call_message'}
                    value={values?.end_call_message}
                    onChange={(e) => {
                        handleChange('end_call_message', e.target.value)
                    }}
                    style={{
                        fontSize: '14px',
                        fontWeight: '300'
                    }}
                />
                {formErrors?.end_call_message && <span style={{
                    color: 'red',
                    fontSize: '12px'
                }}>{formErrors?.end_call_message}</span>}
            </div>
            <div style={{marginBottom: '10px'}}>
                <label>Background Sound</label>
                <Select
                    options={BACKGROUND_VOICE_OPTIONS_ARR}
                    styles={customSelectStyles}
                    value={values.background_sound}
                    onChange={(backgroundVoice) => {
                        handleChange('background_sound', backgroundVoice);
                    }}
                    placeholder="Select Background Sound"
                />
                {formErrors?.background_sound && <span style={{
                    color: 'red',
                    fontSize: '12px'
                }}>{formErrors?.background_sound}</span>}
            </div>

            <div style={{marginBottom: '10px'}}>
                <label>Keywords</label>
                <div className="tags-input-container">
                    <div className="tags-wrapper">
                        {tags.map((tag, index) => (
                            <div key={index}
                                 className="tag">
                                {tag}
                                <span
                                    className="tag-close-icon"
                                    onClick={() => removeTag(index)}> &times; </span>
                            </div>
                        ))}
                    </div>

                    <textarea
                        className="form-control"
                        rows="1"
                        placeholder="Enter keywords and press Enter or comma"
                        name={'keywords'}
                        value={keywordsInput}
                        onChange={(e) => setKeywordsInput(e.target.value)}
                        onKeyPress={handleKeyPress}
                        maxLength={32}
                    />
                </div>
                {formErrors?.keywords && <div style={{
                    color: 'red',
                    marginTop: '5px'
                }}>{formErrors?.keywords}</div>}
            </div>

            <div style={{
                marginTop: '1.5em',
                width: '100%'
            }}>
                <Button
                    color="primary"
                    style={{width: '100%', margin: 0}}
                    onClick={() => {
                        const errors = getFormErrors();
                        if (!isEmpty(errors)) {
                            return;
                        }
                        handleChange('keywords', implodeArr(tags));
                        handleNext();
                    }}
                >
                    Next
                </Button>
            </div>
        </Form>
    )
}
