import React, { Fragment, useContext, useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Button, Card, CardBody, Col, Container, Form, FormGroup, Label, Progress, Row } from 'reactstrap';
import logoWhite from '../assets/images/logo/logo.png';
import logoDark from '../assets/images/logo/logo_dark.png';
// import completedLogo from '../assets/images/logo/allCompleted.png';
import completedLogo from '../assets/images/logo/allCompleted.jpg';
import logoImg from "../assets/images/logo/logoImg.png";
import { ToastContainer, toast } from "react-toastify";
import { H4, P } from '../AbstractElements';
import { getUserCompany, setUpPhone } from '../api/AuthIndex';
import ApiContext from '../ApiContext';
import { Spinner } from 'react-bootstrap';
import { Color } from '../helpers/colors';
import { useDispatch } from 'react-redux';
import { ActionTypes } from '../Store/redux-store/ActionTypes';

const Completed = ({ logoClassMain }) => {
    const { setAllCompanies } = useContext(ApiContext)
    const navigate = useNavigate()
    const theme = localStorage.getItem("mix_background_layout")
    const userInfo = JSON.parse(localStorage.getItem("register"))
    console.log("🚀 ~ Completed ~ userInfo:", userInfo)
    const company_id = JSON.parse(localStorage.getItem("getCompanyId"))
    const setupPhoneData = JSON.parse(localStorage.getItem('phoneSetupData'));
    console.log("🚀 ~ Completed ~ setupPhoneData:", setupPhoneData)

    const location = useLocation()

    const dispatch = useDispatch()

    const [loading, setLoading] = useState();
    const [companies, setCompanies] = useState([])

    const [progress, setProgress] = useState(0);
    const [setupText, setSetupText] = useState("We are setting up your company");

    useEffect(() => {
        incrementProgress(25, () => {
            handleHaveTwilioNumber();
        });
    }, []);

    const incrementProgress = (target, callback) => {
        const interval = setInterval(() => {
            setProgress((prev) => {
                if (prev >= target) {
                    clearInterval(interval);
                    if (callback) callback();
                    if (target === 100) getUserCompanyInfo();
                    return prev;
                }
                return prev + 1;
            });
        }, 100); // Adjust time interval as needed
    };


    let getUserCompanyInfo = async () => {
        setLoading(true)
        try {
            let res = await getUserCompany(userInfo?.token, company_id);
            dispatch({ type: ActionTypes.FETCH_USER_COMPLETE_COMPANY, payload: { company_id: company_id } });
            localStorage.setItem("companies", JSON.stringify(res.Company))
            localStorage.setItem("companyStatus", JSON.stringify(res.companyStatus))
            setCompanies(res.Company)
            setAllCompanies(res.Company)

            localStorage.setItem("Name", userInfo.user.name);
            localStorage.setItem("token", userInfo.token);
            localStorage.setItem("auth0_profile", JSON.stringify(userInfo.user));
            localStorage.setItem("login", JSON.stringify(true));
            localStorage.setItem("authenticated", JSON.stringify(true));
            localStorage.setItem("tokken", userInfo.token);
            localStorage.removeItem("botManualData");

            setTimeout(() => {
            if (companies.length === 1) {
                console.log("IF Comdition");
                navigate(`${process.env.PUBLIC_URL}/app/dashboard`);
            } else {
                console.log("ELSE IF Comdition");
                navigate(`${process.env.PUBLIC_URL}/app/companies`);
            }
            }, 1500);
        } catch (error) {
            console.log("🚀 ~ getUserCompanyInfo ~ error:", error)
        }
    }

    const handleHaveTwilioNumber = async () => {
        try {
            const formData = new FormData();
            formData.append("provider", setupPhoneData?.provider);
            formData.append("phone_number", setupPhoneData?.phone_number);
            formData.append("provider_keys", setupPhoneData?.provider_keys);
            formData.append("bot_name", setupPhoneData?.bot_name);
            formData.append("company_id", setupPhoneData?.company_id);
            formData.append("bot_id", setupPhoneData?.bot_id);

            const res = await setUpPhone(userInfo.token, formData);
            if (res) {
                setSetupText("We are setting up your phone number");
                incrementProgress(75, () => {
                    setSetupText("We are setting up your AI Assistant");
                    incrementProgress(100);
                });
            }
        } catch (error) {
            setLoading(false);
        }
    };

    return (
        <Fragment>
            <div
                style={{
                    backgroundImage: `url(${require('../assets/images/login/dottedBackImg.jpg')})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    display: 'block',
                    minHeight: '100vh',
                }}>
                {/* <Container className='p-0 login-page' fluid={true}>
                    <Row className='m-0'>
                        <Link style={{ marginTop: '1%' }} className={`logo ${logoClassMain ? logoClassMain : ''}`} to={process.env.PUBLIC_URL}>
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', paddingTop: '10px 0px 10px 0px' }}>
                                <img className='for-light' src={logoWhite} alt='loginpage' style={{ width: '20%' }} />
                                <img className='for-dark' src={logoDark} alt='loginpage' style={{ width: '20%' }} />
                            </div>
                        </Link>
                        <Col className='p-0'>
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    marginTop: '2%',
                                }}>
                                <Card style={{ width: '500px', padding: '0px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <CardBody style={{ padding: '0px' }}>

                                        <div>
                                            <Row>
                                                <Col sm='12' style={{ height: '600px', backgroundColor: 'white', borderRadius: '10px' }}>
                                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '600px', }}>
                                                        <div>
                                                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                                <img src={completedLogo} alt='loginpage' style={{ width: '80%' }} />
                                                            </div>
                                                            <H4 attrH4={{ style: { textAlign: 'center', fontSize: '16px' } }}>Thrilled to have you!
                                                                <span style={{
                                                                    color: Color.PRIMARY,
                                                                    maxWidth: '180px',
                                                                    display: 'inline-block',
                                                                    whiteSpace: 'nowrap',
                                                                    overflow: 'hidden',
                                                                    textOverflow: 'ellipsis',
                                                                    marginBottom: '-5px',
                                                                    marginLeft: '5px'
                                                                }}>
                                                                    {userInfo?.user?.name}
                                                                </span>
                                                            </H4>
                                                            <P attrPara={{ style: { textAlign: 'center', color: '#3C3C3C', fontSize: '14px' } }}>Fluten's AI Assistant ensures you will never miss a phone call again.</P>
                                                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                                {loading ?
                                                                    <Button disabled color='primary' style={{ width: '70%', fontSize: '16px' }}>
                                                                        <Spinner
                                                                            as="span"
                                                                            animation="grow"
                                                                            size="sm"
                                                                            role="status"
                                                                            aria-hidden="true"
                                                                        />
                                                                        Loading...
                                                                    </Button>
                                                                    :
                                                                    <Button color='primary' style={{ width: '70%', fontSize: '16px' }} onClick={getUserCompanyInfo} >Go To Dashboard</Button>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                    </CardBody>
                                </Card>
                            </div>
                        </Col>
                    </Row>
                </Container> */}
                <Container className="p-0" fluid>
                    <Row className="m-0" style={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Col md="12" className="text-center">
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <div style={{ backgroundColor: 'white', padding: '20px 40px', borderRadius: '10px', width:'400px',}}>
                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '20px' }}>
                                        <img src={logoImg} alt="setup-logo" style={{ width: '100px', height: '100px' }} />
                                    </div>

                                    <h4 style={{ color: '#3C3C3C', fontSize: '16px', marginBottom: '10px', fontWeight:'500' }}>
                                        {setupText}
                                    </h4>

                                    <div style={{ width: '100%', display:'flex', alignItems:'center', justifyContent:'center',marginBottom: '10px'}}>
                                        <Progress value={progress} style={{ height: '8px', backgroundColor: '#EDEDED', width:'400px' }} color="danger" />
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
                <ToastContainer />
            </div>
        </Fragment >
    )
}

export default Completed