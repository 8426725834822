import React, { Fragment, useState, useEffect, useRef } from 'react'
import {
    Button, Card, CardBody, Col, Container, Progress, Row, Accordion,
    AccordionBody,
    AccordionHeader,
    AccordionItem,
} from 'reactstrap';
import { H5, Image, H4, H6 } from '../../AbstractElements';
import SocialData from '../../Components/Application/Users/UsersCards/SocialData';
import SocialLinks from '../../Components/Application/Users/UsersCards/SocialLinks';
import moment from "moment";
import { getChildCallsDetail, getChildCallsStatusSummary, getColorList, getDepartmentStaffSumry, personWiseChildDetail } from '../../api/AuthIndex';
import { Modal, Spinner } from 'react-bootstrap';
import handle_change from 'knob/handle_change';
import { formatMobileNumber } from '../../Constant/GlobalFunctions';
import DataTable from 'react-data-table-component';
import "./styles.css"
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import '../ManageAssistant/ManageAssistant.css'

import { FaChevronDown, FaChevronUp, FaChevronRight } from "react-icons/fa";
import NoDataFound from '../NoDataFound/NoDataFound';
import { debounceTime } from '../../helpers/constants';

const DepartmentCalls = () => {
    let getCompanyInfo = JSON.parse(localStorage.getItem('companyData'))
    const theme = localStorage.getItem("mix_background_layout")
    let token = localStorage?.getItem('token')
    let getUserData = JSON.parse(localStorage.getItem('auth0_profile'))
    const startOfWeek = moment().startOf('week').format('MM/DD/YY');
    const endOfWeek = moment().endOf('week').format('MM/DD/YY');
    const [dateRange, setDateRange] = useState({ startDate: startOfWeek, endDate: endOfWeek });
    const [departmentData, setDepartmentData] = useState([])
    const [color, setColor] = useState([])
    const [departmentDetailData, setDepartmentDetailData] = useState()
    const [departDetail, setDepartDetail] = useState(false)
    const [loading, setLoading] = useState({});
    const [departmentStaff, setDepartmentStaff] = useState([])
    const [personDetailRecoad, setPersonDetailRecoad] = useState([])
    const [staffReportModal, setStaffReportModal] = useState(false);
    const [personWiseTable, setPersonWiseTable] = useState();
    const [staffData, setStaffData] = useState();
    const [loader, setLoader] = useState(true);
    const [spinner, setSpinner] = useState(false);
    const [openSection, setOpenSection] = useState(null);

    const isInitialRender = useRef(true);


    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            childSummry();
        }, debounceTime);

        return () => clearTimeout(delayDebounceFn);
    }, [dateRange])

    useEffect(() => {
        handleColorList();
    }, [])


    const handleColorList = async () => {
        let res = await getColorList(token);
        setColor(res.colors)
    }

    const handleEventDate = async (event, picker) => {
        try {
            const newDateRange = {
                startDate: picker ? picker?.startDate?.format('MM/DD/YY') : moment(dateRange.startDate).format('MM/DD/YY'),
                endDate: picker ? picker?.endDate?.format('MM/DD/YY') : moment(dateRange.endDate).format('MM/DD/YY'),
            }
            setDateRange(newDateRange);
        } catch (error) {
            console.log("Error in handleEventDate:", error);
        }
    };

    const childSummry = async () => {
        setLoader(true)
        try {
            // YYYY-MM-DD
            const formData = new FormData();
            formData.append("company_id", getCompanyInfo?.id);
            formData.append("start_date", moment(dateRange.startDate).format("YYYY-MM-DD"));
            formData.append("end_date", moment(dateRange.endDate).format("YYYY-MM-DD"));
            let res2 = await getChildCallsStatusSummary(token, formData);
            const processRecords = (records) => {
                return Object.keys(records).map(department => {
                    return {
                        department,
                        records: records[department].flatMap(entryList => entryList)
                    };
                });
            };
            const processedRecords = processRecords(res2.records);
            setDepartmentData(processedRecords)
            setLoader(false)
        } catch (error) {
            console.log("🚀 ~ childSummry ~ error:", error)
            setLoader(false)
        }

    }

    const personDetailRecoadData = async (staff) => {
        setStaffData(staff)
        const formData = new FormData();
        formData.append("company_id", getCompanyInfo?.id);
        formData.append("start_date", moment(dateRange.startDate).format("YYYY-MM-DD"));
        formData.append("end_date", moment(dateRange.endDate).format("YYYY-MM-DD"));
        formData.append("staff_id", staff.staff_id);
        formData.append("department_id", staff.department_id);
        const res = await personWiseChildDetail(token, formData);
        setPersonWiseTable(res.records.map((item) => {
            return {
                callDate: item.call_date,
                phone: item.customer_number,
                status: item.status,
                // person:item.trf_person,
            }
        }))
        setStaffReportModal(true);
        if (res) {
            setLoading(false)
        }
    }

    const getInitials = (name) => {
        return name?.split(' ')?.map(word => word[0])?.join('');
    };

    const getColor = (index, colors) => {
        return colors[index % colors?.length]?.hex;
    };

    const getDepartDetail = async (data) => {
        let id = data?.completed?.departID
        setLoading(true);
        setDepartDetail(true)
        try {
            const formData = new FormData();
            formData.append("company_id", getCompanyInfo?.id);
            formData.append("start_date", moment(dateRange.startDate).format("YYYY-MM-DD"));
            formData.append("end_date", moment(dateRange.endDate).format("YYYY-MM-DD"));
            formData.append("department_id", id);
            const res = await getChildCallsDetail(token, formData);
            console.log("🚀 ~ getDepartDetail ~ res:", res)
            setDepartmentDetailData(res.records.map((item) => {
                return {
                    date: item.call_date,
                    name: item?.department_name,
                    departmentId: item?.department_id,
                    number: item?.customer_number,
                    attName: item.transferred_to,
                    status: item.status
                }
            }))
            if (res) {
                setLoading(false)
            }
        } catch (error) {
            setLoading(false);
            setDepartmentDetailData(null)
            console.log("🚀 ~ getDepartDetail ~ error:", error)
        }
    };

    const tableColumns = [
        {
            name: 'Date',
            sortable: false,
            center: false,
            width: '140px',
            cell: row => {
                return (
                    <div>
                        <p style={{ fontSize: '10px', lineHeight: 1, marginLeft: '15px' }}>{moment(row.date).format('MM-DD-YY HH:MM a')}</p>
                    </div>
                )
            }
        },
        {
            name: 'Attendant',
            sortable: false,
            center: false,
            width: '95px',
            cell: row => {
                return (
                    <p style={{ fontSize: '9px', lineHeight: 1, textAlign: 'center', marginLeft: '15px' }}>{row.attName}</p>
                )
            }
        },
        {
            name: 'Phone No.',
            sortable: false,
            center: false,
            width: '110px',
            cell: row => {
                return (
                    <div style={{ width: '130px', marginLeft: '15px'}}>
                        <p style={{ fontSize: '9px', lineHeight: 1 }}>{formatMobileNumber(row.number)}</p>
                    </div>
                )
            }
        },
        {
            name: 'Caller Status',
            sortable: false,
            center: false,
            cell: row => {
                return (
                    <div style={{ width: '80px', marginLeft: '5px' }}>
                        <p style={{ fontSize: '10px', lineHeight: 1, textTransform: 'capitalize', textAlign: 'left' }}>{row.status}</p>
                    </div>
                )
            }
        },
    ];

    const [open, setOpen] = useState('1');
    // const toggle = (id) => {
    //     if (open === id) {
    //         setOpen();
    //     } else {
    //         setOpen(id);
    //     }
    // };

    const toggle = (sectionId) => {
        if (openSection === sectionId) {
            setOpenSection(null); // Close the section if it's already open
        } else {
            setOpenSection(sectionId); // Open the clicked section
        }
    };


    const sumryDetail = async (data) => {
        console.log("🚀 ~ sumryDetail ~ data:", data)
        setSpinner(true)
        try {
            setDepartmentStaff([])
            let department_id = data?.records[0]?.department_id;
            if (department_id === undefined) {
            } else {
                const formData = new FormData();
                formData.append("company_id", getCompanyInfo?.id);
                formData.append("start_date", moment(dateRange.startDate).format("YYYY-MM-DD"));
                formData.append("end_date", moment(dateRange.endDate).format("YYYY-MM-DD"));
                formData.append("department_id", department_id);
                const res = await getDepartmentStaffSumry(token, formData);
                setDepartmentStaff(res?.records)
            }
            setSpinner(false)
        } catch (error) {
            console.log("🚀 ~ sumryDetail ~ error:", error)
            setSpinner(false)
        }
    }


    const tableColumns3 = [
        {
            name: 'Call Date',
            sortable: false,
            center: false,
            width: '160px',
            cell: row => {
                console.log("🚀 ~ ChildCallReports ~ row:", row)
                const date = moment(row?.callDate).format('MMM-DD-YYYY HH:MM a');
                const formattedDate = moment(date, 'MMM-DD-YYYY HH:mm').format('MM-DD-YY hh:mm a');
                return (
                    <>
                        <p style={{ fontSize: '10px', lineHeight: 1, marginLeft: '15px' }}>{formattedDate}</p>
                    </>
                )
            },
        },
        {
            name: 'Customer Phone',
            // selector: row => row['transferred_to'],
            sortable: false,
            center: true,
            width: '170px',
            cell: row => {
                return (
                    <>
                        <p style={{ fontSize: '10px', lineHeight: 1, marginLeft: '15px' }}>{formatMobileNumber(row.phone)}</p>
                    </>
                )
            },
        },
        {
            name: 'Status',
            sortable: false,
            center: true,
            cell: row => {
                return (

                    <p style={{ lineHeight: 1, textTransform: 'capitalize', backgroundColor: row.status == 'no-answer' ? '#FF4560' : row.status == 'completed' ? '#0adb92' : row.status == 'busy' ? '#5979D4' : '', padding: '5px', borderRadius: '5px', fontSize: '10px', color: 'white', marginLeft: '15px' }}>{row.status}</p>

                )
            },
        },
    ];



    return (
        <Fragment>
            <Container fluid={true}>
                {loader ? (
                    <Row style={{ paddingTop: '2%' }}>
                        <Col sm='12'>
                            <Card >
                                <CardBody style={{ padding: '10px' }}>
                                    <SkeletonTheme baseColor={theme == 'dark-only' ? "#202020" : ""} highlightColor={theme == 'dark-only' ? "#444" : ""}>
                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                            <Skeleton height={30} style={{ width: '150px' }} />
                                            <Skeleton height={30} style={{ width: '200px' }} />
                                        </div>
                                    </SkeletonTheme>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col sm='4'>
                            <Card style={{ height: '200px' }}>
                                <CardBody style={{ padding: '10px' }}>
                                    <SkeletonTheme baseColor={theme == 'dark-only' ? "#202020" : ""} highlightColor={theme == 'dark-only' ? "#444" : ""}>
                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                            <Skeleton height={30} style={{ width: '150px' }} />
                                        </div>
                                        <div style={{ marginTop: '10px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                            <Skeleton height={30} count={2} width={'150px'} />
                                            <Skeleton height={30} count={2} width={'150px'} />
                                        </div>
                                        <div style={{ marginTop: '10px' }}>
                                            <Skeleton height={30} count={1} />
                                        </div>
                                    </SkeletonTheme>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col sm='4'>
                            <Card style={{ height: '200px' }}>
                                <CardBody style={{ padding: '10px' }}>
                                    <SkeletonTheme baseColor={theme == 'dark-only' ? "#202020" : ""} highlightColor={theme == 'dark-only' ? "#444" : ""}>
                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                            <Skeleton height={30} style={{ width: '150px' }} />
                                        </div>
                                        <div style={{ marginTop: '10px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                            <Skeleton height={30} count={2} width={'150px'} />
                                            <Skeleton height={30} count={2} width={'150px'} />
                                        </div>
                                        <div style={{ marginTop: '10px' }}>
                                            <Skeleton height={30} count={1} />
                                        </div>
                                    </SkeletonTheme>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col sm='4'>
                            <Card style={{ height: '200px' }}>
                                <CardBody style={{ padding: '10px' }}>
                                    <SkeletonTheme baseColor={theme == 'dark-only' ? "#202020" : ""} highlightColor={theme == 'dark-only' ? "#444" : ""}>
                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                            <Skeleton height={30} style={{ width: '150px' }} />
                                        </div>
                                        <div style={{ marginTop: '10px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                            <Skeleton height={30} count={2} width={'150px'} />
                                            <Skeleton height={30} count={2} width={'150px'} />
                                        </div>
                                        <div style={{ marginTop: '10px' }}>
                                            <Skeleton height={30} count={1} />
                                        </div>
                                    </SkeletonTheme>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col sm='4'>
                            <Card style={{ height: '200px' }}>
                                <CardBody style={{ padding: '10px' }}>
                                    <SkeletonTheme baseColor={theme == 'dark-only' ? "#202020" : ""} highlightColor={theme == 'dark-only' ? "#444" : ""}>
                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                            <Skeleton height={30} style={{ width: '150px' }} />
                                        </div>
                                        <div style={{ marginTop: '10px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                            <Skeleton height={30} count={2} width={'150px'} />
                                            <Skeleton height={30} count={2} width={'150px'} />
                                        </div>
                                        <div style={{ marginTop: '10px' }}>
                                            <Skeleton height={30} count={1} />
                                        </div>
                                    </SkeletonTheme>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col sm='4'>
                            <Card style={{ height: '200px' }}>
                                <CardBody style={{ padding: '10px' }}>
                                    <SkeletonTheme baseColor={theme == 'dark-only' ? "#202020" : ""} highlightColor={theme == 'dark-only' ? "#444" : ""}>
                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                            <Skeleton height={30} style={{ width: '150px' }} />
                                        </div>
                                        <div style={{ marginTop: '10px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                            <Skeleton height={30} count={2} width={'150px'} />
                                            <Skeleton height={30} count={2} width={'150px'} />
                                        </div>
                                        <div style={{ marginTop: '10px' }}>
                                            <Skeleton height={30} count={1} />
                                        </div>
                                    </SkeletonTheme>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                ) : (
                    <>

                        {departmentData?.length > 0 ?
                            (
                                <Fragment>
                                    <Row style={{ paddingTop: '2%' }}>
                                        <Col sm='12'>

                                            <Row>
                                                <Col sm='12'>
                                                    <Card>
                                                        <CardBody className='cusCardP'>
                                                            <Row>
                                                                <Col md="8">
                                                                    <H4>Department</H4>
                                                                </Col>
                                                                <Col md="4" style={{ textAlign: "right" }}>
                                                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'end' }}>
                                                                        <DateRangePicker
                                                                            initialSettings={{ startDate: dateRange.startDate, endDate: dateRange.endDate }}
                                                                            onApply={handleEventDate}
                                                                        >
                                                                            <input
                                                                                type="text"
                                                                                value={`${dateRange.startDate} - ${dateRange.endDate}`}
                                                                                readOnly
                                                                                className="form-control"
                                                                                style={{ width: '210px' }}
                                                                            />
                                                                        </DateRangePicker>
                                                                    </div>
                                                                </Col>
                                                            </Row>

                                                        </CardBody>
                                                    </Card>
                                                </Col>
                                            </Row>
                                        </Col>

                                        {departmentData?.map((data, index) => {
                                            const initials = getInitials(data.department);
                                            const backgroundColor = getColor(index, color);
                                            const filteredRecords = data?.records?.filter(item => {
                                                return item.status === 'completed' || item.status === 'busy' || item.status === 'no-answer';
                                            });

                                            let completed = {
                                                status: "completed",
                                                total_calls: 0,
                                                this_status: 0
                                            };

                                            let noAnswer = {
                                                status: "no-answer",
                                                total_calls: 0,
                                                this_status: 0
                                            };

                                            let busy = {
                                                status: "busy",
                                                total_calls: 0,
                                                this_status: 0
                                            };

                                            let failed = {
                                                status: "failed",
                                                total_calls: 0,
                                                this_status: 0
                                            };

                                            filteredRecords.forEach(item => {
                                                let allCalls = 0;
                                                allCalls += item.total_calls
                                                console.log('filterData:', item);
                                                if (item.status === "completed") {
                                                    completed = {
                                                        status: item.status,
                                                        total_calls: item.total_calls,
                                                        this_status: item.this_status,
                                                        average: item.average,
                                                        sumOfCalls: allCalls,
                                                        departID: item.department_id
                                                    };
                                                }
                                                if (item.status === "no-answer") {
                                                    noAnswer = {
                                                        status: item.status,
                                                        total_calls: item.total_calls,
                                                        this_status: item.this_status,
                                                        average: item.average,
                                                    };
                                                }

                                                if (item.status === "busy") {
                                                    busy = {
                                                        status: item.status,
                                                        total_calls: item.total_calls,
                                                        this_status: item.this_status,
                                                        average: item.average
                                                    };
                                                }

                                                if (item.status === "failed") {
                                                    failed = {
                                                        status: item.status,
                                                        total_calls: item.total_calls,
                                                        this_status: item.this_status,
                                                        average: item.average
                                                    };
                                                }

                                            });

                                            return (
                                                <Col key={index} xl='4' sm='6' xxl='4' className='col-ed-4 box-col-4'>
                                                    <Card style={{ border: '1px solid #EFEFEF' }}>
                                                        <CardBody className='cusCardP'>
                                                            <Row>
                                                                <Col xs='10' sm='10' md="10" >
                                                                    <H5>{data?.department}</H5>
                                                                </Col>
                                                                <Col xs='2' sm='2' md="2" style={{ textAlign: "center" }}>
                                                                    <div style={{ cursor: 'pointer' }} onClick={() => getDepartDetail({ completed, noAnswer, busy })}>
                                                                        <i class="fa-solid fa-bars"></i>
                                                                    </div>
                                                                </Col>
                                                            </Row>

                                                            <div className='social-details'>
                                                                <>
                                                                    <Row>
                                                                        <Col xs='6' sm='6' md="6" >
                                                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                                <div className='noAnswer'>
                                                                                    No - Answer
                                                                                </div>
                                                                                <div style={{ marginTop: '10px' }}>
                                                                                    <H6>{noAnswer?.this_status}</H6>
                                                                                </div>
                                                                            </div>
                                                                        </Col>
                                                                        <Col xs='6' sm='6' md="6">
                                                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                                <div className='success'>
                                                                                    Success
                                                                                </div>
                                                                                <div style={{ marginTop: '10px' }}>
                                                                                    <H6> {completed?.this_status}</H6>
                                                                                </div>
                                                                            </div>
                                                                        </Col>

                                                                        <Col xs='6' sm='6' md="6" className='mt-1'>
                                                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                                <div className='busy'>
                                                                                    Busy
                                                                                </div>
                                                                                <div style={{ marginTop: '10px' }}>
                                                                                    <H6>{busy?.this_status}</H6>
                                                                                </div>
                                                                            </div>
                                                                        </Col>

                                                                        <Col xs='6' sm='6' md="6" className='mt-1'>
                                                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                                <div className='failed'>
                                                                                    Failed
                                                                                </div>
                                                                                <div style={{ marginTop: '10px' }}>
                                                                                    <H6> {failed?.this_status}</H6>
                                                                                </div>
                                                                            </div>
                                                                        </Col>
                                                                    </Row>

                                                                    <br />

                                                                    <div style={{ border: 'none' }}>
                                                                        <div
                                                                            className="accordion-header-red"
                                                                            style={{ backgroundColor: theme === 'dark-only' ? '#262932' : 'white' }}

                                                                        >
                                                                            {openSection === (data.department + data.index) ? (
                                                                                <FaChevronUp
                                                                                    style={{ cursor: 'pointer' }}
                                                                                    color={theme === 'dark-only' ? 'grey' : 'black'}
                                                                                    onClick={() => {
                                                                                        sumryDetail(data);
                                                                                        toggle(data.department + data.index);
                                                                                        if (departmentStaff && departmentStaff.length === 0) {
                                                                                            setSpinner(true);
                                                                                            setTimeout(() => {
                                                                                                setSpinner(false);
                                                                                            }, 2000);
                                                                                        }
                                                                                    }} />
                                                                            ) : (

                                                                                <FaChevronDown
                                                                                    style={{ cursor: 'pointer' }}
                                                                                    color={theme === 'dark-only' ? 'grey' : 'black'}
                                                                                    onClick={() => {
                                                                                        sumryDetail(data);
                                                                                        toggle(data.department + data.index);
                                                                                        if (departmentStaff && departmentStaff.length === 0) {
                                                                                            setSpinner(true);
                                                                                            setTimeout(() => {
                                                                                                setSpinner(false);
                                                                                            }, 2000);
                                                                                        }
                                                                                    }} />
                                                                            )}


                                                                        </div>
                                                                        {openSection === (data.department + data.index) && (
                                                                            <div>
                                                                                {spinner ? (
                                                                                    <Row >
                                                                                        <Col sm='3'>
                                                                                            <SkeletonTheme baseColor={theme === 'dark-only' ? "#202020" : ""} highlightColor={theme === 'dark-only' ? "#444" : ""}>
                                                                                                <Skeleton height={20} count={2} />
                                                                                            </SkeletonTheme>
                                                                                        </Col>
                                                                                        <Col sm='6'>
                                                                                            <SkeletonTheme baseColor={theme === 'dark-only' ? "#202020" : ""} highlightColor={theme === 'dark-only' ? "#444" : ""}>
                                                                                                <Skeleton height={20} count={2} />
                                                                                            </SkeletonTheme>
                                                                                        </Col>
                                                                                        <Col sm='3'>
                                                                                            <SkeletonTheme baseColor={theme === 'dark-only' ? "#202020" : ""} highlightColor={theme === 'dark-only' ? "#444" : ""}>
                                                                                                <Skeleton height={20} count={2} />
                                                                                            </SkeletonTheme>
                                                                                        </Col>
                                                                                    </Row>
                                                                                ) : (
                                                                                    departmentStaff && departmentStaff.length > 0 ? (
                                                                                        <Row style={{ marginTop: "5px", padding: '5px' }}>
                                                                                            {departmentStaff.map((staff, index) => {
                                                                                                let total_calls = Number(staff.success) + Number(staff.failed);
                                                                                                let answered_calls = Number(staff.success) * 100;
                                                                                                let percentage = answered_calls / total_calls;
                                                                                                return (
                                                                                                    <div className='userCard' style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '15px' }} key={index}>
                                                                                                        <div style={{ display: 'flex' }}>
                                                                                                            <div className='userCircle'>
                                                                                                                <div style={{ fontSize: '20px', fontWeight: '500', color: '#FFAA05' }}>{percentage ? `${percentage.toFixed(1)}%` : '0%'}</div>
                                                                                                            </div>
                                                                                                            <div className='userNameC' style={{ marginLeft: '15px' }}>
                                                                                                                <div style={{ marginTop: "-5px", fontWeight: '500', color: theme === 'dark-only' ? 'grey' : 'black' }}>{staff.trf_person}</div>
                                                                                                                <div style={{ marginTop: "3px", color: 'grey' }}>{staff.trf_to}</div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <div style={{ display: 'flex', width: '15%', justifyContent: 'space-between', alignItems: 'center' }}>
                                                                                                            <div className='userNameC'>
                                                                                                                <div style={{ marginTop: "-5px", color: "#54BA4A" }}>{staff.success}</div>
                                                                                                                <div style={{ marginTop: "3px", color: "#F73164" }}>{staff.failed}</div>
                                                                                                            </div>
                                                                                                            <div className='userNameC'>
                                                                                                                <FaChevronRight style={{ cursor: 'pointer' }} color={theme === 'dark-only' ? 'grey' : 'black'} onClick={() => { personDetailRecoadData(staff) }} />
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                );
                                                                                            })}
                                                                                        </Row>
                                                                                    ) : (
                                                                                        <div style={{ textAlign: 'center', color: theme === 'dark-only' ? 'grey' : 'black' }}>No data found</div>
                                                                                    )
                                                                                )}
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                </>
                                                            </div>
                                                        </CardBody>
                                                    </Card>
                                                </Col>
                                            );
                                        })}
                                    </Row>
                                </Fragment>
                            ) : (
                                <Row >
                                    <Col sm='12' style={{ marginTop: '4%', height: '400px' }}>
                                        <Card style={{ height: '600px' }}>
                                            <NoDataFound message={'To generate data on this page, configure one or more departments in the Phone Directory tab.'} />
                                            {/* <Image attrImage={{ className: 'img-fluid m-auto', src: errorImg, alt: '' }} /> */}
                                        </Card>
                                    </Col>
                                </Row>
                            )
                        }
                    </>

                )
                }

            </Container >

            <Modal
                show={departDetail}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                onHide={() => setDepartDetail(false)}
            >
                <Modal.Header closeButton>
                    <H4> Department Detail</H4>
                </Modal.Header>
                <Modal.Body>
                    <div style={{ justifyContent: 'center' }}>
                        {loading ? (
                            <div style={{ display: 'flex', justifyContent: 'center', height: '400px', alignItems: 'center' }}>
                                <Spinner
                                    as="span"
                                    animation="grow"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                />
                            </div>
                        ) : (
                            <>
                                <div style={{
                                    height: '400px',
                                    overflow: 'scroll',
                                    scrollbarWidth: 'none',
                                    msOverflowStyle: 'none',
                                }}>
                                    <DataTable
                                        data={departmentDetailData || []}
                                        columns={tableColumns}
                                        striped={true}
                                        center={true}
                                        pagination
                                        paginationPerPage={5}
                                        noDataComponent={
                                            <div style={{ padding: '10px', color: theme === 'dark-only' ? '#fff' : '#000', backgroundColor: theme === 'dark-only' ? '#262932' : '#fff', textAlign: 'center', width: '100%', border: 'none' }}>
                                                There are no records to display
                                            </div>
                                        }
                                    />
                                </div>
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '20px' }}>
                                    <Button style={{ width: '150px', height: '43px' }} onClick={() => setDepartDetail(false)}>Close</Button>
                                </div>
                            </>
                        )}
                    </div>
                </Modal.Body>
            </Modal>


            <Modal
                show={staffReportModal}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                onHide={() => setStaffReportModal(false)}
            >
                <Modal.Header closeButton>
                    <H4>{staffData?.trf_person}</H4>
                    {/* <H4> Staff Level Call Detail</H4> */}
                </Modal.Header>
                <Modal.Body>
                    <div style={{ justifyContent: 'center' }}>
                        {loading ? (
                            <div style={{ display: 'flex', justifyContent: 'center', height: '400px', alignItems: 'center' }}>
                                <Spinner
                                    as="span"
                                    animation="grow"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                />
                            </div>
                        ) : (
                            <>
                                <div style={{ height: '450px', overflow: 'scroll', scrollbarWidth: 'none', msOverflowStyle: 'none' }}>
                                    <DataTable
                                        data={personWiseTable || []}
                                        columns={tableColumns3}
                                        striped={true}
                                        center={true}
                                        pagination
                                        paginationPerPage={5}
                                    />
                                </div>
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '20px' }}>
                                    <Button style={{ width: '150px', height: '43px' }} onClick={() => setStaffReportModal(false)}>Close</Button>
                                </div>
                            </>
                        )}
                    </div>
                </Modal.Body>
            </Modal>




        </Fragment >
    )
}

export default DepartmentCalls