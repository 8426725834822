import {H4} from "../../../AbstractElements";
import {
    AI_ASSISTANT_RIGHT_SIDEBAR,
    BACKGROUND_VOICE_OPTIONS,
    FEMALE_VOICES,
    GENDER_ARR,
    LANGUAGES_MAP,
    MALE_VOICES,
    PROVIDERS_ARR,
    SHORT_GENDER
} from "../../../helpers/constants";
import {useState} from "react";
import {IoIosHelpCircleOutline} from 'react-icons/io';
import {IoPauseCircleOutline, IoPlayCircleOutline} from "react-icons/io5";
import {selectSelectedAssistant} from "../../../Store/modules/assistant/assistantSelectors";
import {useDispatch, useSelector} from 'react-redux';
import {
    Button,
    CardBody,
    Col,
    FormFeedback,
    FormGroup,
    Input,
    Label,
    Popover,
    PopoverBody,
    Row,
    Spinner
} from 'reactstrap';
import Select from 'react-select';
import {FaChevronDown, FaChevronUp, FaEye, FaEyeSlash} from 'react-icons/fa';
import {formatPhoneNumber} from "../../../Constant/GlobalFunctions";
import {ActionTypes} from "../../../Store/redux-store/ActionTypes";
import {isEmpty} from "../../../helpers/utils";
import {botVoice, getGenderByShortName, getLangByLangName} from "../../../helpers/common";
import {genderRadioButtonsStyles} from "./genderRadioButtonStyles";
import LanguageSelect from "../LanguageSelect";
import {explodeString, implodeArr} from "../../../helpers/StringHelper";
import {selectBotVoice, selectBotVoiceLoading} from "../../../Store/modules/precastBots/botVoice/botVoiceSelectors";
import {selectUpdatingAssistant} from "../../../Store/modules/assistant/update/assistantSelectors";
import {getCurrentCompany} from "../../../helpers/LocalStorageHelper";


export const EditAssistant = () => {
    const dispatch = useDispatch();
    const botData = useSelector(selectSelectedAssistant);
    const audioSrc = useSelector(selectBotVoice);
    const isBotVoiceLoading = useSelector(selectBotVoiceLoading);
    const isUpdatingAssistant = useSelector(selectUpdatingAssistant);

    const [showAccountSID, setShowAccountSID] = useState(false);
    const [showAuthToken, setShowAuthToken] = useState(false);
    const [isCollapsed, setIsCollapsed] = useState(false);
    const [isPlaying, setIsPlaying] = useState(false);
    const [audioElement, setAudioElement] = useState(null);
    const [tags, setTags] = useState(explodeString(botData?.keywords));

    const [fieldErrors, setFieldErrors] = useState({
        background_sound: '',
        bot_id: '',
        bot_name: '',
        end_call_message: '',
        end_call_phrases: '',
        first_message: '',
        gender: '',
        keywords: '',
        language: '',
        phone_number: '',
        picture: '',
        provider: '',
        provider_keys: '',
        purpose: '',
        prompt: '',
        voice_id: '',
    });

    const [fieldVals, setFieldVals] = useState({
        background_sound: botData?.background_sound,
        bot_id: botData?.bot_id,
        bot_name: botData?.bot_name,
        end_call_message: botData?.end_call_message,
        end_call_phrases: botData?.end_call_phrases,
        first_message: botData?.first_message,
        gender: getGenderByShortName(botData?.gender),
        // keywords: botData?.keywords,
        keywords: '',
        language: getLangByLangName(botData?.language),
        phone_number: botData?.phone_number,
        picture: botData?.picture,
        provider: botData?.provider,
        provider_keys: botData?.provider_keys,
        provider_sid: explodeString(botData?.provider_keys)?.[0],
        provider_auth_token: explodeString(botData?.provider_keys)?.[1],
        purpose: botData?.purpose,
        prompt: botData?.prompt,
        voice: botVoice(botData?.gender, botData?.voice_id)
    });

    const handleKeyPress = (e) => {
        if (e.key === 'Enter' || e.key === ',') {
            const input = fieldVals?.keywords.trim().replace(/,+$/, '').toLowerCase();
            if (input) {
                const isDuplicate = tags.some(tag => tag.toLowerCase() === input);
                if (isDuplicate) {
                    setFieldErrors({...fieldErrors, keywords: 'This keyword has already been submitted.'});
                } else {
                    setTags([...tags, input]);
                }
                setFieldVals({...fieldVals, keywords: ''});
            }
            e.preventDefault();
        }
    };

    function focusFirstError(errors) {
        for (const field in errors) {
            if (errors.hasOwnProperty(field)) {
                const element = document.getElementsByName(field)[0];
                if (element) {
                    element.focus();
                    element.scrollIntoView({behavior: "smooth", block: "start"});
                    setTimeout(() => {
                        window.scrollTo({
                            top: window.scrollY - 140,
                            behavior: "smooth"
                        });
                        console.log(window.scrollY);
                    }, 500);

                    break;
                }
            }
        }
    }

    const getFormErrors = () => {
        let errors = {};

        if (isEmpty(fieldVals?.bot_name.trim())) {
            errors.bot_name = 'Name is required.';
        }

        if (isEmpty(fieldVals.language)) {
            errors.language = 'Language is required';
        }

        if (isEmpty(fieldVals.first_message.trim())) {
            errors.first_message = 'Welcome Message is required.';
        }

        if (isEmpty(fieldVals.end_call_message)) {
            errors.end_call_message = 'End Call Message is required.';
        }

        if (isEmpty(fieldVals.end_call_phrases)) {
            errors.end_call_phrases = 'End Call Phrases are required.';
        }

        if (isEmpty(fieldVals?.background_sound)) {
            errors.background_sound = 'You must choose one of these options.';
        }

        if (isEmpty(fieldVals?.gender)) {
            errors.gender = 'Gender is required.';
        }

        if (isEmpty(fieldVals?.voice)) {
            errors.voice_id = 'AI Assistant Voice is required.';
        }

        // if (isEmpty(tags)) {
        //     errors.keywords = 'Keywords are required';
        // }

        // if (isEmpty(fieldVals?.keywords)) {
        //     errors.keywords = 'Keywords are required';
        // }

        if (isEmpty(fieldVals?.prompt)) {
            errors.prompt = 'Your AI Personality is required.';
        }

        if (isEmpty(fieldVals?.phone_number)) {
            errors.phone_number = 'Phone number is required.';
        }

        if (isEmpty(fieldVals?.provider_sid)) {
            errors.provider_sid = 'Provider sid is required.';
        }

        if (isEmpty(fieldVals?.provider_auth_token)) {
            errors.provider_auth_token = 'Phone auth token is required.';
        }

        if (!isEmpty(errors.phone_number) || !isEmpty(errors.provider_sid) || !isEmpty(errors.provider_auth_token)) {
            setIsCollapsed(true);
        }

        return errors;
    }

    const handleSave = () => {
        const errors = getFormErrors();
        setFieldErrors(errors);

        if (Object.keys(errors).length > 0) {
            focusFirstError(errors);
            return;
        }

        const company = getCurrentCompany();

        let data = {
            background_sound: fieldVals?.background_sound,
            bot_id: fieldVals?.bot_id,
            bot_name: fieldVals?.bot_name,
            end_call_message: fieldVals?.end_call_message,
            end_call_phrases: fieldVals?.end_call_phrases,
            first_message: fieldVals?.first_message,
            gender: fieldVals?.gender?.value,
            // keywords: fieldVals?.keywords,
            keywords: implodeArr(tags),
            language: fieldVals?.language?.lang_name,
            phone_number: fieldVals?.phone_number,
            provider: fieldVals?.provider,
            provider_keys: `${fieldVals?.provider_sid} ${fieldVals?.provider_auth_token}`,
            purpose: fieldVals?.purpose,
            prompt: fieldVals?.prompt,
            voice_id: fieldVals?.voice?.value,
            company_id: company?.id
        };

        dispatch({
            type: ActionTypes.UPDATE_ASSISTANT,
            payload: {...data}
        });
    };

    const handleLanguageChange = (lang) => {
        setFieldVals({...fieldVals, language: lang});
    };

    const handleChanges = (e) => {
        var {name, value} = e.target;
        var value = (value = value?.trimStart());
        const vals = {}
        if(name === 'gender') {
            setFieldVals({
                ...fieldVals,
                [name]: value,
                'voice': null
            });
        } else {
            setFieldVals({
                ...fieldVals,
                [name]: value,
            });
        }
        setFieldErrors({});
    };


    const [popoverOpen, setPopoverOpen] = useState({
        firstMessage: false,
        endCallWords: false,
        endCallGreetings: false,
        backVoice: false,
        keywords: false,
        addAssistantButton: false
    });

    const handleMouseEnter = (target) => {
        setPopoverOpen(prevState => ({
            ...prevState,
            [target]: true
        }));
    };

    const handleMouseLeave = (target) => {
        setPopoverOpen(prevState => ({
            ...prevState,
            [target]: false
        }));
    };

    const toggleAudioPlay = () => {
        if (isPlaying) {
            audioElement?.pause();
            setIsPlaying(false);
        } else {
            audioElement?.play();
            setIsPlaying(true);
        }
    };

    const removeTag = (indexToRemove) => {
        setTags(tags.filter((_, index) => index !== indexToRemove));
    };


    return (
        <Row style={{padding: 0, marginTop: '1em', marginRight: '0.1em', marginBottom: '1em'}}>
            <Col sm="12">
                <H4>Edit Assistant - <span style={{textTransform: 'capitalize'}}>{fieldVals?.bot_name}</span></H4>
                <Row>
                    <Col sm='6'>
                        <FormGroup>
                            <Label
                                className="col-sm-6 col-form-label"
                                htmlFor="inputEmail3"
                                style={{
                                    fontWeight: '500',
                                    color: 'black'
                                }}>AI Assistant Name</Label>
                            <Col sm="12">
                                <Input
                                    type="text"
                                    id="bot_name"
                                    name={'bot_name'}
                                    value={fieldVals?.bot_name}
                                    onChange={handleChanges}
                                    invalid={fieldErrors?.bot_name}
                                    placeholder="Enter Name"
                                    className={`form-control input-air-primary digits  'dark-theme-field'}`}
                                    style={{backgroundColor: 'white'}}
                                />
                                {fieldErrors?.bot_name && (
                                    <FormFeedback>{fieldErrors?.bot_name}</FormFeedback>
                                )}
                            </Col>
                        </FormGroup>
                    </Col>
                    <Col sm='6'>
                        <FormGroup>
                            <Label
                                className="col-sm-12 col-form-label"
                                htmlFor="language" style={{
                                fontWeight: '500',
                                color: 'black'
                            }}>Language</Label>
                            <Col sm="12">
                                <LanguageSelect
                                    languageMap={LANGUAGES_MAP}
                                    invalid={fieldErrors?.bot_name}
                                    selectedLanguage={fieldVals?.language}
                                    onLanguageChange={handleLanguageChange}
                                />
                                {fieldErrors?.language && (
                                    <FormFeedback>{fieldErrors?.language}</FormFeedback>
                                )}
                            </Col>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col sm='6'>
                        <FormGroup>
                            <Label
                                className="col-sm-12 col-form-label"
                                htmlFor="firstMessage"
                                style={{
                                    fontWeight: '500',
                                    color: 'black'
                                }}>
                                Welcome Message To Customer
                                <IoIosHelpCircleOutline
                                    id="PopoverFirstMessage"
                                    size={20}
                                    style={{
                                        marginLeft: 8,
                                        cursor: 'pointer',
                                        marginBottom: '-5px'
                                    }}
                                    onMouseEnter={() => handleMouseEnter('firstMessage')}
                                    onMouseLeave={() => handleMouseLeave('firstMessage')}
                                />
                                <Popover
                                    placement="top"
                                    isOpen={popoverOpen.firstMessage}
                                    target="PopoverFirstMessage"
                                    toggle={() => {
                                    }}
                                >
                                    <PopoverBody>When a call is connected, the assistant should start with a friendly
                                        greeting, such as, "Hello, this is Amanda speaking on behalf of Fluten.ai. How
                                        can I assist you today?"</PopoverBody>
                                </Popover>
                            </Label>
                            <Col sm="12">
                                <Input
                                    type="textarea"
                                    id="first_message"
                                    name={'first_message'}
                                    value={fieldVals?.first_message}
                                    invalid={fieldErrors?.first_message}
                                    onChange={handleChanges}
                                    placeholder="Hi, how may I help you?"
                                    className={`form-control input-air-primary digits`}
                                    style={{backgroundColor: 'white'}}
                                />
                                {fieldErrors?.first_message && (
                                    <FormFeedback>{fieldErrors?.first_message}</FormFeedback>
                                )}
                            </Col>
                        </FormGroup>
                    </Col>

                    <Col sm={'6'}>
                        <FormGroup>
                            <Label
                                className="col-sm-12 col-form-label"
                                htmlFor="endCallGreetings"
                                style={{
                                    fontWeight: '500',
                                    color: 'black'
                                }}>
                                End Call Message
                                <IoIosHelpCircleOutline
                                    id="PopoverEndCallGreetings"
                                    size={20}
                                    style={{
                                        marginLeft: 8,
                                        cursor: 'pointer',
                                        marginBottom: '-5px'
                                    }}
                                    onMouseEnter={() => handleMouseEnter('endCallGreetings')}
                                    onMouseLeave={() => handleMouseLeave('endCallGreetings')}
                                />
                                <Popover
                                    placement="top"
                                    isOpen={popoverOpen.endCallGreetings}
                                    target="PopoverEndCallGreetings"
                                    toggle={() => {
                                    }}
                                >
                                    <PopoverBody>The assistant will conclude the conversation with a closing message
                                        before ending the call. For instance, the assistant will say, “If you have any
                                        other questions, feel free to call back. Have a wonderful day.
                                        Bye.”</PopoverBody>
                                </Popover>
                            </Label>
                            <Col sm="12">
                                <Input
                                    type="textarea"
                                    id="end_call_message"
                                    name={'end_call_message'}
                                    value={fieldVals.end_call_message}
                                    invalid={fieldErrors.end_call_message}
                                    onChange={handleChanges}
                                    placeholder="Have a nice day."
                                    className={`form-control input-air-primary digits `}
                                    style={{backgroundColor: 'white'}}
                                />
                                {fieldErrors?.end_call_message && (
                                    <FormFeedback>{fieldErrors?.end_call_message}</FormFeedback>
                                )}
                            </Col>
                        </FormGroup>
                    </Col>


                </Row>

                <Row>
                    <Col sm='6'>
                        <FormGroup>
                            <Label
                                className="col-sm-12 col-form-label"
                                htmlFor="endCallWords"
                                style={{
                                    fontWeight: '500',
                                    color: 'black'
                                }}>
                                End Call Phrases
                                <IoIosHelpCircleOutline
                                    id="PopoverEndCallWords"
                                    size={20}
                                    style={{
                                        marginLeft: 8,
                                        cursor: 'pointer',
                                        marginBottom: '-5px'
                                    }}
                                    onMouseEnter={() => handleMouseEnter('endCallWords')}
                                    onMouseLeave={() => handleMouseLeave('endCallWords')}
                                />
                                <Popover
                                    placement="top"
                                    isOpen={popoverOpen.endCallWords}
                                    target="PopoverEndCallWords"
                                    toggle={() => {
                                    }}
                                >
                                    <PopoverBody>The assistant will consider this as a sign of closing the call.
                                        Assistant will speak the 'End call Message' and disconnects the
                                        call</PopoverBody>
                                </Popover>
                            </Label>
                            <Col sm="12">
                                <Input
                                    type="text"
                                    id="name"
                                    name={'end_call_phrases'}
                                    value={fieldVals.end_call_phrases}
                                    invalid={fieldErrors.end_call_phrases}
                                    onChange={handleChanges}
                                    placeholder="Goodbye, Bye."
                                    className={`form-control input-air-primary digits`}
                                    style={{backgroundColor: 'white'}}
                                />
                                {fieldErrors?.end_call_phrases && (
                                    <FormFeedback>{fieldErrors?.end_call_phrases}</FormFeedback>
                                )}
                            </Col>
                        </FormGroup>
                    </Col>
                    <Col sm={'6'}>
                        <FormGroup>
                            <Label
                                className="col-sm-12 col-form-label"
                                htmlFor="backVoice"
                                style={{
                                    fontWeight: '500',
                                    color: 'black'
                                }}>
                                Background Voice
                                <IoIosHelpCircleOutline
                                    id="PopoverBackVoice"
                                    size={20}
                                    style={{
                                        marginLeft: 8,
                                        cursor: 'pointer',
                                        marginBottom: '-5px'
                                    }}
                                    onMouseEnter={() => handleMouseEnter('backVoice')}
                                    onMouseLeave={() => handleMouseLeave('backVoice')}
                                />
                                <Popover
                                    placement="top"
                                    isOpen={popoverOpen.backVoice}
                                    target="PopoverBackVoice"
                                    toggle={() => {
                                    }}
                                >
                                    <PopoverBody>Selecting the "Office" option will allow the caller to experience a
                                        realistic work environment with office background sounds, creating a more
                                        authentic interaction with the assistant.</PopoverBody>
                                </Popover>
                            </Label>
                            <Col sm="12">
                                <Input
                                    type="select"
                                    value={fieldVals.background_sound}
                                    onChange={handleChanges}
                                    invalid={fieldErrors.background_sound}
                                    name="background_sound"
                                    className={`form-control input-air-primary digits`}
                                    style={{backgroundColor: 'white'}}
                                >
                                    {!fieldVals.background_sound &&
                                        <option
                                            value="">Select
                                            Voice</option>}
                                    {BACKGROUND_VOICE_OPTIONS?.map(option => (
                                        <option
                                            key={option}
                                            value={option}>{option}</option>
                                    ))}
                                </Input>
                                {fieldErrors?.background_sound && (
                                    <FormFeedback>{fieldErrors?.background_sound}</FormFeedback>
                                )}
                            </Col>
                        </FormGroup>
                    </Col>
                </Row>

                <Row>
                    <Col sm='6'>
                        <FormGroup>
                            <Label
                                className="col-sm-12 col-form-label"
                                htmlFor="provider" style={{
                                fontWeight: '500',
                                color: 'black',
                            }}>Gender</Label>
                            <div style={{
                                display: 'flex',
                                gap: '2em',
                            }}>
                                    <div   style={{width: '100%'}}>
                                        <Select
                                            id="edit_gender"
                                            name="gender"
                                            options={GENDER_ARR}
                                            value={fieldVals?.gender}
                                            onChange={(gender) => {
                                                setFieldVals({...fieldVals, gender: gender, 'voice': null});
                                                setFieldErrors({});
                                            }}
                                            invalid={fieldErrors.gender}
                                            styles={genderRadioButtonsStyles}
                                            placeholder={'Select Gender'}
                                        />
                                        {fieldErrors?.gender && (
                                            <FormFeedback>{fieldErrors?.gender}</FormFeedback>
                                        )}
                                    </div>
                            </div>
                        </FormGroup>
                    </Col>

                    <Col sm={'6'}>
                        <FormGroup>
                            <Label
                                className="col-sm-12 col-form-label"
                                htmlFor="assistantVoice"
                                style={{
                                    fontWeight: '500',
                                    color: 'black'
                                }}>
                                AI Assistant Voice Should Sound Like
                                <IoIosHelpCircleOutline
                                    id="PopoverAssistantVoice"
                                    size={20}
                                    style={{
                                        marginLeft: 8,
                                        cursor: 'pointer',
                                        marginBottom: '-5px'
                                    }}
                                    onMouseEnter={() => handleMouseEnter('assistantVoice')}
                                    onMouseLeave={() => handleMouseLeave('assistantVoice')}
                                />
                                <Popover
                                    placement="top"
                                    isOpen={popoverOpen.assistantVoice}
                                    target="PopoverAssistantVoice"
                                    toggle={() => {
                                    }}
                                >
                                    <PopoverBody>Select the voice for the assistant. Different voices provide distinct
                                        styles and tones for the assistant’s responses.</PopoverBody>
                                </Popover>
                            </Label>
                            <Col sm="12">
                                <div style={{
                                    display: 'flex',
                                    alignItems: 'center'
                                }}>
                                    <div
                                        style={{width: '100%'}}>
                                        <Select
                                            id="edit_voice"
                                            name="voice"
                                            options={fieldVals.gender.value === SHORT_GENDER.MALE ? MALE_VOICES : FEMALE_VOICES}
                                            value={fieldVals?.voice}
                                            onChange={(voice) => {
                                                setFieldVals({...fieldVals, voice: voice});
                                                setFieldErrors({});
                                                dispatch({
                                                    type: ActionTypes.GET_BOT_VOICE_MODEL,
                                                    payload: {text: fieldVals?.first_message, voice: voice?.value}
                                                });
                                            }}
                                            invalid={fieldErrors.voice_id}
                                            styles={genderRadioButtonsStyles}
                                            placeholder={'Select Voice'}
                                        />
                                    </div>
                                    {audioSrc &&
                                        <>
                                            {isBotVoiceLoading ?
                                                <div style={{
                                                    marginLeft: '10px',
                                                    height: '25px',
                                                    width: '25px',
                                                    marginTop: '5px'
                                                }}>
                                                    <Spinner size={'sm'}>...</Spinner>
                                                </div>
                                                : (
                                                    <div
                                                        onClick={toggleAudioPlay}
                                                        style={{
                                                            marginLeft: '10px',
                                                            cursor: 'pointer'
                                                        }}>
                                                        {isPlaying ? (
                                                            <IoPauseCircleOutline
                                                                size={25}
                                                                style={{marginTop: '5px'}}/>
                                                        ) : (
                                                            <IoPlayCircleOutline
                                                                size={25}
                                                                style={{marginTop: '5px'}}/>
                                                        )}
                                                    </div>
                                                )}
                                        </>
                                    }
                                </div>
                                {fieldErrors?.voice_id && (
                                    <div style={{ color: "#dc3545", marginTop: "5px" }}>
                                        {fieldErrors?.voice_id}
                                    </div>
                                )}
                                <audio
                                    ref={(audio) => setAudioElement(audio)}
                                    src={audioSrc}
                                    type="audio/mpeg"
                                    onEnded={() => setIsPlaying(false)}
                                    style={{display: 'none'}}
                                />
                            </Col>
                        </FormGroup>
                    </Col>
                </Row>


                <FormGroup>
                    <Label
                        className="col-sm-12 col-form-label"
                        htmlFor="keywords"
                        style={{
                            fontWeight: '500',
                            color: 'black'
                        }}>
                        Keywords (Catch Phrases)
                        <IoIosHelpCircleOutline
                            id="PopoverKeywords"
                            size={20}
                            style={{
                                marginLeft: 8,
                                cursor: 'pointer',
                                marginBottom: '-5px'
                            }}
                            onMouseEnter={() => handleMouseEnter('keywords')}
                            onMouseLeave={() => handleMouseLeave('keywords')}
                        />
                        <Popover
                            placement="top"
                            isOpen={popoverOpen.keywords}
                            target="PopoverKeywords"
                            toggle={() => {
                            }}
                        >
                            <PopoverBody>Fluten.ai is designed to capture keywords used during conversations between the
                                assistant and the caller. This feature will provide valuable insights into the topics
                                being discussed and the frequency of keyword usage in each call. Each keyword needs to
                                be separated with a comma (,).</PopoverBody>
                        </Popover>
                    </Label>
                    <Col sm="12">
                        <div className="tags-input-container">
                        <div className="tags-wrapper">
                            {tags?.map((tag, index) => (
                                <div key={index}
                                     className="tag">
                                    {tag}
                                    <span
                                        className="tag-close-icon"
                                        onClick={() => removeTag(index)}> &times; </span>
                                </div>
                            ))}
                        </div>
                            <Input
                                type="textarea"
                                id="keywords"
                                maxLength={32}
                                name={'keywords'}
                                value={fieldVals?.keywords}
                                invalid={fieldErrors?.keywords}
                                onKeyPress={handleKeyPress}
                                onChange={handleChanges}
                                placeholder="Business, Email, Sale, Insurance"
                                className={`form-control input-air-primary digits`}
                                style={{backgroundColor: 'white'}}
                            />
                            {fieldErrors?.keywords && (
                                <FormFeedback>{fieldErrors?.keywords}</FormFeedback>
                            )}
                        </div>
                    </Col>
                </FormGroup>

                <Col sm='12'>
                    <FormGroup>
                        <Label
                            className="col-sm-12 col-form-label"
                            htmlFor="keywords" style={{
                            fontWeight: '500',
                            color: 'black'
                        }}>
                            Your AI Assistant Personality
                            <IoIosHelpCircleOutline
                                id="InstructionsDetail"
                                size={20}
                                style={{
                                    marginLeft: 8,
                                    cursor: 'pointer',
                                    marginBottom: '-5px'
                                }}
                                onMouseEnter={() => handleMouseEnter('instructions')}
                                onMouseLeave={() => handleMouseLeave('instructions')}
                            />
                            <Popover
                                placement="top"
                                isOpen={popoverOpen.instructions}
                                target="InstructionsDetail"
                                toggle={() => {
                                }}
                            >
                                <PopoverBody>
                                    To define your assistant's customer relations with all incoming callers, it's
                                    important to outline the assistant's personality traits, behavior, and response
                                    strategy in your instructions.
                                </PopoverBody>
                            </Popover>
                        </Label>
                        <Col sm="12">
                            <Input
                                type="textarea"
                                id="editPrompt"
                                name='prompt'
                                rows={10}
                                value={fieldVals.prompt}
                                invalid={fieldErrors.prompt}
                                onChange={handleChanges}
                                placeholder="Enter prompt"
                                className={`form-control input-air-primary digits `}
                                style={{
                                    backgroundColor: 'white',
                                    minHeight: '100px'
                                }}
                            />
                            {fieldErrors?.prompt && (
                                <FormFeedback>{fieldErrors?.prompt}</FormFeedback>
                            )}
                        </Col>
                    </FormGroup>
                </Col>

            </Col>

            <Col sm={'12'}>
                <CardBody style={{
                    borderRadius: '10px',
                    border: '1px solid #DDDBDB',
                    padding: '10px'
                }}>
                    <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        cursor: 'pointer',
                    }}
                         onClick={() => setIsCollapsed(!isCollapsed)}
                    >
                        <h5 style={{margin: 0, padding: 0}}>Advanced Settings</h5>
                        <div
                            style={{

                                margin: 0,
                                padding: 0
                            }}
                        >
                            {!isCollapsed ?
                                <FaChevronDown
                                    color={'black'}/> :
                                <FaChevronUp
                                    color={'black'}/>}
                        </div>
                    </div>

                    {isCollapsed &&
                        <>
                            <FormGroup>
                                <Label
                                    className="col-sm-12 col-form-label"
                                    htmlFor="inputEmail3"
                                    style={{
                                        fontWeight: '500',
                                        color: 'black'
                                    }}>Phone Number</Label>
                                <Col sm="12">
                                    <Input
                                        type="text"
                                        id="name"
                                        name={'phone_number'}
                                        value={formatPhoneNumber(fieldVals?.phone_number)}
                                        invalid={fieldErrors?.phone_number}
                                        onChange={handleChanges}
                                        placeholder="Enter phone"
                                        className={`form-control input-air-primary digits `}
                                        style={{backgroundColor: 'white'}}
                                        maxLength={30}
                                    />
                                    {fieldErrors?.phone_number && (
                                        <FormFeedback>{fieldErrors?.phone_number}</FormFeedback>
                                    )}
                                </Col>
                            </FormGroup>
                            <FormGroup>
                                <Label
                                    className="col-sm-12 col-form-label"
                                    htmlFor="provider"
                                    style={{
                                        fontWeight: '500',
                                        color: 'black'
                                    }}>Provider</Label>
                                <Col sm="12">
                                    <Input
                                        type="select"
                                        value={fieldVals?.provider}
                                        invalid={fieldErrors?.provider}
                                        onChange={handleChanges}
                                        name="provider"
                                        className={`form-control input-air-primary digits `}
                                        style={{backgroundColor: 'white'}}
                                    >
                                        {!fieldVals?.provider &&
                                            <option
                                                value="">Select
                                                Provider</option>}
                                        {PROVIDERS_ARR?.map(option => (
                                            <option
                                                key={option}
                                                value={option}>{option}</option>
                                        ))}
                                    </Input>
                                    {fieldErrors?.provider && (
                                        <FormFeedback>{fieldErrors?.provider}</FormFeedback>
                                    )}
                                </Col>
                            </FormGroup>

                            {fieldVals?.provider && (
                                <>
                                    <FormGroup>
                                        <Col sm="12"
                                             style={{position: 'relative'}}>
                                            <div
                                                style={{display: 'flex', justifyContent: 'space-between'}}>
                                                <Label
                                                    className="col-sm-11 col-form-label"
                                                    htmlFor="inputEmail3"
                                                    style={{
                                                        fontWeight: '500',
                                                        color: 'black'
                                                    }}>Account SID</Label>
                                                <div
                                                    onClick={() => setShowAccountSID(!showAccountSID)}
                                                    style={{
                                                        cursor: 'pointer',
                                                        marginTop: '10px',
                                                        marginRight: '5px'
                                                    }}
                                                >
                                                    {showAccountSID ?
                                                        <FaEyeSlash
                                                            color={'black'}/> :
                                                        <FaEye
                                                            color={'black'}/>}
                                                </div>
                                            </div>

                                            <Input
                                                type={showAccountSID ? 'text' : 'password'}
                                                id="provider_keys"
                                                name="provider_sid"
                                                value={fieldVals.provider_sid}
                                                invalid={fieldErrors.provider_sid}
                                                onChange={handleChanges}
                                                placeholder="Enter Account SID"
                                                className={`form-control input-air-primary digits `}
                                                style={{backgroundColor: 'white'}}
                                                maxLength={50}
                                            />
                                            {fieldErrors?.provider_sid && (
                                                <FormFeedback>{fieldErrors?.provider_sid}</FormFeedback>
                                            )}
                                        </Col>
                                    </FormGroup>

                                    <FormGroup>
                                        <div
                                            style={{display: 'flex', justifyContent: 'space-between'}}>
                                            <Label
                                                className="col-sm-11 col-form-label"
                                                htmlFor="inputEmail3"
                                                style={{
                                                    fontWeight: '500',
                                                    color: 'black'
                                                }}>AuthToken</Label>
                                            <div
                                                onClick={() => setShowAuthToken(!showAuthToken)}
                                                style={{
                                                    cursor: 'pointer',
                                                    marginTop: '10px',
                                                    marginRight: '5px'
                                                }}
                                            >
                                                {showAuthToken ?
                                                    <FaEyeSlash
                                                        color={'black'}/> :
                                                    <FaEye
                                                        color={'black'}/>}
                                            </div>
                                        </div>

                                        <Col sm="12"
                                             style={{position: 'relative'}}>
                                            <Input
                                                type={showAuthToken ? 'text' : 'password'} // Toggle between text and password
                                                id="provider_keys"
                                                name={'provider_auth_token'}
                                                value={fieldVals?.provider_auth_token}
                                                invalid={fieldErrors?.provider_auth_token}
                                                onChange={handleChanges}
                                                placeholder="Enter AuthToken"
                                                className={`form-control input-air-primary digits `}
                                                style={{backgroundColor: 'white'}}
                                                maxLength={50}
                                            />

                                            {fieldErrors?.provider_auth_token && (
                                                <FormFeedback>{fieldErrors?.provider_auth_token}</FormFeedback>
                                            )}
                                        </Col>
                                    </FormGroup>
                                </>
                            )}
                        </>
                    }
                </CardBody>

            </Col>


            <div style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginTop: '2em'
            }}>
                <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                    width: '100%'
                }}>
                    {isUpdatingAssistant ?
                        <Button disabled>
                            <Spinner
                                as="span"
                                animation="grow"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            />
                            Saving...
                        </Button>
                        :
                        <Button style={{height: '40px'}}
                                onClick={handleSave}>Save</Button>
                    }

                    <Button color='primary'
                            style={{height: '40px'}}
                            onClick={() => {
                                dispatch({
                                    type: ActionTypes.SET_ASSISTANT_RIGHT_SIDEBAR,
                                    payload: {sidebar: AI_ASSISTANT_RIGHT_SIDEBAR.ASSISTANT_DETAIL}
                                });
                            }}>Cancel</Button>
                </div>
            </div>
        </Row>
    );
}